import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { servicesApi } from 'site/api/definitions/services';

import { getOffset } from 'core/utils/url-helper';
import { denormalizeData } from 'core/utils/api';
import { renderError } from 'core/utils/app-status-helper';

import { withBreakpoint } from 'core/components/breakpoint';
import bindProps from 'core/components/bindProps';
import PageLoader from 'core/components/Loader/PageLoader';

import H1 from 'core/components/H1';
import GridFeed from 'core/components/GridFeed';
import ListPage from 'core/components/ListPage';

import PageLayoutService from 'site/components/PageLayoutService';
import { Indent } from 'site/components/Wrappers';

import CardServiceEditorChoice from 'site/cardsService/CardServiceEditorChoice';

import { getTypeOfPage } from 'site/utils';
import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


const limit = 15;
const CardServiceEditorChoiceMobile = bindProps({ imageMaxWidth: 400 })(CardServiceEditorChoice);

function BestChoicePage(props) {
  const {
    isMobile,
    isDesktop,
    location: {
      pathname,
      search,
    },
    history,
  } = props;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const pageType = getTypeOfPage(pathname);

  const [{
    data: rawContent = {},
    isLoading: contentIsLoading,
  }, {
    data: editorsChoiceSeo = {},
    isLoading: seoIsLoading,
  }] = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', 'getEditorsChoice', {
          'attributes[car_editors_choice]': 'base,image',
          'filter[type]': pageType,
          limit,
          offset: getOffset(search, limit),
          with_filtered_count: true,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .catch(err => renderError(err, history)),
      }, {
        queryKey: ['servicesApi', 'getEditorsChoiceSeo', {
          'filter[bestchoice]': pageType + '_main_meta',
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      },
    ],
  });

  if (seoIsLoading) return <PageLoader />;

  const {
    title,
    description,
    h1,
  } = editorsChoiceSeo;

  return (
    <PageLayoutService>
      <ListPage
        rawData={rawContent}
        limit={limit}
        title={title}
        description={description}
        isLoading={contentIsLoading}
      >
        {({ content }) => (
          <Indent bottom={vertical}>
            {<H1>{h1 || title}</H1>}
            {!!description && <p>{description}</p>}
            <Indent bottom={vertical} />
            {contentIsLoading && <PageLoader />}
            {!contentIsLoading && (
              <GridFeed
                content={content}
                card={isDesktop ? CardServiceEditorChoice : CardServiceEditorChoiceMobile}
                gap='20px'
                {...isDesktop && { gridTemplateColumns: '1fr 1fr 1fr' }}
              />
            )}
          </Indent>
        )}
      </ListPage>
    </PageLayoutService>
  );
}

BestChoicePage.propTypes = {
  isMobile: PropTypes.bool,
  isDesktop: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default withBreakpoint(BestChoicePage);
