import { useState, useContext } from 'react';

import useRequest from 'core/hooks/useRequest';

import { setCookie } from 'core/utils/cookie-helper';

import SharedRenderContext from 'core/components/SharedRenderContext';

import { GEO_ID_COOKIE } from 'core/site/constants';

import { servicesApi } from 'site/api/definitions/services';

import { GeoContext, DEFAULT_GEO } from '.';


/**
 * TODO: Рассмотреть возможность использовать uuid вместо quto_id
 */

export default function GeoProvider({ children }) {
  const { requestHeaders } = useContext(SharedRenderContext);
  const {
    'x-geo-id': X_GEO_ID,
    HTTP_X_GEO_COUNTRY,
    HTTP_X_GEO_REGION,
    HTTP_X_GEO_CITY,
  } = requestHeaders;

  const [stateGeo, setGeo] = useState(DEFAULT_GEO);

  const setGeoFromGeos = (id, geosData) => {
    const geoId = Number(id);
    const defaultGeoId = DEFAULT_GEO.attributes.quto_id;

    if (geoId === defaultGeoId && stateGeo?.attributes?.quto_id !== defaultGeoId) {
      setGeo(DEFAULT_GEO);
    } else {
      setGeo(geosData.find(item => item.attributes.quto_id === geoId) || DEFAULT_GEO);
    }
  };

  const [{}, { data: geos, isLoading: geosAreLoading }] = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', 'resolveGeo', {
          country: HTTP_X_GEO_COUNTRY,
          region: HTTP_X_GEO_REGION,
          city: HTTP_X_GEO_CITY,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .then(({ data }) => {
            !X_GEO_ID && setGeo(data);
            return data;
          })
          .catch(() => DEFAULT_GEO),
      }, {
        queryKey: ['servicesApi', 'getUserGeos'],
        queryFn: ({ queryKey: [, method] }) => servicesApi[method]()
          .then(({ data }) => {
            !!X_GEO_ID && setGeoFromGeos(X_GEO_ID, data);
            return data;
          })
          .catch(() => []),
      },
    ],
  });

  return (
    <GeoContext.Provider
      value={{
        geo: stateGeo,
        geoSlug: stateGeo?.attributes?.url,
        geos,
        geosAreLoading,
        defaultGeo: DEFAULT_GEO,
        updateGeo: value => {
          setGeoFromGeos(value, geos);
          value !== X_GEO_ID && setCookie(GEO_ID_COOKIE, value);
        },
        getGeoAttributes: () => stateGeo?.attributes || {},
      }}
    >
      {children}
    </GeoContext.Provider>
  );
}
