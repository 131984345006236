import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { withRouter } from 'core/libs/router';

import { denormalizeData } from 'core/utils/api';
import resolveRelationships from 'core/utils/relationships';

import PageLoader from 'core/components/Loader/PageLoader';
import Drum from 'core/components/Drum';

import { withBreakpoint } from 'core/components/breakpoint';

import { servicesApi } from 'site/api/definitions/services';

import FeedHeader from 'site/components/FeedHeader';
import { ShadowContentBg } from 'site/components/Wrappers';

import CardRelease1WithPrice from 'site/cardsService/CardServiceRelease1/cards/CardRelease1WithPrice';

import { isMotoPath } from 'site/utils';


const relationships = resolveRelationships(['classmates'], {}, {});

function Classmates(props) {
  const {
    mainReleaseId,
    isMobile,
    match: {
      params: {
        brand,
        model,
        generation,
        body,
      },
    },
    location,
    withShadowBg,
  } = props;

  const isMoto = isMotoPath(location.pathname);
  const vehiclePrefix = isMoto ? 'moto' : 'car';

  const {
    data: releases,
    isLoading: releasesAreLoading,
  } = useRequest({
    queryKey: ['servicesApi', isMoto ? 'getMotoReleases' : 'getReleases', {
      'filter[brand]': brand,
      'filter[model]': model,
      'filter[generation]': generation,
      'filter[body]': body,
      'filter[usages]': 'catalog,archive',
      [`attributes[${vehiclePrefix}_release]`]: 'base,image',
      [`relations[${vehiclePrefix}_release]`]: 'classmates',
      include: `${vehiclePrefix}_release`,
    }, location.pathname, mainReleaseId],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
      .then(data => {
        const relations = relationships(denormalizeData(data)[0]);
        return relations.classmates;
      })
      .then(data => data.filter(item => item.id !== mainReleaseId)),
    enabled: process.env.BROWSER_RUNTIME,
  });

  if (releasesAreLoading) return <PageLoader />;
  if (!releases?.length) return null;

  const commonProps = {
    hideArrows: true,
    showHeaderArrows: true,
    title: 'Конкуренты',
    headerComponent: FeedHeader,
    grid: true,
    itemWidth: '25%',
    itemWidthMobile: '50%',
    spaceBetween: 20,
    content: releases,
    card: CardRelease1WithPrice,
  };

  if (withShadowBg) {
    return (
      <ShadowContentBg type={isMobile ? 4 : 3}>
        <Drum {...commonProps} />
      </ShadowContentBg>
    );
  }

  return (
    <Drum {...commonProps} />
  );
}

Classmates.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  match: PropTypes.object,
  /** @ignore */
  location: PropTypes.object,
  /** Флаг, включающий обрамление блока тенью */
  withShadowBg: PropTypes.bool,
  /** Модель для сравнения */
  mainReleaseId: PropTypes.string,

};

const ClassmatesWithHOCs = withBreakpoint(withRouter(Classmates));

ClassmatesWithHOCs.displayName = 'Classmates';

export default ClassmatesWithHOCs;
export { Classmates as StorybookComponent };
