import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { withRouter } from 'core/libs/router';

import { denormalizeData } from 'core/utils/api';

import { withBreakpoint, Mobile, Desktop } from 'core/components/breakpoint';
import PageLoader from 'core/components/Loader/PageLoader';

import { servicesApi } from 'site/api/definitions/services';

import FeedHeader from 'site/components/FeedHeader';
import { Indent, NegativeMobile, ShadowContentBg } from 'site/components/Wrappers';

import withTabs from './withTabs';
import Releases from './Releases';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  SIDE_INDENT_MOBILE,
} from 'site/constants';


function Generations(props) {
  const {
    mainGenerationId,
    match: {
      params: {
        brand: brandSlug,
        model: modelSlug,
      },
    },
    isMobile,
  } = props;

  const {
    data: generations,
    isLoading: generetionsAreLoading,
  } = useRequest({
    queryKey: ['servicesApi', 'getGenerations', {
      'filter[brand]': brandSlug,
      'filter[model]': modelSlug,
      'relations[car_generation]': 'releases',
      'attributes[car_release]': 'base,image,stats',
      include: 'car_release',
      'sort': '-manual',
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    select: denormalizeData,
    enabled: process.env.BROWSER_RUNTIME,
  });

  if (generetionsAreLoading) return <PageLoader />;
  if (!Array.isArray(generations)) return null;

  const filteredGenerationsByRelease = generations.filter(generation => {
    return (generation?.relationships?.releases?.data || []).length &&
      generation.id !== mainGenerationId;
  });

  if (filteredGenerationsByRelease.length === 0) return null;

  const tabs = filteredGenerationsByRelease
    .map(generation => ({
      requestParams: {
        'filter[brand]': brandSlug,
        'filter[model]': modelSlug,
        'filter[generation]': generation?.attributes?.slug,
        'attributes[car_release]': 'base,image,stats',
        limit: 1,
      },
      content: generation,
    }));

  const Tabs = withTabs({ tabs })(Releases);

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const horizontal = isMobile ? SIDE_INDENT_MOBILE : 0;

  const Title = (
    <Indent
      top={vertical}
      bottom={vertical}
      left={horizontal}
      right={horizontal}
    >
      <FeedHeader>
        <Mobile>Другие поколения</Mobile>
        <Desktop>Другие поколения в каталоге</Desktop>
      </FeedHeader>
    </Indent>
  );

  return (
    <NegativeMobile>
      <Desktop>{Title}</Desktop>
      <ShadowContentBg type={isMobile ? 2 : 1}>
        <Mobile>{Title}</Mobile>
        <Indent left={isMobile ? 5 : 0} right={isMobile ? 5 : 0}>
          <Tabs />
        </Indent>
      </ShadowContentBg>
    </NegativeMobile>
  );
}

Generations.propTypes = {
  isMobile: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string.isRequired,
      model: PropTypes.string.isRequired,
    }),
  }).isRequired,
  mainGenerationId: PropTypes.string,
};

export default withRouter(withBreakpoint(Generations));
