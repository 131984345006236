import useRequest from 'core/hooks/useRequest';

import { renderError } from 'core/utils/app-status-helper';
import { denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';

import { servicesApi } from 'site/api/definitions/services';

import { isMotoPath } from 'site/utils';
import rejectEmptyResult from 'site/utils/rejectEmptyResult';


export default function useOfferContent(queryParams) {
  const {
    match: {
      params: {
        geo,
        slug,
      },
    },
    location: {
      pathname,
    },
    history,
  } = queryParams;

  const isMoto = isMotoPath(pathname);
  const vehiclePrefix = isMoto ? 'moto' : 'car';

  const results = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', isMoto ? 'getMotoOffers' : 'getOffers', {
          'filter[offer]': slug,
          'filter[with_inactive]': true,
          [`relations[dealer_offer${isMoto ? '_moto' : ''}]`]: 'body,release,modification,geo,dealer,class',
          'relations[dealer]': 'geo_parent',
          'include': `car_modification,${vehiclePrefix}_release,${vehiclePrefix}_body,${vehiclePrefix}_class,geo,dealer,moto_class`,
          [`attributes[dealer_offer${isMoto ? '_moto' : ''}]`]: 'base,text,media',
          [`attributes[${vehiclePrefix}_modification]`]: 'base,extended',
          [`attributes[${vehiclePrefix}_release]`]: 'base,media,extended',
          'limit': 1,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .then(result => denormalizeData(result)[0])
          .then(rejectEmptyResult)
          .catch(err => renderError(err, history)),
      }, {
        queryKey: ['servicesApi', isMoto ? 'getMotoOffersOfferSeo' : 'getCarOffersOfferSeo', {
          'filter[geo]': geo,
          'filter[offer]': slug,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      },
    ],
  });

  const {
    data: [
      offer = {},
      offerSeo = {},
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  const hasRelatedDealer = Boolean(offer?.relationships?.dealer);
  const included = offer?.included || [];

  // Из полученных данных собираем дилера со связью geo_parent для передачи в DealerInfo
  const dealer = hasRelatedDealer
    ? denormalizeData({
      data: {
        ...offer.relationships.dealer.data,
        relationships: (included.find(({ type }) => type === 'dealer') || {}).relationships,
      },
      included,
    })
    : undefined;

  return {
    offerWithDealer: {
      offer,
      dealer,
    },
    offerSeo,
    isLoading: resultsAreLoading,
  };
}

