import { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';

import OptionRadio from 'site/components/OptionRadio';
import { Indent } from 'site/components/Wrappers';

import { ConfiguratorContext } from 'site/components/ConfiguratorProvider';

import {
  hasNotInstalledIncludes,
} from 'site/utils/configurator';

import styles from './index.styl';

export default function InstallVariants(props) {
  const {
    handleChange,
    option,
    currentOption,
    modificationId,
  } = props;

  const {
    id: optionId,
    install_variants: installVariants,
  } = option;

  const {
    id: currentOptionId,
  } = currentOption;

  const configurations = useContext(ConfiguratorContext);
  const {
    options,
    packages,
    installed_options: installedOptions,
  } = configurations[modificationId] || {};

  const [withNote, checkMessage] = useState(false);

  /**
   * Показываем сообщение сноску под звездочкой.
   */
  useEffect(() => {
    if (!installVariants) return;

    const hasIncludesVariant = installVariants.filter(variant => {
      const localOption = options[variant.id] || packages[variant.id];
      return hasNotInstalledIncludes(localOption.dependencies, installedOptions);
    });
    if (hasIncludesVariant.length && !withNote) checkMessage(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!options && !packages) return null;

  return (
    <div className='installVariants'>
      {installVariants.map(variant => {
        const localOption = options[variant.id] || packages[variant.id];
        const hasIncludesMark = hasNotInstalledIncludes(localOption.dependencies, installedOptions);

        return (
          <div key={variant.id}>
            <OptionRadio
              modificationId={modificationId}
              name={`installVariants_${optionId}`}
              defaultChecked={currentOptionId === variant.id}
              option={options[variant.id] || packages[variant.id]}
              onChange={() => handleChange(options[variant.id] || packages[variant.id])}
              isImportant={hasIncludesMark}
            />
          </div>
        );
      })}

      <Indent top={10} />
      {withNote && <div className={styles.note}>* у опции есть зависимости</div>}
    </div>
  );
}

InstallVariants.propTypes = {
  option: PropTypes.object,
  currentOption: PropTypes.object,
  handleChange: PropTypes.func,
  modificationId: PropTypes.string,
};
