import PropTypes from 'prop-types';
import cx from 'classnames';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import withTheme from 'core/components/theme';
import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';

import H6 from 'core/components/H6';
import Page from 'core/components/Page';
import PageLoader from 'core/components/Loader/PageLoader';
import AdWrapper from 'core/components/Ad/AdWrapper';

import DealerInfo from 'site/components/DealerInfo';

import PageLayoutService from 'site/components/PageLayoutService';
import TextExpander from 'site/components/TextExpander';
import { Indent } from 'site/components/Wrappers';
import { SuperFooter } from 'site/components/Ads/desktop';
import { Listing1 } from 'site/components/Ads/mobile';
import SimilarOffers from 'site/components/SimilarOffers';

import { isMotoPath } from 'site/utils';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import Header from './Header';
import Cover from './Cover';
import Reviews from './Reviews';
import Properties from './Properties';
import AuxiliaryInfo from './AuxiliaryInfo';

import useOfferContent from './useOfferContent';

import styles from './index.styl';


function OfferPage(props) {
  const {
    offerWithDealer,
    offerSeo,
    isLoading,
  } = useOfferContent(props);

  const {
    offer,
    dealer,
  } = offerWithDealer || {};

  const {
    isMobile,
    breakpoint,
    theme,
    location,
    match,
  } = props;

  const {
    description,
    external_url: externalUrl,
  } = offer?.attributes || {};

  const { title: seoTitle, description: seoDescription } = offerSeo || {};
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const isMoto = isMotoPath(location.pathname);

  return (
    <Page
      title={seoTitle}
      description={seoDescription}
    >
      <style jsx>{`
        .section
          border-bottom 1px solid ${theme.colors.primary250}
      `}</style>
      {isLoading && <PageLoader />}
      {!isLoading && (
        <AdWrapper bottom={vertical}>
          <PageLayoutService bgContentList>
            <div className={cx(styles.wrapper, styles[breakpoint])}>
              <Header offer={offer} />

              <Mobile>
                <Indent top={20}>
                  <AuxiliaryInfo offer={offer} />
                </Indent>
              </Mobile>

              <Indent top={vertical} bottom={vertical}>
                <Cover offer={offer} />
              </Indent>

              <div className={styles.column}>
                <Desktop>
                  <AuxiliaryInfo offer={offer} />
                </Desktop>

                <Indent top={vertical} bottom={vertical}>
                  <Properties offer={offer} />
                </Indent>

                <div className={cx(styles.section, 'section')}>
                  <H6>Комментарий продавца</H6>
                  <TextExpander maxParagraphs={10} text={description} />
                </div>
                <Indent bottom={vertical} />
                <AuxiliaryInfo offer={offer} />
                <Indent bottom={vertical} />
              </div>


              <Desktop>
                <SuperFooter />
              </Desktop>
              <Mobile>
                <Listing1 />
              </Mobile>
              <DealerInfo
                externalUrl={externalUrl}
                content={dealer}
                showHeader
              />
              <Indent bottom={vertical} />
              <SimilarOffers offer={offer} />
              {!isMoto && (
                <Reviews match={match} />
              )}
            </div>
          </PageLayoutService>
        </AdWrapper>
      )}
    </Page>
  );
}

OfferPage.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
    }).isRequired,
  }).isRequired,
  breakpoint: breakpointPropTypes(),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default withBreakpoint(withTheme(OfferPage));
