import PropTypes from 'prop-types';

import { compose } from 'core/libs/recompose';
import { withRouter } from 'core/libs/router';

import { withBreakpoint, Mobile, Desktop } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';

import CarReleaseGallery from 'site/components/CarReleaseGallery';
import CarReleaseImage from 'site/components/CarReleaseGallery/CarReleaseImage';

import TitleCatalog from 'site/components/TitleCatalog';
import { Indent } from 'site/components/Wrappers';
import Divider from 'site/components/Divider';
import ReleaseTabs from 'site/components/ReleaseTabs';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  SIDE_INDENT_MOBILE,
} from 'site/constants';
import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';

import styles from './index.styl';

function ReleasePromo({ isMobile, release, theme, isBrandTagExists }) {
  const {
    attributes: {
      name,
    },
  } = release;

  /**
   * TODO: resolveRelationships оставляет только атрибутуты
   * через деструктуризацию брать опасно, т.к можно словить ошибку
   * Подумать что с этим можно сделать, т.к это не первое место где
   * relationships берутся таким обрзом
   */
  const brand = release?.relationships?.brand?.data;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const horizontal = isMobile ? SIDE_INDENT_MOBILE : 0;

  const media = release?.attributes?.images?.attributes?.images.length === 1
    ? (
      <CarReleaseImage
        image={release.attributes.images.attributes.images[0]}
      />
    ) : (
      <CarReleaseGallery release={release} />
    );


  return (
    <div style={{ backgroundColor: theme.colors.layout, marginTop: -vertical, paddingTop: vertical }}>
      <Indent left={horizontal} right={horizontal}>
        <TitleCatalog brand={brand} title={name} />
      </Indent>

      <Indent bottom={vertical} top={vertical}>
        { media }
      </Indent>

      <Mobile>
        <Indent left={horizontal} right={horizontal}>
          <Divider />
        </Indent>
      </Mobile>

      <ReleaseTabs release={release} isBrandTagExists={isBrandTagExists} />
      <Divider />

      <Desktop>
        <div className={styles.separator} />
      </Desktop>
    </div>
  );
}

ReleasePromo.propTypes = {
  isMobile: PropTypes.bool,
  release: modelPropTypes(carReleaseAttributes),
  theme: PropTypes.object,
  isBrandTagExists: PropTypes.bool,
};

export default compose(
  withBreakpoint,
  withRouter,
  withTheme,
)(ReleasePromo);
