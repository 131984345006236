import PropTypes from 'prop-types';

import { bebop as bebopApi } from 'core/api/definitions/bebop';

import useRequest from 'core/hooks/useRequest';

import { rawTopicsQuery } from 'core/queries/listPage';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import { denormalizeData } from 'core/utils/api';
import { getPage } from 'core/utils/url-helper';

import H1 from 'core/components/H1';
import ListPage from 'core/components/ListPage';
import PageLoader from 'core/components/Loader/PageLoader';

import PageLayout from 'site/components/PageLayout';
import RubricSelector from 'site/components/RubricSelector';
import SelectionList from 'site/components/SelectionList';

import { Indent } from 'site/components/Wrappers';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  RUBRICS_PAGE_LIMIT,
} from 'site/constants';


const title = 'Журнал — все статьи и новости';
const description = 'Все последние новости автомобилей, актуальные обзоры от экспертов, новинки авторынка в журнале на сайте Quto.ru';

function JournalPage(props) {
  const {
    isMobile,
    location,
    history,
  } = props;
  const page = getPage(location.search);

  const {
    data: commercial = [],
    isLoading: commercialIsLoading,
  } = useRequest({
    queryKey: ['bebopApi', 'getTopics', {
      list: 'commercial',
      sort: 'list',
      include: SelectionList.requiredPayloadImports,
      fields: SelectionList.requiredPayloadFields,
      limit: 1,
    }],
    queryFn: ({ queryKey: [, method, params] }) => bebopApi[method](params),
    select: denormalizeData,
  });

  const {
    data: rawData = {},
    isLoading: dataIsLoading,
  } = useRequest(rawTopicsQuery({
    location,
    history,
    include: SelectionList.requiredPayloadImports,
    fields: SelectionList.requiredPayloadFields,
    limit: RUBRICS_PAGE_LIMIT,
    visibility: 'main_page',
    ...commercial?.length && {
      'excluded_ids': [commercial[0].id],
    },
  }, { enabled: !commercialIsLoading }));

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <PageLayout>
      <H1 dataQa='page-title'>Журнал</H1>
      <Indent bottom={isMobile ? VERTICAL_INDENT_MOBILE : 24} />
      <RubricSelector />
      <Indent bottom={vertical} />
      <ListPage
        title={title}
        description={description}
        rawData={rawData}
        limit={RUBRICS_PAGE_LIMIT}
        isLoading={dataIsLoading || commercialIsLoading}
      >
        {({ content }) => {
          if (dataIsLoading || commercialIsLoading) return <PageLoader />;

          const result = commercial?.length && page === 1
            ? [...content].splice(1, 0, commercial[0])
            : content;

          return <SelectionList topics={result} isLoading={dataIsLoading} />;
        }}
      </ListPage>
    </PageLayout>
  );
}

JournalPage.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  history: PropTypes.object,
};

export default withBreakpoint(JournalPage);
