import cx from 'classnames';

import { NUMBER_OF_YEARS } from 'site/constants';

import styles from './index.styl';


export default function ExpensesSkeleton() {
  return new Array(NUMBER_OF_YEARS).fill(null).map((v, i) => (
    <td key={i}>
      <div className={cx('lb-skeleton', styles.expense)} />
    </td>
  ));
}
