import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { renderError } from 'core/utils/app-status-helper';
import { denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';
import resolveRelationships from 'core/utils/relationships';

import Page from 'core/components/Page';
import PageLoader from 'core/components/Loader/PageLoader';
import H2 from 'core/components/H2';
import Feed from 'core/components/Feed';
import { withBreakpoint } from 'core/components/breakpoint';

import { servicesApi } from 'site/api/definitions/services';

import ReleaseBoroda from 'site/components/ReleaseBoroda';
import { Indent, ReleaseInnerPages } from 'site/components/Wrappers';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';

import { brandTagExistsQuery } from 'site/queries/brandTagExists';

import CardReleaseFeature from 'site/cardsService/CardReleaseFeature';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


const relationships = resolveRelationships(['features'], {}, { features: [] });

function ReleaseFeatures(props) {
  const {
    match: {
      params: {
        brand: brandSlug,
        model: modelSlug,
        generation: generationSlug,
        body: bodySlug,
      },
    },
    isMobile,
  } = props;

  const results = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', 'getReleases', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'filter[body]': bodySlug,
          include: 'car_brand,car_feature',
          'relations[car_release]': 'brand,features',
          'attributes[car_release]': 'base,media,seo,image,extended',
          'attributes[car_feature]': 'base,image,text',
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .then(result => denormalizeData(result)[0])
          .then(rejectEmptyResult)
          .catch(err => renderError(err, history)),
      }, {
        queryKey: ['servicesApi', 'getReleasesFeaturesSeo', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'filter[submodel]': bodySlug,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      },
      brandTagExistsQuery(props),
    ],
  });

  const {
    data: [
      release = {},
      seo = {},
      isBrandTagExists,
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  if (resultsAreLoading) return <PageLoader />;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const { features } = relationships(release);

  return (
    <Page title={seo?.title} description={seo?.description}>
      <ReleaseInnerPages release={release} isBrandTagExists={isBrandTagExists} >
        <H2 as='h1'>{`Особенности ${release?.attributes?.name}`}</H2>
        <Indent bottom={vertical} />
        <Feed
          grid
          content={features}
          interitemSpacing={isMobile ? 0 : 20}
          columns={3}
          card={CardReleaseFeature}
        />
        <ReleaseBoroda release={release} />
      </ReleaseInnerPages>
    </Page>
  );
}

ReleaseFeatures.propTypes = {
  isMobile: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
      body: PropTypes.string,
    }),
  }).isRequired,
};

export default withBreakpoint(ReleaseFeatures);
