import useRequest from 'core/hooks/useRequest';

import { denormalizeData } from 'core/utils/api';

import { servicesApi } from 'site/api/definitions/services';


/* eslint-disable react/prop-types */
export default function withContent(Component) {
  return function WrappedComponent(props) {
    const {
      match: {
        params: {
          brand: brandSlug,
        },
      },
    } = props;

    const {
      data: brands = [],
      isLoading: brandsAreLoading,
    } = useRequest({
      queryKey: ['servicesApi', 'getBrands', { 'filter[with_reviews]': true }],
      queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
      select: denormalizeData,
      enabled: process.env.BROWSER_RUNTIME,
    });

    const { data: models = [] } = useRequest({
      queryKey: ['servicesApi', 'getModels', {
        'filter[with_reviews]': true,
        'filter[brand]': brandSlug,
        'relations[car_model]': 'brand',
        include: 'car_brand',
      }],
      queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
      select: denormalizeData,
      enabled: !brandsAreLoading && !!brandSlug,
    });

    return (
      <Component
        {...props}
        selectorsData={{
          brands,
          models,
        }}
      />
    );
  };
}
