
import useRequest from 'core/hooks/useRequest';

import { renderError } from 'core/utils/app-status-helper';
import { processQueryResults } from 'core/utils/query';
import { denormalizeData } from 'core/utils/api';

import { servicesApi } from 'site/api/definitions/services';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';
import {
  buildSearchRequestParams,
  isMotoPath,
} from 'site/utils';


export default function useModelContent(queryParams) {
  const {
    match: {
      params: {
        lcv,
        brand: brandSlug,
        model: modelSlug,
      },
    },
    location: {
      pathname,
      search,
    },
    history,
  } = queryParams;

  const isLcvPage = Boolean(lcv);
  const isMoto = isMotoPath(pathname);
  const vehiclePrefix = isMoto ? 'moto' : 'car';
  const isChoiceOfGenerations = `${isMoto ? 'year' : 'generation'}`;

  const results = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', isMoto ? 'getMotoModels' : 'getModels', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          include: `${vehiclePrefix}_brand`,
          [`attributes[${vehiclePrefix}_brand]`]: 'base,image',
          [`attributes[${vehiclePrefix}_model]`]: 'base,seo,text',
          [`relations[${vehiclePrefix}_model]`]: 'brand',
          limit: 1,
          ...(isLcvPage && { 'filter[car_type]': 'lcv' }),
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .then(result => denormalizeData(result)[0])
          .then(rejectEmptyResult)
          .catch(err => renderError(err, history)),
      }, {
        queryKey: ['servicesApi', isMoto ? 'getMotoModelSeo' : 'getCarModelSeo', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      },
      {
        queryKey: ['servicesApi', isMoto ? 'getMotoReleases' : 'getReleases', {
          [`attributes[${vehiclePrefix}_release]`]: 'base,image,stats',
          [`relations[${vehiclePrefix}_release]`]: isChoiceOfGenerations,
          'include': `${vehiclePrefix}_generation,${vehiclePrefix}_year`,
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[usages]': 'catalog,archive',
          ...buildSearchRequestParams(search),
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      },
    ],
  });

  const { data: generations = [], isLoading: generationsAreLoading } = useRequest({
    queryKey: ['servicesApi', 'getGenerations', {
      'filter[brand]': brandSlug,
      'filter[model]': modelSlug,
      sort: '-manual',
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    select: denormalizeData,
    enabled: !isMoto,
  });

  const { data: years = [], isLoading: yearsAreLoading } = useRequest({
    queryKey: ['servicesApi', 'getMotoYears', {
      'filter[brand]': brandSlug,
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    select: denormalizeData,
    enabled: isMoto,
  });

  const {
    data: [
      model = {},
      modelSeo = {},
      releases = [],
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  return {
    model,
    modelSeo,
    releases,
    generations,
    years,
    isLoading: resultsAreLoading
    || (isMoto && yearsAreLoading)
    || (!isMoto && generationsAreLoading),
  };
}
