import useRequest from 'core/hooks/useRequest';

import { renderError } from 'core/utils/app-status-helper';
import { denormalizeData } from 'core/utils/api';
import { getOffset } from 'core/utils/url-helper';

import { servicesApi } from 'site/api/definitions/services';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';

import { comparisonsQuery } from 'site/queries/comparisons';

import { BEST_CHOICE_LIMIT } from 'site/constants';


const selectionsHandleMap = {
  'car': 'getReleases',
  'moto': 'getMotoReleases',
};

export default function useBestChoiceSelectionContent(queryParams) {
  const {
    match: {
      params: {
        lcv,
        moto,
        slug,
      },
    },
    location: { search },
    history,
  } = queryParams;

  const type = lcv || moto || 'cars';

  const [{
    data: editorsChoiceSeo = {},
    isLoading: seoIsLoading,
  }, {
    data: bestChoice = {},
    isLoading: bestChoiceIsLoading,
  }, {
    data: selections = {},
    isLoading: selectionsAreLoading,
  }] = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', 'getEditorsChoiceSeo', {
          'filter[bestchoice]': slug,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      }, {
        queryKey: ['servicesApi', 'getEditorsChoice', {
          'attributes[car_editors_choice]': 'base,text,seo',
          'filter[type]': type,
          'filter[slug]': slug,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .then(result => denormalizeData(result)[0])
          .then(rejectEmptyResult)
          .catch(err => renderError(err, history)),
      }, {
        queryKey: ['servicesApi', selectionsHandleMap[moto || 'car'], {
          'filter[editors_choice]': slug,
          [`attributes[${moto || 'car'}_release]`]: 'base,image,stats',
          with_filtered_count: true,
          limit: BEST_CHOICE_LIMIT,
          offset: getOffset(search, BEST_CHOICE_LIMIT),
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
      },
    ],
  });

  const vehicleType = bestChoice?.attributes?.type || 'cars';

  const [{
    data: editorsChoice = {},
    isLoading: editorsChoiceAreLoading,
  }, {
    data: comparisons = [],
    isLoading: comparisonsAreLoading,
  }] = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', 'getEditorsChoice', {
          'filter[type]': vehicleType,
          'attributes[car_editors_choice]': 'base,image,seo',
          limit: 6,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
        enabled: !bestChoiceIsLoading,
      }, comparisonsQuery({ bestChoice }, {
        enabled: !bestChoiceIsLoading,
      }),
    ],
  });

  const isLoading = bestChoiceIsLoading || selectionsAreLoading;
  const footerContentIsLoading = editorsChoiceAreLoading || comparisonsAreLoading;

  return {
    bestChoice,
    editorsChoice,
    comparisons,
    editorsChoiceSeo,
    selections,
    seoIsLoading,
    isLoading,
    footerContentIsLoading,
  };
}

