import PropTypes from 'prop-types';

import H1 from 'core/components/H1';
import Link from 'core/components/Link';
import QutoBrandIcon from 'core/components/QutoBrandIcon';

import { carBrandAttributes } from 'core/utils/prop-types/model';
import modelPropTypes from 'site/utils/prop-types/model';

import styles from './index.styl';

function TitleCatalog({ title, brand, withLink }) {
  const heading = <H1>{title}</H1>;

  if (!brand) return heading;

  const {
    type,
    attributes: {
      slug: brandSlug,
    } = {},
  } = brand;

  const isMoto = type === 'moto_brand';

  const Icon = (
    <QutoBrandIcon brand={brand} className={styles.logo} />
  );

  return (
    <div className={styles.header}>
      {withLink ? (
        <Link to={`${isMoto ? '/moto' : ''}/${brandSlug}`}>
          {Icon}
        </Link>
      ) : Icon}
      {heading}
    </div>
  );
}


TitleCatalog.defaultProps = {
  withLink: true,
};

TitleCatalog.propTypes = {
  /** Текст заголовка */
  title: PropTypes.string,
  /** Модель бренда `carBrandAttributes` */
  brand: modelPropTypes(carBrandAttributes).isRequired,
  /**
   * Делать ли ссылку на страницу бренда с логотипа бренда
   */
  withLink: PropTypes.bool,
};

export default TitleCatalog;
