import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-sticky/lib/Sticky';
import StickyContainer from 'react-sticky/lib/Container';

import { Desktop, Mobile, withBreakpoint } from 'core/components/breakpoint';

import Page from 'core/components/Page';
import ContentBackground from 'core/components/ContentBackground';
import ColumnLayout from 'core/components/ColumnLayout';
import AdWrapper from 'core/components/Ad/AdWrapper';

import CarInfoBar from 'site/components/CarInfoBar';

import BorodaService from 'site/components/BorodaService';
import ParallaxWrapper from 'site/components/Ads/ParallaxWrapper';
import Classmates from 'site/components/Classmates';
import CostLineChart from 'site/components/CostLineChart';
import Comparison from 'site/components/Comparison';
import { PageIndent, ListContentBg, Indent } from 'site/components/Wrappers';
import { InnerSideColumnPart1, InnerSideColumnPart2 } from 'site/components/InnerSideColumn';
import { Listing2 } from 'site/components/Ads/mobile';
import FooterAds from 'site/components/FooterAds';
import SimilarOffers from 'site/components/SimilarOffers';

import modelPropTypes, { carModificationAttributes } from 'site/utils/prop-types/model';

import {
  SIDE_INDENT,
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  CARINFOBAR_DESKTOP_HEIGHT,
} from 'site/constants';

import Expenses from '../expenses';

import styles from './index.styl';


class ModificationLayout extends PureComponent {
  state = {
    mobileBottomOffset: 0,
  };

  componentDidMount() {
    if (process.env.BROWSER_RUNTIME && this.props.isMobile) {
      this.calcBottomOffset(window.innerHeight);
    }
  }

  calcBottomOffset = () => {
    this.setState({
      mobileBottomOffset: window.innerHeight,
    });
  };

  render() {
    const {
      modification,
      comparisons,
      isMobile,
      children,
    } = this.props;

    const {
      price,
      cost_of_ownership: ownershipCost,
    } = modification?.attributes || {};

    const {
      run,
      geo_url: geoUrl,
      depreciation,
    } = ownershipCost || {};

    const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

    const { mobileBottomOffset } = this.state;

    return (
      <Page>
        <ListContentBg marginCollapse>
          <AdWrapper bottom={vertical}>
            <StickyContainer>
              <Desktop>
                <Sticky>
                  {({ style, isSticky }) => (
                    <div style={{ position: 'relative', ...style, zIndex: 111 }} {...isSticky && { className: styles.sticked }}>
                      <ContentBackground>
                        <Indent left={SIDE_INDENT} right={SIDE_INDENT}>
                          <CarInfoBar modification={modification} />
                        </Indent>
                      </ContentBackground>
                    </div>
                  )}
                </Sticky>
              </Desktop>

              <PageIndent>
                <ColumnLayout
                  rightColumn={(
                    <InnerSideColumnPart1 additionalOffsetTop={CARINFOBAR_DESKTOP_HEIGHT} />
                  )}
                >
                  {children}
                  <Indent bottom={vertical} />
                  {(Boolean(price) && depreciation) && (
                    <Indent bottom={vertical}>
                      <CostLineChart basePrice={price} depreciation={depreciation} />
                    </Indent>
                  )}
                  <Expenses run={run} geoUrl={geoUrl} />
                  <Indent bottom={vertical} />
                  <Classmates withShadowBg />
                </ColumnLayout>
              </PageIndent>

              <Mobile>
                <Sticky bottomOffset={-mobileBottomOffset}>
                  {({ style, isSticky }) => (
                    <div style={{ position: 'relative', ...style, top: 'auto', bottom: 0, zIndex: 10 }} {...isSticky && { className: styles.sticked }}>
                      <CarInfoBar modification={modification} />
                    </div>
                  )}
                </Sticky>
              </Mobile>
            </StickyContainer>

            <PageIndent>
              <ParallaxWrapper />

              <ColumnLayout rightColumn={<InnerSideColumnPart2 />}>
                <SimilarOffers />
                <Mobile>
                  <Listing2 />
                </Mobile>
                <Comparison
                  content={comparisons}
                  withShadowBg
                  title='Эту модель часто сравнивают'
                />
                <Indent bottom={vertical} />
                <BorodaService />
              </ColumnLayout>
            </PageIndent>
            <FooterAds contextCount={2} />
          </AdWrapper>
        </ListContentBg>
      </Page>
    );
  }
}


ModificationLayout.propTypes = {
  isMobile: PropTypes.bool,
  modification: modelPropTypes(carModificationAttributes),
  comparisons: PropTypes.array,
};

export default withBreakpoint(ModificationLayout);
