import { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';

import { Route, Switch, Redirect } from 'core/libs/router';

import SecondaryMenu from 'site/components/SecondaryMenu';
import PageLayoutService from 'site/components/PageLayoutService';
import { GeoContext } from 'site/components/GeoContext';

import Offers from 'site/pages/offers';

import MotoOffers from 'site/forms/MotoOffers';

import { MOTO_URL } from 'site/constants';


export default function MotoOffersLayout({ isMobile }) {
  const { geo, geoSlug } = useContext(GeoContext);

  return (
    <Fragment>
      <SecondaryMenu section='inventory' />
      <MotoOffers geo={geo} />
      <PageLayoutService bgContentList={isMobile}>
        <Switch>
          <Route
            exact
            path={`${MOTO_URL}/inventory/`}
            render={params => <Redirect to={params.match.path + '/' + geoSlug} />}
          />
          <Route
            exact
            path={`${MOTO_URL}/inventory/:geo?/:brand?/:model?/:generation?`}
            component={Offers}
          />
        </Switch>
      </PageLayoutService>
    </Fragment>
  );
}

MotoOffersLayout.propTypes = {
  isMobile: PropTypes.bool,
};
