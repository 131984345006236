import PropTypes from 'prop-types';
import cx from 'classnames';

import resolveRelationships from 'core/utils/relationships';

import { Desktop, Mobile, withNonPureBreakpoint } from 'core/components/breakpoint';

import Divider from 'site/components/Divider';
import ReleaseNavigation from 'site/components/ReleaseNavigation';
import { Indent } from 'site/components/Wrappers';
import ReleaseTabs from 'site/components/ReleaseTabs';

import styles from './index.styl';

import { SIDE_INDENT, SIDE_INDENT_MOBILE } from 'site/constants';


const relationships = resolveRelationships(['brand'], {}, {});

function Navigation({ release, isMobile, isBrandTagExists }) {
  const horizontal = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;

  const {
    brand: {
      name: brandName,
    },
  } = relationships(release);

  return (
    <div className={cx(styles.navigation, isMobile && styles.mobile)}>
      <Desktop>
        <ReleaseNavigation brandName={brandName} release={release} />
        <Indent left={horizontal} />
        <ReleaseTabs release={release}  isBrandTagExists={isBrandTagExists} />
      </Desktop>
      <Mobile>
        <Indent left={horizontal} right={horizontal}>
          <ReleaseNavigation brandName={brandName} release={release} />
          <Divider />
        </Indent>
        <ReleaseTabs release={release} isBrandTagExists={isBrandTagExists} />
      </Mobile>
    </div>
  );
}

Navigation.propTypes = {
  isMobile: PropTypes.bool,
  release: PropTypes.object,
  isBrandTagExists: PropTypes.bool,
};

export default withNonPureBreakpoint(Navigation);
