import { Fragment } from 'react';

import { Desktop, Mobile } from 'core/components/breakpoint';

import modelPropTypes, { carModificationAttributes } from 'site/utils/prop-types/model';

import ViewOnDesktop from './ViewOnDesktop';
import ViewOnMobile from './ViewOnMobile';


export default function CarInfoBar({ modification }) {
  return (
    <Fragment>
      <Desktop>
        <ViewOnDesktop modification={modification} />
      </Desktop>
      <Mobile>
        <ViewOnMobile modification={modification} />
      </Mobile>
    </Fragment>
  );
}

CarInfoBar.propTypes = {
  /** Данные о модификации, соответствующие модели `carModificationAttributes` */
  modification: modelPropTypes(carModificationAttributes),
};
