import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/components/theme';

import ArrowRightSm from 'site/icons/ArrowRightSm';
import { Indent } from 'site/components/Wrappers';
import { VERTICAL_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';


function TextExpander({ text, maxParagraphs, theme }) {
  const [hidden, setHidden] = useState(true);

  if (!text) return null;

  const linkText = hidden ? 'Читать полностью' : 'Свернуть';
  const paragraphs = text.split('\n').map((item, idx) => <span key={idx}>{item}<br /></span>);

  if (paragraphs.length <= maxParagraphs) {
    return paragraphs;
  }

  return (
    <Fragment>
      <style jsx>{`
        .link
          color ${theme.colors.active1}
          & :global([id*="!"])
            stroke ${theme.colors.active1}

          &:hover
            color ${theme.colors.primary}
            & :global([id*="!"])
              stroke ${theme.colors.primary}
      `}</style>
      {hidden ? paragraphs.slice(0, maxParagraphs) : paragraphs}
      <Indent bottom={VERTICAL_INDENT_MOBILE} top={VERTICAL_INDENT_MOBILE}>
        <a className={cx(styles.link, 'link')} onClick={() => setHidden(!hidden)}>
          {linkText}<ArrowRightSm className={hidden ? styles.arrowDown : styles.arrowUp} />
        </a>
      </Indent>
    </Fragment>
  );
}

TextExpander.propTypes = {
  /**
   Текст, который будет обрезаться
   */
  text: PropTypes.string,
  /**
   Количество разрывов строк, после которого произойдет обрезание
   */
  maxParagraphs: PropTypes.number,
  /** @ignore */
  theme: PropTypes.object,
};

const TextExpanderWithHOCs = withTheme(TextExpander);
TextExpanderWithHOCs.displayName = 'TextExpander';

export default TextExpanderWithHOCs;
export { TextExpander as StorybookComponent };
