import useRequest from 'core/hooks/useRequest';

import { renderError } from 'core/utils/app-status-helper';
import { denormalizeData } from 'core/utils/api';

import queryString from 'core/libs/query-string';

import { servicesApi } from 'site/api/definitions/services';

import { isMotoPath } from 'site/utils';


function getValueDataFromRoute(location, match) {
  const { brand, geo } = match.params;
  const { search } = queryString.parse(location.search);

  return {
    geo,
    brand,
    search,
  };
}

export default function useDealersContent(queryParams) {
  const {
    match,
    location,
    history,
  } = queryParams;

  const { pathname } = location;

  const isMoto = isMotoPath(pathname);

  const {
    geo,
    brand: brandSlug,
    search: dealerNetworkId,
  } = getValueDataFromRoute(location, match);

  const type = isMoto ? 'moto' : 'car';
  const searchFilter =  brandSlug ? `${type}_brand` : 'dealer_network';

  const requestParams = {
    'filter[geo]': geo,
    [`filter[${searchFilter}]`]: brandSlug || dealerNetworkId,
    'relations[dealer]': `geo,${type}_brands,dealer_network`,
    include: `geo,${type}_brand,dealer_network`,
    'attributes[dealer]': 'base,stats',
    'attributes[dealer_network]': 'image',
    [`attributes[${type}_brand]`]: 'base,image',
    'filter[type]': type,
    'filter[status]': 'official',
  };

  const [{
    data: dealersSeo = {},
    isLoading: dealersSeoIsLoading,
  }, {
    data: firstDealers = {},
    isLoading: firstDealersAreLoading,
  }] = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', `get${isMoto ? 'Moto' : 'Car'}Dealers${brandSlug ? 'Brand' : 'Geo'}Seo`, {
          'filter[geo]': geo,
          'filter[brand]': brandSlug,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params).catch(err => renderError(err, history)),
        select: denormalizeData,
      }, {
        queryKey: ['servicesApi', 'getDealers', {
          ...requestParams,
          with_filtered_count: true,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
      },
    ],
  });

  const filteredCount = firstDealers?.meta?.filtered_count || 0;
  const limitPerRequest = firstDealers?.data?.length || 0;
  const iterations = filteredCount + limitPerRequest ? Math.ceil(filteredCount / limitPerRequest) : 1;

  const queries = [];

  for (let i = 1; i < iterations; i++) {
    queries.push({
      queryKey: ['servicesApi', 'getDealers', {
        ...requestParams,
        offset: i * limitPerRequest,
      }],
      queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    });
  }

  const restDealersResults = useRequest({ queries });
  const restDealersAreLoading = restDealersResults.some(({ isLoading }) => isLoading);

  const dealers = denormalizeData(restDealersResults
    .reduce((acc, { data: item }) => {
      if (!item?.attributes?.latitude && !item?.attributes?.longitude) {
        return acc;
      }

      const { data, included } = acc;

      return {
        data: data.concat(item?.data),
        included: included.concat(item?.included),
      };
    }, firstDealers));

  return {
    dealersSeo,
    dealers,
    dealersAreLoading: firstDealersAreLoading || restDealersAreLoading,
    isLoading: dealersSeoIsLoading,
  };
}
