import { Fragment } from 'react';
import PropTypes from 'prop-types';

import resolveRelationships from 'core/utils/relationships';

import { withBreakpoint, Mobile } from 'core/components/breakpoint';
import Feed from 'core/components/Feed';

import { Indent } from 'site/components/Wrappers';
import Divider from 'site/components/Divider';
import TitleUpperCase from 'site/components/TitleUpperCase';
import NothingFound from 'site/components/NothingFound';

import CardModification1WithPrice from 'site/cardsService/CardServiceModification1/cards/CardModification1WithPrice';
import CardModification2WithPrice from 'site/cardsService/CardServiceModification2/cards/CardModification2WithPrice';

import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


const requiredPayloadImports = ['body'];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  body: {},
});


function Releases(props) {
  const {
    releases,
    isMobile,
  } = props;

  if (!releases?.length) return <NothingFound />;

  const releasesGenerations = releases.reduce((groups, release) => {
    const {
      body: { name },
    } = relationships(release);

    if (groups[name]) {
      groups[name].push(release);
    } else {
      groups[name] = [release];
    }

    return groups;
  }, {});

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Fragment>
      <Mobile>
        <Divider />
      </Mobile>
      {Object.keys(releasesGenerations).map(releaseGeneration => (
        <Indent bottom={vertical} key={releaseGeneration}>
          <TitleUpperCase title={releaseGeneration} />
          <Feed
            content={releasesGenerations[releaseGeneration]}
            card={isMobile ? CardModification2WithPrice : CardModification1WithPrice}
          />
        </Indent>
      ))}
    </Fragment>
  );
}

Releases.propTypes = {
  isMobile: PropTypes.bool,
  releases: PropTypes.arrayOf(modelPropTypes(carReleaseAttributes)).isRequired,
};

export default withBreakpoint(Releases);
