import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import OptionModal from 'site/components/OptionModal';

import { ConfiguratorContext } from 'site/components/ConfiguratorProvider';

import { getDiffSummary } from 'site/utils/configurator';

import OptionDependencies from 'site/components/OptionDependencies';

import InstallVariants from './InstallVariants';

export default function OptionInstallVariants(props) {
  const {
    option,
    toggleModal,
    isOpen,
    modificationId,
  } = props;

  const configurations = useContext(ConfiguratorContext);

  const {
    options,
    packages,
    configuration: {
      price: configurationPrice,
      installed_options: installedOptions,
    } = {},
  } = configurations[modificationId] || {};

  const [diffPrice, setDiffPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  /**
   * Опция выбранная в модальном окне "Варианты установки".
   * Хранится для обновления `currentOption` при нажатии на кнопку "Применить".
   */
  const [installVariant, acceptInstallVariant] = useState(option);
  /**
   * Устанавливаем базовую опцию как текущую.
   * Используется для расчета стоимости на onChange радиогруппы вариантов установки.
   * Может быть изменена при принятии изменений в модальном окне "Варианты установки".
   */
  const [currentOption, setCurrentOption] = useState(option);
  /**
   * Вариант установки был принят.
   * Используется в навигации для перехода на следующий шаг и обратно.
   */
  const [accepted, handleAccept] = useState(false);

  function handlePrices(localOption) {
    const diff = getDiffSummary(localOption, options, packages, installedOptions);
    // Устанавливаем разницу в цене
    setDiffPrice(diff);
    // Устанавливаем общую стоимость учитывая все зависимости
    setTotalPrice(configurationPrice + diff);
  }

  const handleInstallVariants = useCallback(localOption => {
    handlePrices(localOption);
    acceptInstallVariant(localOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBackward = useCallback(() => {
    toggleModal();
    handleAccept(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!installedOptions) return;
    handlePrices(currentOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installedOptions]);

  return (
    <Fragment>
      {accepted ? (
        <OptionDependencies
          option={currentOption}
          isOpen={!isOpen}
          toggleModal={toggleModal}
          handleBackward={handleBackward}
          modificationId={modificationId}
        />
      ) : (
        <OptionModal
          isOpen={isOpen}
          toggleModal={toggleModal}
          handleClose={toggleModal}
          handleAccept={() => {
            setCurrentOption(installVariant);
            handleAccept(true);
            toggleModal();
          }}
          acceptText='Далее'
          diffPrice={diffPrice}
          totalPrice={totalPrice}
        >
          <InstallVariants
            handleChange={handleInstallVariants}
            currentOption={currentOption}
            option={option}
            modificationId={modificationId}
          />
        </OptionModal>
      )}
    </Fragment>
  );
}

OptionInstallVariants.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  option: PropTypes.object,
  modificationId: PropTypes.string,
};
