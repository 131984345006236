import cx from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import withTheme from 'core/components/theme';
import H4 from 'core/components/H4';

import BackIcon from 'site/icons/Back';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import getThumbnail from 'site/utils/get-thumbnail';

import { processLcvUrlPrefix } from 'site/utils';
import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';

import { MOTO_URL } from 'site/constants';

import styles from './index.styl';

function ReleaseNavigation(props) {
  const {
    backLink,
    release,
    brandName,
    match,
    theme: {
      colors: {
        primary,
        primary500,
        active1,
      },
      texts: {
        title6: {
          font: titleFont,
        },
      },
    },
  } = props;

  if (!release) return null;

  const {
    image,
    name: releaseName,
  } = release?.attributes || release || {};

  const {
    type,
  } = release;

  const {
    params: {
      lcv: lcvSlug,
      brand: brandSlug,
      model: modelSlug,
      generation: generationSlug,
      body: bodySlug,
      release: releaseSlug,
    },
  } = match;

  const isMoto = type === 'moto_release';
  const cover = getThumbnail(image);

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .brandLink
          color ${primary500}

          &:visited
            color ${primary500}

        .releaseLink
          color ${primary}

          &:visited
            color ${primary}

        .title
          font ${titleFont}
      `}</style>
    </scope>
  );

  const lcvUrlPrefix = processLcvUrlPrefix(Boolean(lcvSlug));
  const carReleaseLink = `/${brandSlug}/${modelSlug}/${generationSlug}/${bodySlug}`;
  const motoReleaseLink = `${MOTO_URL}/${brandSlug}/${modelSlug}/${releaseSlug}`;
  const releaseLink = isMoto ? motoReleaseLink : carReleaseLink;

  return (
    <div className={styles.releaseNavigation}>
      <style jsx>{`
        .${styles.back}
          &:hover
          &:focus
            :global([id*="$"])
              fill ${active1}
      `}</style>
      <Link
        to={lcvUrlPrefix + (backLink || releaseLink)}
        className={styles.back}
      >
        <BackIcon />
      </Link>
      <div className={styles.image}>
        <Image src={cover} width={100} />
      </div>
      <div>
        <Link
          to={`${lcvUrlPrefix}${isMoto ? MOTO_URL :  ''}/${brandSlug}`}
          className={cx(scope.wrapClassNames('brandLink'), styles.brandLink)}
        >{brandName}</Link>
        {releaseName && (
          <Link
            to={lcvUrlPrefix + releaseLink}
            className={cx(scope.wrapClassNames('releaseLink'), styles.releaseLink)}
          >
            <H4 is='h1' className={scope.wrapClassNames('title')}>{releaseName.replace(brandName, '')}</H4>
          </Link>
        )}
      </div>
      <scope.styles />
    </div>
  );
}

ReleaseNavigation.propTypes = {
  /** URL ссылки */
  backLink: PropTypes.string,
  /**
   * release может быть получен из разных ручек (/releases, /modification)
   * и передан как модель car_release, или как зависимость в relationships
   */
  release: PropTypes.oneOfType([
    modelPropTypes(carReleaseAttributes),
    carReleaseAttributes,
  ]),
  /** @ignore */
  theme: PropTypes.object,
  /** Набор match-параметров из URL */
  match: PropTypes.shape({
    params: PropTypes.shape({
      lcv: PropTypes.string,
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
      body: PropTypes.string,
      modification: PropTypes.string,
      release: PropTypes.string,
    }),
  }),
  /** Название бренда */
  brandName: PropTypes.string.isRequired,
};

const ReleaseNavigationWithHOCs = withRouter(withTheme(ReleaseNavigation));
ReleaseNavigationWithHOCs.displayName = 'ReleaseNavigation';

export default ReleaseNavigationWithHOCs;
export { ReleaseNavigation as StorybookComponent };
