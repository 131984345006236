import { Fragment } from 'react';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import {
  tagQuery,
  topicsQuery,
} from 'core/queries/tagTopics';

import TagTopics from 'core/components/TagTopics';

import SelectionList from 'site/components/SelectionList';
import PageLayout from 'site/components/PageLayout';

import { RUBRICS_PAGE_LIMIT } from 'site/constants';


export default function TagPage({ history, location, match }) {
  const [
    { data: tag, isLoading: tagIsLoading },
    { data: rawTopics, isLoading: topicsAreLoading },
  ] = useRequest({
    queries: [
      tagQuery({ history, match }),
      topicsQuery({
        location, history, match,
        limit: RUBRICS_PAGE_LIMIT,
        include: SelectionList.requiredPayloadImports,
        fields: SelectionList.requiredPayloadFields,
      }),
    ],
  });

  return (
    <PageLayout>
      <TagTopics
        tag={tag}
        topics={rawTopics}
        limit={RUBRICS_PAGE_LIMIT}
        isLoading={topicsAreLoading || tagIsLoading}
      >
        {({ topics = [] }) => {
          return (
            <Fragment>
              <SelectionList topics={topics} isLoading={topicsAreLoading} />
            </Fragment>
          );
        }}
      </TagTopics>
    </PageLayout>
  );
}

TagPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
};
