import { useContext } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

import { Route, withRouter, matchPath } from 'core/libs/router';

import configResolver from './shared-app-config';

import App from 'core/components/App';
import BaseRoutes from 'core/components/BaseRoutes';
import { Mobile, Desktop } from 'core/components/breakpoint';
import LegalPage from 'core/components/LegalPage';
import SharedRenderContext from 'core/components/SharedRenderContext';

import RulesRecommenders from 'site/components/RulesRecommenders';
import Layout from 'site/components/Layout';
import CompareProvider from 'site/components/CompareProvider';
import GeoProvider from 'site/components/GeoContext/GeoProvider';
import GlobalNativeStyles from 'site/components/Ads/styles/global';
import { InitHints } from 'site/components/Hint';
import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';

import {
  Shtorka,
  Background,
  Fullscreen as DesktopFullscreen,
} from 'site/components/Ads/desktop';

import {
  Rich,
  Fullscreen as MobileFullscreen,
} from 'site/components/Ads/mobile';

import About from 'site/pages/about';
import Main from 'site/pages/main';
import Topic from 'site/pages/topic';
import Comments from 'site/pages/comments';
import Profile from 'site/pages/profile';
import LeaderBoard from './pages/leaderBoard';

import { initAccounting } from 'site/utils/initializers';
import { isMediaPath } from 'core/utils/url-helper';

import routes from 'site/routes';

import styles from './index.styl';

// Указываем контейнер приложения react-modal'у
ReactModal.setAppElement('[data-placeholder="site-content"]');

initAccounting();

function Site({ location: { pathname } }) {
  const { requestHeaders } = useContext(SharedRenderContext);

  /**
   * Конфиг приложения нужно резолвить на каждую перерисовку чтоб при SSR
   * правильно резолвились переменные окружения.
   */
  const config = configResolver(requestHeaders);

  /**
   * Выставляем пуиды на основе роутинга только для сервисной части,
   * т.к в журнале пуиды запрашиваются из апишки
   */
  const puids = isMediaPath(pathname, config.mediaUrlPrefix)
    ? null
    : (routes.find(routeParams => matchPath(pathname, routeParams)) || {}).puids;

  return (
    <div className={styles.horScrollFixer}>
      <App {...config} puids={puids}>
        <ThemeSwitchWithColor tone='light'>
          <GeoProvider>
            <CompareProvider>
              <Layout>
                <BaseRoutes
                  main={Main}
                  topic={Topic}
                  comments={Comments}
                  about={About}
                  profile={Profile}
                  legal={LegalPage}
                  leaderBoard={LeaderBoard}
                  rulesRecommenders={RulesRecommenders}
                >
                  {routes.map((route, index) => (
                    <Route key={index} {...route} />
                  ))}
                </BaseRoutes>
              </Layout>

              <Desktop>
                <Background />
                <Shtorka />
                <DesktopFullscreen />
                <GlobalNativeStyles />
              </Desktop>

              <Mobile>
                <Rich />
                <MobileFullscreen />
              </Mobile>

              <InitHints />
            </CompareProvider>
          </GeoProvider>
        </ThemeSwitchWithColor>
      </App>
    </div>
  );
}

Site.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default withRouter(Site);
