import PropTypes from 'prop-types';
import cx from 'classnames';
import { Fragment } from 'react';

import Link from 'core/components/Link';
import Image from 'core/components/Image';
import { Desktop } from 'core/components/breakpoint';

import withTheme from 'core/components/theme';

import getThumbnail from 'site/utils/get-thumbnail';
import modelPropTypes, { carModificationAttributes } from 'site/utils/prop-types/model';
import resolveRelationships from 'core/utils/relationships';

import Comparison from './comparison';

import mapTypes from './types';
import mapSizes from './sizes';

import styles from './index.styl';


const relationships = resolveRelationships(['release'], {}, {});

const getTitle = (item) => {
  const {
    attributes: { name },
  } = item;

  const {
    release: { name: releaseName, short_name: shortName },
  } = relationships(item);

  return shortName || releaseName ? `${shortName || releaseName}, ${name}` : name;
};

const getCover = (item) => {
  const {
    attributes: { image },
  } = item;

  const {
    release: { image: releaseImage },
  } = relationships(item);

  const ratio = '3/2';

  return getThumbnail(image || releaseImage, ratio);
};


function CardServiceComparison(props) {
  const {
    type,
    theme,
    content,
    vehicleType,
  } = props;

  if (content?.items?.length !== 2) return null;

  const [{ id: id1 }, { id: id2 }] = content.items;

  const { size, withComparison } = mapTypes[type] || {};

  const {
    titleSize,
    swordsSize,
    width,
  } = mapSizes[size] || {};

  const cars = content.items.map((item) => ({
    title: getTitle(item),
    cover: getCover(item),
    id: item.id,
  }));

  return (
    <div className={size === 'sm' && styles._small}>
      <style jsx>{`
        .title
          color ${theme.colors.primary}
          font ${titleSize} ${theme.fonts.text}
          transition color ${theme.animations.hover}

        .${styles.content}
          &:hover
            .title
              color ${theme.colors.active1}

        .${styles.swords}
          flex 0 0 ${swordsSize}px
          height ${swordsSize}px
      `}</style>
      <Link type='secondary' to={`${vehicleType === 'cars' ? '' : '/' + vehicleType}/compare?uuid=${id1}&uuid=${id2}`}>
        <div className={styles.content}>
          {Object.values(cars).map((car, i) => (
            <Fragment key={car.id}>
              <div
                className={cx(
                  styles.car,
                  !i && styles._carFirst,
                  i && styles._carSecond)}
              >
                <div className={styles.image}>
                  {car.cover && <Image maxWidth={width} src={car.cover} />}
                </div>
                <div className={styles.content}>
                  <div className='title'>{car.title}</div>
                </div>
              </div>
              {!i && <div className={styles.swords} />}
            </Fragment>
          ))}
        </div>
      </Link>

      {withComparison && (
        <Desktop>
          <Comparison content={content} />
        </Desktop>
      )}
    </div>
  );
}

CardServiceComparison.defaultProps = {
  type: 1,
  vehicleType: 'cars',
};

CardServiceComparison.propTypes = {
  /** Тип карточки */
  type: PropTypes.oneOf([1, 2, 3]),
  /** Контент карточки, содержащий id и список модификаций ТС,
   * соответствующий модели `carModificationAttributes`
   */
  content: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    items: PropTypes.arrayOf(modelPropTypes(carModificationAttributes)),
  }),
  /** @ignore */
  theme: PropTypes.object,
  /** Тип ТС */
  vehicleType: PropTypes.string,
};

const CardWithHOCs = withTheme(CardServiceComparison);
CardWithHOCs.displayName = 'CardServiceComparison';

export default CardWithHOCs;
export { CardServiceComparison as StorybookComponent };
