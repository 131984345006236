import useRequest from 'core/hooks/useRequest';

import { renderError } from 'core/utils/app-status-helper';
import { denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';

import { servicesApi } from 'site/api/definitions/services';

import { comparisonsQuery } from 'site/queries/comparisons';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';


export const getOwnershipCostQueryKey = (run, geoUrl, params) => {
  const {
    brand: brandSlug,
    model: modelSlug,
    generation: generationSlug,
    body: bodySlug,
    modification: modificationSlug,
  } = params;

  return ['servicesApi', 'getModifications', {
    'attributes[car_modification]': 'ownership',
    'filter[brand]': brandSlug,
    'filter[model]': modelSlug,
    'filter[generation]': generationSlug,
    'filter[body]': bodySlug,
    'filter[modification]': modificationSlug,
    'ownership[run]': run,
    'ownership[geo]': geoUrl,
  }];
};

export default function useModificationContent(queryParams) {
  const {
    queryClient,
    match,
    history,
  } = queryParams;

  const {
    brand: brandSlug,
    model: modelSlug,
    generation: generationSlug,
    body: bodySlug,
    modification: modificationSlug,
    property: isPropertyPage,
  } = match.params;

  const {
    data: modification = {},
    isLoading: modificationIsLoading,
  } = useRequest({
    queryKey: ['servicesApi', 'getModifications', {
      'filter[brand]': brandSlug,
      'filter[model]': modelSlug,
      'filter[generation]': generationSlug,
      'filter[body]': bodySlug,
      'filter[modification]': modificationSlug,
      'attributes[car_modification]': `base,extended,ownership,stats,options,${isPropertyPage ? 'specifications' : ''}`,
      'relations[car_modification]': 'release,brand',
      include: 'car_release,car_brand',
      'attributes[car_release]': 'base,image,stats',
      limit: 1,
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
      .then(result => denormalizeData(result)[0])
      .then(rejectEmptyResult)
      .then(data => {
        const {
          cost_of_ownership: ownershipCost = {},
        } = data?.attributes || {};

        const {
          run,
          geo_url: geoUrl,
        } = ownershipCost;

        queryClient.setQueryData(
          getOwnershipCostQueryKey(run, geoUrl, match.params),
          ownershipCost,
        );

        return data;
      })
      .catch(err => renderError(err, history)),
  });

  const results = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', isPropertyPage ? 'getModificationPropertiesSeo' : 'getModificationSeo', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'filter[submodel]': bodySlug,
          'filter[modification]': modificationSlug,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      },

      comparisonsQuery({
        id: modification?.id,
      }, {
        enabled: !modificationIsLoading,
      }),
    ],
  });

  const {
    data: [
      modificationSeo = {},
      comparisons = [],
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  return {
    modification,
    modificationSeo,
    comparisons,
    isLoading: modificationIsLoading || resultsAreLoading,
  };
}
