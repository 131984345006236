import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import H3 from 'core/components/H3';
import Feed from 'core/components/Feed';
import Link from 'core/components/Link';
import Button from 'core/components/Button';
import Comments from 'core/components/Comments';
import { withBreakpoint } from 'core/components/breakpoint';

import Headline from 'site/components/Headline';

import { Indent } from 'core/components/Wrappers';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import CardReleaseFeature from 'site/cardsService/CardReleaseFeature';

import modelPropTypes, { releaseFeatureAttributes } from 'site/utils/prop-types/model';


function CommentsPage(props) {
  const {
    isMobile,
    location: {
      pathname,
    },
    currentFeature,
    filteredFeatures,
  } = props;

  const {
    id,
    attributes: {
      name,
    },
  } = currentFeature;

  const formattedLink = pathname.slice(0, pathname.length - '/comments'.length);

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Fragment>
      <Headline>{name}</Headline>

      <Indent bottom={20}>
        <Link to={formattedLink} type='secondary'>
          <Button>Вернуться к статье</Button>
        </Link>
      </Indent>

      <Comments
        adv
        advInterval={4}
        xid={`feature_${id}`}
      />

      <H3>Другие особенности</H3>
      <Indent bottom={vertical} />
      <Feed
        grid
        content={filteredFeatures}
        interitemSpacing={isMobile ? 0 : 20}
        columns={3}
        card={CardReleaseFeature}
      />
    </Fragment>
  );
}

CommentsPage.propTypes = {
  currentFeature: modelPropTypes(releaseFeatureAttributes),
  filteredFeatures: PropTypes.arrayOf(modelPropTypes(releaseFeatureAttributes)),
  isMobile: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export default withBreakpoint(withRouter(CommentsPage));
