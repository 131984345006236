import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import {
  generateSelectOptions,
} from 'site/utils/forms';
import { isMotoPath } from 'site/utils';

import Form from './Form';
import BaseForm from 'site/components/BaseForm';

import { DEALERS_URL } from 'site/constants';

import withContent from './withContent';
import { PATHNAME_FIELDS } from './constants';


class DealersForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state = this.getFormStateFromLocation(props.formOptions);
  }

  dependencies = {};

  get basePath() {
    const { location } = this.props;

    return `${isMotoPath(location.pathname) ? '/moto' : ''}${DEALERS_URL}`;
  }

  get pathnameFields() {
    const { search } = this.state;

    if (!search || search && (search.type === 'car_brand' || search.type === 'moto_brand')) {
      return PATHNAME_FIELDS;
    }

    return ['geo'];
  }

  componentDidUpdate(prevProps) {
    const {
      formOptions,
      isLoading,
      location: {
        pathname,
      },
    } = this.props;

    const {
      formOptions: prevFormOptions,
      location: {
        pathname: prevPathname,
      },
    } = prevProps;

    if (!isLoading && (formOptions !== prevFormOptions || pathname !== prevPathname)) {
      this.setState(this.getFormStateFromLocation(formOptions));
    }
  }

  getFormStateFromLocation = (formOptions) => {
    const {
      match: {
        params: {
          brand: brandSlug,
          geo: geoSlug,
        },
      },
      geo: defaultGeo,
      location,
    } = this.props;

    const filteredDefaultGeo = this.getGeoOptions([defaultGeo])[0];

    const { search: dealerNetworkId } = queryString.parse(location.search);

    const findSearchValue = ({ value }) => value === (brandSlug || dealerNetworkId);

    /**
     * Если значение не найдено, то устанавливаем его в null вместо
     * undefined, потому что undefined при сбросе фильтров не воспринимается
     * для некоторых контролов и там остается предыдущее установленное
     * значение.
     */
    const filteredBrands = formOptions.brandsDealersOptions;
    const search = filteredBrands.reduce((acc, { options }) => {
      return acc || options.find(findSearchValue);
    }, null);

    const geo = formOptions.filteredGeos.find(item => (item.attributes.url === geoSlug)) || filteredDefaultGeo;

    return {
      search,
      geo,
    };
  };

  getGeoOptions = memoize(
    (geos = []) => generateSelectOptions(geos, { value: 'attributes.url' })
  );

  render() {
    return (
      <Form
        formState={this.state}
        formOptions={this.props.formOptions}
        onChange={this.handleControlChange}
      />
    );
  }
}

DealersForm.propTypes = {
  formOptions: PropTypes.object,
  isLoading: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      geo: PropTypes.string,
    }),
  }),
  location: PropTypes.object,
};

export default withRouter(withContent(DealersForm));
