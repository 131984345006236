import useRequest from 'core/hooks/useRequest';

import { renderError } from 'core/utils/app-status-helper';
import { denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';

import { servicesApi } from 'site/api/definitions/services';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';


export default function useReleaseContent(queryParams) {
  const {
    match,
    match: {
      params: {
        brand: brandSlug,
        model: modelSlug,
        generation: generationSlug,
        body: bodySlug,
        review_id: reviewId,
      },
    },
    history,
  } = queryParams;

  const results = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', 'getReviews', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'filter[body]': bodySlug,
          'filter[review]': reviewId,
          'relations[review]': 'release,modification,user',
          include: 'car_release,car_modification,user',
          'attributes[review]': 'base,text,image,media',
          'attributes[car_release]': 'base,image',
          'attributes[car_modification]': 'base,extended',
          'attributes[user]': 'base,image',
          limit: 1,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .then(({ meta, ...otherData }) => ({
            seo: meta.seo,
            review: denormalizeData(otherData)[0],
          }))
          .then(reviewWithSeo => rejectEmptyResult(reviewWithSeo.review)
            .then(() => reviewWithSeo))
          .catch(err => renderError(err, history)),
      }, {
        queryKey: ['servicesApi', 'getReviews', {
          limit: 4,
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'filter[body]': bodySlug,
          'filter[review]': `!${reviewId}`,
          'relations[review]': 'release,modification,user',
          include: 'car_release,car_modification,user',
          'attributes[review]': 'base,text,image',
          'attributes[user]': 'base,image',
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      }, {
        queryKey: ['servicesApi', 'getReviewsSeo', match],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .catch(() => ({})),
        select: denormalizeData,
      },
    ],
  });

  const {
    data: [
      reviewData = {},
      reviews = [],
      reviewSeo = {},
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  return {
    reviewData,
    reviews,
    reviewSeo,
    isLoading: resultsAreLoading,
  };
}
