import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import { withNonPureTheme } from 'core/components/theme';
import Link from 'core/components/Link';

import themePropTypes from 'core/utils/prop-types/theme';
import resolveRelationships from 'core/utils/relationships';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import { isMotoPath } from 'site/utils';
import { carReleasePages, motoReleasePages, JOURNAL_URL, MOTO_URL } from 'site/constants';

import TabsWrapper from './TabsWrapper';

import styles from './index.styl';


const relationships = resolveRelationships(['features'], {}, {});

function Tabs(props) {
  const {
    location,
    isBrandTagExists,
    match: {
      params: {
        brand: brandSlug,
        model: modelSlug,
        generation: generationSlug,
        body: bodySlug,
        suffix: suffixSlug,
        release: releaseSlug,
      },
    },
    theme: {
      colors: {
        primary,
        primary400,
        lime1,
      },
    },
    release,
    release: {
      attributes: {
        photogallery: {
          attributes: {
            images: photoGalleryImages,
          },
        },
        video: videos,
        colors_count: colorsCount,
      },
    },
  } = props;

  const isMoto = isMotoPath(location.pathname);
  const releasePages = isMoto ? motoReleasePages : carReleasePages;

  const { features } = relationships(release);

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.link}
          color ${primary400}

          &:hover
          &:visited
            color ${primary}

        .active
          color ${primary}
          cursor default
          &:visited
            color ${primary}
          &:after
            background ${primary}

        .${styles.news}
          &:after
          &:before
            background ${lime1}
      `}</style>
    </scope>
  );

  const carBaseUrl = `/${brandSlug}/${modelSlug}/${generationSlug}/${bodySlug}`;
  const motoBaseUrl = `${MOTO_URL}/${brandSlug}/${modelSlug}/${releaseSlug}`;
  const baseUrl = isMoto ? motoBaseUrl : carBaseUrl;

  /**
   * Формируем список табов, по наличию данных для этих страниц
   *
   * TODO:
   *  По нужно оптимизировать API, чтобы не запрашивать все данные для отрисовки табов.
   *  Например:
   *  Для отрисовки таба "Фото и Видео" не нужно наличие всей галереи.
   *  Достаточно счетчика колличества фоток или просто флага что галерея заполнена.
   *  Тоже самое и для features.
   */
  const tabsData = releasePages.filter(({ suffix }) => {
    switch (suffix) {
      case '/photo':
        return Boolean(photoGalleryImages.length || videos.length);
      case '/features':
        return Boolean(features.length);
      case '/colors':
        return Boolean(colorsCount);
      default:
        return !suffix;
    }
  });

  if (isBrandTagExists) {
    tabsData.push({
      url: `${JOURNAL_URL}/label/${brandSlug}`,
      content: `Новости о ${brandSlug}`,
      suffix: 'news',
      // добавляем suffix для того, что бы isActive применялся также как для других Tabs
    });
  }

  if (tabsData.length === 0) return null;

  return (
    <TabsWrapper>
      <div className={styles.tabs}>
        {tabsData.map(({ suffix, content, url }) => {
          const tabUrl = url || (baseUrl + suffix);
          const isActive = !(suffixSlug || suffix)
            || suffixSlug === suffix.slice(1)
            || (suffixSlug === 'video' && suffix === '/photo');

          // выделяем каждый символ разным цветом таб "цвета"
          const colorTabIsActive = suffix === '/colors';

          return (
            <Link
              type='secondary'
              key={suffix || 'releasePageTab'}
              className={scope.wrapClassNames(
                styles.link,
                isActive && 'active',
                url && styles.news,
              )}
              to={tabUrl}
              {...url && { target: '_blank' }}
            >
              {colorTabIsActive ? (
                <Fragment>
                  {[...content].map((letter, index) => (
                    <span key={index}>
                      {letter}
                    </span>
                  ))}
                </Fragment>
              ) : content}</Link>
          );
        })}
      </div>
      <scope.styles />
    </TabsWrapper>
  );
}

Tabs.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  isBrandTagExists: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
      body: PropTypes.string,
      suffix: PropTypes.string,
      release: PropTypes.string,
    }),
  }).isRequired,
  theme: themePropTypes(`{
    colors: {
      primary250,
    },
  }`),
  release: PropTypes.object,
};

export { TabsWrapper };

export default withRouter(withNonPureTheme(Tabs));
