import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import {
  Section,
  Block,
} from 'core/components/Grid';

import { Mobile } from 'core/components/breakpoint';
import { Indent } from 'site/components/Wrappers';
import InputDropdown from 'site/components/InputDropdown';
import Chip from 'site/components/Chip';
import ChipRange from 'site/components/ChipRange';
import InputRange from 'site/components/InputRange';
import FullFilterToggler from 'site/components/FullFilterToggler';
import FullFormTopline from 'site/components/FullFormTopline';
import Divider from 'site/components/Divider';
import RadioClouds from 'site/components/RadioClouds';
import Checkboxes from 'site/components/Checkboxes';
import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';
import FormContainer from 'site/components/FormContainer';

import {
  rangeMarksArray,
} from 'site/utils/forms';

import {
  SIDE_INDENT,
  SIDE_INDENT_MOBILE,
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  FULL_FILTER_HASH,
} from 'site/constants';

import styles from './index.styl';


function Form(props) {
  const {
    formState,
    formOptions,
    ranges,
    isMobile,
    onChange,
    location,
    navigateToNewUrl,
    toggleFullForm,
    resetAllForm,
    fullFiltersCount,
    allFiltersCount,
    isFiltersDisabled,
  } = props;

  const {
    filteredBrands,
    filteredModels,
    filteredTypes,
    filteredClasses,
    filteredEngineTypes,
    filteredConfigurate,
    filtiredCooling,
    filteredTransmission,
    filteredTransfer,
    filteredBoost,
    filteredAbs,
    filteredUsages,
  } = formOptions;

  const {
    brand,
    model,
    boost,
    cylinders,
    engine_type: engineType,
    abs,
    configurate,
    cooling,
    transfer,
    transmission,
    usages,
  } = formState;

  const verticalIndent = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const horizontalIndent = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;

  const isFullFilterOpened = location.hash === FULL_FILTER_HASH;

  const isBrandSelected = Boolean(Array.isArray(brand)
    ? brand[0]
    : brand
  );

  const isModelSelected = Boolean(Array.isArray(model)
    ? model[0]
    : model
  );

  const isElectroEngineType = engineType ? engineType === 'e' : !!engineType;

  const rangeDropdownProps = {
    contentPosition: isMobile ? 'center' : 'right',
    contentPadding: (horizontalIndent - 2) + 'px',
  };

  const topRow = [
    {
      name: 'brand',
      component: InputDropdown,
      options: filteredBrands,
      label: 'Марка',
      placeholder: 'Введите название марки',
      isMulti: false,
      indent: { ...!isMobile && { right: 20 } },
      hideBorder: isMobile,
    },
    {
      name: 'model',
      component: InputDropdown,
      options: filteredModels,
      label: 'Модельный ряд',
      placeholder: 'Введите название модели',
      isDisabled: !isBrandSelected,
      isMulti: false,
      indent: { ...isMobile ? { top: 1 } : { right: 35 } },
      roundingPosition: isMobile ? 'bottom' : 'top',
      hideBorder: isMobile,
    },
    {
      name: 'price',
      component: InputRange,
      title: 'Цена',
      min: ranges.price.min,
      max: ranges.price.max,
      step: 10000,
      unit: '₽',
      isDisabled: isFiltersDisabled && isModelSelected,
      arrangeJustify: true,
      indent: { ...isMobile && { top: 20 } },
    },
  ];

  const secondRow = [
    {
      name: 'type_uuid',
      component: Chip,
      options: filteredTypes,
      placeholder: 'Тип',
      isDisabled: isFiltersDisabled && isModelSelected,
    },
    {
      name: 'class_uuid',
      component: Chip,
      options: filteredClasses,
      placeholder: 'Класс',
      isDisabled: isFiltersDisabled && isModelSelected,
    },
    {
      name: 'volume',
      component: ChipRange,
      placeholder: 'Объем двигателя',
      title: 'Объем',
      menuWidth: 290,
      min: ranges.volume.min,
      max: ranges.volume.max,
      unit: 'см³',
      dropdownProps: rangeDropdownProps,
      isDisabled: (isFiltersDisabled && isModelSelected) || isElectroEngineType,
    },
    {
      name: 'power',
      component: ChipRange,
      placeholder: 'Мощность (л.с.)',
      title: 'Мощность',
      menuWidth: 290,
      min: ranges.power.min,
      max: ranges.power.max,
      unit: 'л.с.',
      dropdownProps: rangeDropdownProps,
      isDisabled: isFiltersDisabled && isModelSelected,
    },
  ];

  return (
    <Fragment>
      {isFullFilterOpened && (
        <FullFormTopline
          toggleFullForm={toggleFullForm}
          resetAllForm={resetAllForm}
          navigateToNewUrl={navigateToNewUrl}
          allFiltersCount={allFiltersCount}
          formType='catalog'
          formState={formState}
          ranges={ranges}
          isMoto
        />
      )}
      <ThemeSwitchWithColor tone='dark'>
        <FormContainer>
          <Section>
            {topRow.map(({ component: Component, ...params }, i) => (
              <Block
                width={12}
                desktop={4}
                key={i}
              >
                <Indent {...params.indent}>
                  <Component
                    {...params}
                    onChange={onChange}
                    value={formState[params.name]}
                  />
                </Indent>
              </Block>
            ))}
          </Section>
          <Indent top={isMobile ? 10 : 5} />
          <Section>
            {secondRow.map(({ component: Component, ...params }, i) => (
              <div className={styles.chipWrapper} key={i}>
                <Component
                  {...params}
                  onChange={value => onChange(value, params)} // Странно что здесь вручную передавать 2-й параметр. Разобраться почему так.
                  value={formState[params.name]}
                />
              </div>
            ))}
            <div className={styles.fullFilterToggler}>
              <Indent top={15}>
                <FullFilterToggler
                  location={location}
                  toggleFullForm={toggleFullForm}
                  fullFiltersCount={fullFiltersCount}
                />
              </Indent>
            </div>
          </Section>
          {isFullFilterOpened && (
            <Fragment>
              <Divider
                type={4}
                top={verticalIndent}
                bottom={verticalIndent}
                {...isMobile && {
                  left: -SIDE_INDENT_MOBILE,
                  right: -SIDE_INDENT_MOBILE,
                }}
              />
              <Section>
                <Block width={12} desktop={6}>
                  <RadioClouds
                    options={filteredEngineTypes}
                    name='engine_type'
                    onChange={onChange}
                    title='Тип двигателя'
                    value={engineType}
                  />
                </Block>
                {isMobile && (
                  <Block width={12}>
                    <Divider
                      type={4}
                      top={verticalIndent}
                      bottom={verticalIndent}
                      {...isMobile && {
                        left: -SIDE_INDENT_MOBILE,
                        right: -SIDE_INDENT_MOBILE,
                      }}
                    />
                  </Block>
                )}
                <Block width={12} desktop={6}>
                  <Checkboxes
                    title='Наддув'
                    options={filteredBoost}
                    inputName='boost'
                    checkboxesState={boost}
                    onChange={onChange}
                    isDisabled={isElectroEngineType}
                  />
                </Block>
              </Section>
              <Divider
                type={4}
                top={verticalIndent}
                bottom={verticalIndent}
                {...isMobile && {
                  left: -SIDE_INDENT_MOBILE,
                  right: -SIDE_INDENT_MOBILE,
                }}
              />
              <Section>
                <Block width={12} desktop={3}>
                  <InputRange
                    name='cylinders'
                    title='Количество цилиндров'
                    min={ranges.cylinders.min}
                    max={ranges.cylinders.max}
                    step={1}
                    hiddenInputValue
                    marks={rangeMarksArray(ranges.cylinders.min, ranges.cylinders.max, 1)}
                    onChange={onChange}
                    value={cylinders}
                    isDisabled={isElectroEngineType}
                    resetValue={isElectroEngineType}
                    customEndMark={6}
                    replaceValue={[5, 6]}
                  />
                </Block>
                {isMobile && (
                  <Block width={12}>
                    <Divider
                      type={4}
                      top={verticalIndent}
                      bottom={verticalIndent}
                      {...isMobile && {
                        left: -SIDE_INDENT_MOBILE,
                        right: -SIDE_INDENT_MOBILE,
                      }}
                    />
                  </Block>
                )}
                <Block desktop={3} />
                <Block width={12} desktop={6}>
                  <RadioClouds
                    options={filteredConfigurate}
                    name='configurate'
                    onChange={onChange}
                    title='Конфиг. двигателя'
                    isDisabled={isElectroEngineType}
                    value={configurate}
                  />
                </Block>
              </Section>
              <Divider
                type={4}
                top={verticalIndent}
                bottom={verticalIndent}
                {...isMobile && {
                  left: -SIDE_INDENT_MOBILE,
                  right: -SIDE_INDENT_MOBILE,
                }}
              />
              <RadioClouds
                options={filtiredCooling}
                name='cooling'
                onChange={onChange}
                title='Система охлаждения'
                value={cooling}
              />
              <Divider
                type={4}
                top={verticalIndent}
                bottom={verticalIndent}
                {...isMobile && {
                  left: -SIDE_INDENT_MOBILE,
                  right: -SIDE_INDENT_MOBILE,
                }}
              />
              <Section>
                <Block width={12} desktop={6}>
                  <RadioClouds
                    options={filteredTransfer}
                    name='transfer'
                    onChange={onChange}
                    title='Главная передача'
                    value={transfer}
                  />
                </Block>
                {isMobile && (
                  <Block width={12}>
                    <Divider
                      type={4}
                      top={verticalIndent}
                      bottom={verticalIndent}
                      {...isMobile && {
                        left: -SIDE_INDENT_MOBILE,
                        right: -SIDE_INDENT_MOBILE,
                      }}
                    />
                  </Block>
                )}
                <Block width={12} desktop={6}>
                  <RadioClouds
                    options={filteredTransmission}
                    name='transmission'
                    onChange={onChange}
                    title='Коробка передач'
                    value={transmission}
                  />
                </Block>
              </Section>
              <Divider
                type={4}
                top={verticalIndent}
                bottom={verticalIndent}
                {...isMobile && {
                  left: -SIDE_INDENT_MOBILE,
                  right: -SIDE_INDENT_MOBILE,
                }}
              />
              <Section>
                <Block width={12} desktop={6}>
                  <Checkboxes
                    title='Безопасность'
                    options={filteredAbs}
                    inputName='abs'
                    checkboxesState={abs}
                    onChange={onChange}
                  />
                </Block>
                {isMobile && (
                  <Block width={12}>
                    <Divider
                      type={4}
                      top={verticalIndent}
                      bottom={verticalIndent}
                      {...isMobile && {
                        left: -SIDE_INDENT_MOBILE,
                        right: -SIDE_INDENT_MOBILE,
                      }}
                    />
                  </Block>
                )}
                <Block width={12} desktop={6}>
                  <Indent top={!isMobile ? 40 : 0}>
                    <Checkboxes
                      options={filteredUsages}
                      inputName='usages'
                      checkboxesState={usages}
                      onChange={onChange}
                    />
                  </Indent>
                </Block>
                {isMobile && (
                  <Block width={12}>
                    <Divider
                      type={4}
                      top={verticalIndent}
                      bottom={verticalIndent}
                      {...isMobile && {
                        left: -SIDE_INDENT_MOBILE,
                        right: -SIDE_INDENT_MOBILE,
                      }}
                    />
                  </Block>
                )}
              </Section>
              <Mobile><Indent bottom={50} /></Mobile>
            </Fragment>
          )}
        </FormContainer>
      </ThemeSwitchWithColor>
    </Fragment>
  );
}

Form.propTypes = {
  isFiltersDisabled: PropTypes.bool,
  isMobile: PropTypes.bool,
  formState: PropTypes.object,
  formOptions: PropTypes.object,
  ranges: PropTypes.object,
  location: PropTypes.object,
  onChange: PropTypes.func,
  onCarTypeChange: PropTypes.func,
  navigateToNewUrl: PropTypes.func,
  toggleFullForm: PropTypes.func,
  resetAllForm: PropTypes.func,
  fullFiltersCount: PropTypes.number,
  allFiltersCount: PropTypes.number,
};

export default withRouter(Form);
