import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import withTheme from 'core/components/theme';

import * as skin from './skin'; // eslint-disable-line import/no-namespace

class InputDropdown extends PureComponent {
  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    themeType: PropTypes.oneOf(['basic']),
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    /** Компонент, выводящий сообщение о пустом списке */
    noOptionsMessage: PropTypes.func,
    /** Компонент, выводящий сообщение о загрузке списка */
    loadingMessage: PropTypes.func,
    /** Свойство, включающее возможность множественного выбора */
    isMulti: PropTypes.bool,
    /** @ignore */
    theme: PropTypes.object,
    /** Свойство позволяет задизейблить контрол */
    isDisabled: PropTypes.bool,
    roundingPosition: PropTypes.string,
    /** Скрывает нижнее подчеркивание инпута */
    hideBorder: PropTypes.bool,
    /** @ignore */
    dataQa: PropTypes.string,
  };

  static defaultProps = {
    themeType: 'basic',
    roundingPosition: 'top',
    hideBorder: false,
    isMulti: true,
    noOptionsMessage: () => 'Ничего не найдено :(',
    loadingMessage: () => 'Загрузка...',
    placeholder: '',
  };

  state = {
    isFocused: false,
  };

  setFocused = value => {
    this.setState({ isFocused: value });
  };

  getCustomStyles = () => {
    const inputConstAtoms = this.props.theme.controls.formInput.const;

    return ({
      menu: provided => ({
        ...provided,
        marginTop: 4,
        marginBottom: 0,
        overflow: 'hidden',
        zIndex: 10,
        background: '#FFF',
      }),
      menuList: provided => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
      }),
      valueContainer: provided => ({
        ...provided,
        padding: '0',
      }),
      multiValue: provided => ({
        ...provided,
        margin: 0,
        marginRight: 5,
        background: inputConstAtoms.multiValue.background,
        border: inputConstAtoms.multiValue.border,
        cursor: 'default',
      }),
      singleValue: provided => ({
        ...provided,
        margin: 0,
        fontWeight: 500,
      }),
      multiValueLabel: provided => ({
        ...provided,
        fontSize: '.85em',
        lineHeight: 1,
        padding: 3,
        color: inputConstAtoms.multiValueLabel.color,
        fontWeight: 500,
      }),
      option: (provided, state) => {
        return ({
          ...provided,
          cursor: 'pointer',
          color: inputConstAtoms.optionColor,
          backgroundColor: state.isSelected || state.isFocused
            ? this.props.theme.colors.dividerLight
            : 'inherit',
          ':hover': {
            backgroundColor: this.props.theme.colors.dividerLight,
          },
        });
      },
      noOptionsMessage: (provided) => {
        return ({
          ...provided,
          color: inputConstAtoms.optionColor,
        });
      },
    });
  };

  render() {
    return (
      <Select
        {...this.props}
        isFocused={this.state.isFocused}
        setFocused={this.setFocused}
        styles={this.getCustomStyles()}
        components={{ ...skin }}
        tabIndex={this.props.isDisabled ? -1 : 0}
        tabSelectsValue={false}
        instanceId={this.props.name}
      />
    );
  }
}

const InputDropdownWithHOCs = withTheme(InputDropdown);
InputDropdownWithHOCs.displayName = 'InputDropdown';

export default InputDropdownWithHOCs;
export { InputDropdown as StorybookComponent };
