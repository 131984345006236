import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { topicQuery } from 'core/queries/topic';

import resolveRelationships from 'core/utils/relationships';

import Topic from 'core/components/Topic';
import LeaderBoardCore from 'core/components/GameCompare/LeaderBoard';
import { Indent, NegativeMobile } from 'core/components/Wrappers';
import PageLoader from 'core/components/Loader/PageLoader';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import TopicHeader from 'site/pages/topic/TopicHeader';
import TopicWrapper from 'site/pages/topic/TopicWrapper';


const relationships = resolveRelationships(['content'], {});

function LeaderBoard(props) {
  const { isMobile } = props;
  const { data, isLoading } = useRequest(topicQuery(props));

  if (isLoading) return <PageLoader />;

  return (
    <Topic content={data}>
      {topic => {
        const {
          content: {
            widgets,
          },
        } = relationships(topic);

        if (widgets.length === 0) return null;

        const {
          final_announce: finalAnnounce,
        } = widgets[0].attributes;

        return (
          <TopicWrapper content={topic}>
            <Indent top={isMobile ? 16 : 20} bottom={20}>
              <TopicHeader content={topic} isGameCompare />
            </Indent>
            <div>{finalAnnounce}</div>
            <Indent top={isMobile ? 35 : 25} />
            <NegativeMobile>
              <LeaderBoardCore content={topic} />
            </NegativeMobile>
            <Indent top={isMobile ? 80 : 50} />
          </TopicWrapper>
        );
      }}
    </Topic>
  );
}

LeaderBoard.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(LeaderBoard);
