import { Fragment, useContext } from 'react';

import { Route, Switch, Redirect } from 'core/libs/router';

import SecondaryMenu from 'site/components/SecondaryMenu';
import PageLayoutService from 'site/components/PageLayoutService';
import { GeoContext } from 'site/components/GeoContext';

import Offers from 'site/pages/offers';

import OffersForm from 'site/forms/Offers';


export default function OffersLayout() {
  const { geo, geoSlug } = useContext(GeoContext);

  return (
    <Fragment>
      <SecondaryMenu section='inventory' />
      <OffersForm geo={geo} />
      <PageLayoutService bgContentList>
        <Switch>
          <Route
            exact
            path='/inventory'
            render={params => <Redirect to={params.match.path + '/' + geoSlug} />}
          />
          <Route
            exact
            path='/inventory/:geo?/:brand?/:model?/:generation?'
            component={Offers}
          />
        </Switch>
      </PageLayoutService>
    </Fragment>
  );
}
