import PropTypes from 'prop-types';
import { useContext } from 'react';

import { Redirect } from 'core/libs/router';

import { GeoContext } from 'site/components/GeoContext';


export default function withGeoRedirect(Component) {
  const Wrapper = props => {
    const { geoSlug } = useContext(GeoContext);

    if (props.match.params.geo) return <Component {...props} />;

    return <Redirect to={`${props.match.url}/${geoSlug}`} />;
  };

  Wrapper.propTypes = { match: PropTypes.object };

  return Wrapper;
}
