import { denormalizeData } from 'core/utils/api';
import { getOffset } from 'core/utils/url-helper';

import { servicesApi } from 'site/api/definitions/services';

import { buildSearchRequestParams, isMotoPath } from 'site/utils';

import { RELEASES_LIMIT } from 'site/constants';


export const newReleasesQuery = () => ({
  queryKey: ['servicesApi', 'getNewReleases', {
    type: 'cars',
    'attributes[car_release]': 'base,image,media,seo,extended',
    include: 'car_release',
    'relations[car_innovation]': 'release',
  }],
  queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
  select: denormalizeData,
});

export const releasesQuery = (queryParams) => {
  const {
    location: {
      pathname,
      search,
    },
    match: {
      params: {
        lcv,
      },
    },
  } = queryParams || {};

  const isMoto = isMotoPath(pathname);
  const isLcvPage = Boolean(lcv);

  const handle = isMoto
    ? 'getMotoReleases'
    : 'getReleases';

  const vehiclePrefix = isMoto ? 'moto' : 'car';

  return {
    queryKey: ['servicesApi', handle, {
      ...(isLcvPage && { 'filter[car_type]': 'lcv' }),
      offset: getOffset(search, RELEASES_LIMIT),
      limit: RELEASES_LIMIT,
      with_filtered_count: true,
      [`attributes[${vehiclePrefix}_release]`]: 'base,image,stats',
      'filter[usages]': 'catalog,archive',
      ...buildSearchRequestParams(search),
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
      .then(data => ({
        meta: data.meta,
        releases: denormalizeData(data) || [],
      })),
  };
};

export const releasesCountQuery = ({ match }) => {
  const isLcvPage = Boolean(match.params.lcv);

  return {
    queryKey: ['servicesApi', 'getReleases', {
      ...(isLcvPage && { 'filter[car_type]': 'lcv' }),
      only_filtered_count: true,
      'filter[usages]': 'catalog,archive',
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    select: ({ meta }) => meta.filtered_count,
  };
};
