import { Fragment } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import {
  generateSelectOptions,
  initSelectValue,
  initRangeValue,
  initCheckboxValue,
  initRadioValue,
  initSwitchWithSeparatorValues,
} from 'site/utils/forms';

import BaseForm from 'site/components/BaseForm';
import FormModal from 'site/components/FormModal';

import withContent from './withContent';
import Form from './Form';

import {
  RANGES,
  FULL_FILTER_FIELDS,
  PATHNAME_FIELDS,
  BASE_PATH,
  DEPENDENCIES,
} from './constants';


class OffersForm extends BaseForm {
  constructor(props) {
    super(props);

    this.state = this.getFormStateFromLocation(
      props.formData.formOptions,
      props.brands,
      props.models,
      props.generations,
      props.geos,
    );
  }

  dependencies = DEPENDENCIES;

  ranges = RANGES;

  pathnameFields = PATHNAME_FIELDS;

  fullFilterFields = FULL_FILTER_FIELDS;

  basePath = BASE_PATH;

  get emptyPath() {
    return BASE_PATH + '/' + this.props.geoSlug;
  }

  componentDidUpdate(prevProps) {
    const {
      formData,
      brands,
      models,
      generations,
      geos,
      isLoading,
      location: {
        pathname,
        search,
      },
    } = this.props;

    const {
      formData: prevFormData,
      brands: prevBrands,
      models: prevModels,
      generations: prevGenerations,
      geos: prevGeos,
      location: {
        pathname: prevPathname,
        search: prevSearch,
      },
    } = prevProps;

    if (!isLoading && formData !== prevFormData) {
      this.ranges = formData.ranges;
      this.setState(this.getFormStateFromLocation(formData.formOptions, brands, models, generations, geos));
    }

    if (!isLoading
      && (
        brands !== prevBrands
        || models !== prevModels
        || generations !== prevGenerations
        || geos !== prevGeos
        || pathname !== prevPathname
        || search !== prevSearch
      )
    ) {
      this.setState(this.getFormStateFromLocation(formData.formOptions, brands, models, generations, geos));
    }
  }

  getFormStateFromLocation = (
    formOptions,
    brands = [],
    models = [],
    generations = [],
    geos = [],
  ) => {
    const {
      location,
      match: {
        params,
      },
      geo: defaultGeo,
    } = this.props;

    const formValuesFromUrl = {
      ...queryString.parse(location.search),
    };

    const filteredBrands = this.getBrandOptions(brands);
    const filteredGeos = this.getGeoOptions(geos);
    const filteredDefaultGeo = this.getDefaultGeoOption([defaultGeo])[0];

    /**
     * Если значение не найдено, то устанавливаем его в null вместо
     * undefined, потому что undefined при сбросе фильтров не воспринимается
     * для некоторых контролов и там остается предыдущее установленное
     * значение.
     */
    const brand = filteredBrands?.find(item => (
      item.attributes.slug === params.brand
    )) || null;

    const filteredModels = this.getModelOptions(models, brand);
    const model = brand && filteredModels?.find(item => (
      item.attributes.slug === params.model
    )) || null;

    const filteredGenerations = this.getGenerationOptions(generations, model);
    const generation = model && filteredGenerations?.find(item => (
      item.attributes.slug === params.generation
    )) || null;

    const geo = filteredGeos?.find(item => (
      item.attributes.url === params.geo
    )) || filteredDefaultGeo;

    return {
      brand,
      model,
      price: initRangeValue(
        'price',
        formValuesFromUrl,
        this.ranges.price
      ),
      generation,
      body: initSelectValue(
        'body',
        formValuesFromUrl,
        formOptions.filteredBodies,
        'attributes.slug',
      ),
      year: initRangeValue(
        'year',
        formValuesFromUrl,
        this.ranges.year
      ),
      engine_type: initSelectValue(
        'engine_type',
        formValuesFromUrl,
        formOptions.filteredEngineTypes
      ),
      transmission: initSelectValue(
        'transmission',
        formValuesFromUrl,
        formOptions.filteredTransmissions,
      ),
      condition: initSelectValue(
        'condition',
        formValuesFromUrl,
        formOptions.filteredConditions,
      ),
      geo,
      dealer_type: initRadioValue(
        'dealer_type',
        formValuesFromUrl,
      ),
      country: initSelectValue(
        'country',
        formValuesFromUrl,
        formOptions.filteredCountries,
      ),
      class: initSelectValue(
        'class',
        formValuesFromUrl,
        formOptions.filteredClasses,
        'attributes.url'
      ),
      assembly: initRadioValue(
        'assembly',
        formValuesFromUrl,
      ),
      power: initRangeValue(
        'power',
        formValuesFromUrl,
        this.ranges.power
      ),
      boost: initCheckboxValue(
        'boost',
        formValuesFromUrl,
      ),
      control: initRadioValue(
        'control',
        formValuesFromUrl,
      ),
      acceleration: initRangeValue(
        'acceleration',
        formValuesFromUrl,
        this.ranges.acceleration
      ),
      trunk_volume: initRangeValue(
        'trunk_volume',
        formValuesFromUrl,
        this.ranges.trunk_volume
      ),
      clearance: initRangeValue(
        'clearance',
        formValuesFromUrl,
        this.ranges.clearance
      ),
      number_of_seats: initSwitchWithSeparatorValues(
        'number_of_seats',
        formValuesFromUrl,
        formOptions.filteredSeats,
      ),
    };
  };

  getDefaultGeoOption = memoize(
    (geos = []) => generateSelectOptions(geos, { value: 'attributes.url' })
  );

  getGeoOptions = memoize(
    (geos = []) => generateSelectOptions(geos, { value: 'attributes.url' })
  );

  getBrandOptions = memoize(
    (brands = []) => generateSelectOptions(brands, { value: 'attributes.slug' })
  );

  getModelOptions = memoize(
    (models = []) => generateSelectOptions(models, { value: 'attributes.slug' })
  );

  getGenerationOptions = memoize(
    (generations = []) => generateSelectOptions(generations, { value: 'attributes.slug' })
  );

  render() {
    const {
      formData,
      brands,
      models,
      generations,
      geos,
      location,
      geo: defaultGeo,
    } = this.props;

    const FormWrapper = this.isFullFormOpened ? FormModal : Fragment;

    const filteredDefaultGeo = this.getDefaultGeoOption([defaultGeo])[0];
    const filteredGeos = this.getGeoOptions(geos);
    // Зависимые опции
    const filteredBrands = this.getBrandOptions(brands);
    const filteredModels = this.getModelOptions(models);
    const filteredGenerations = this.getGenerationOptions(generations);

    return (
      <FormWrapper>
        <Form
          formState={{ ...this.state }}
          formOptions={{
            filteredGeos,
            filteredDefaultGeo,
            filteredBrands,
            filteredModels,
            filteredGenerations,
            ...formData.formOptions,
          }}
          onChange={this.handleControlChange}
          navigateToNewUrl={this.navigateToNewUrl}
          toggleFullForm={this.toggleFullForm}
          allFiltersCount={this.allFiltersCount}
          fullFiltersCount={this.fullFiltersCount}
          resetAllForm={this.resetAllForm}
          location={location}
          ranges={this.ranges}
        />
      </FormWrapper>
    );
  }
}

OffersForm.propTypes = {
  formData: PropTypes.shape({
    formOptions: PropTypes.object,
    ranges: PropTypes.object,
  }),
  brands: PropTypes.array,
  models: PropTypes.array,
  generations: PropTypes.array,
  geos: PropTypes.array,
  isLoading: PropTypes.bool,
  match: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(withContent(OffersForm));
