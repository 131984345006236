import useRequest from 'core/hooks/useRequest';

import queryString from 'core/libs/query-string';

import { renderError } from 'core/utils/app-status-helper';
import { processQueryResults } from 'core/utils/query';
import { denormalizeData } from 'core/utils/api';
import { getOffset } from 'core/utils/url-helper';

import { servicesApi } from 'site/api/definitions/services';

import { buildSearchRequestParams, isMotoPath } from 'site/utils';

import { OFFERS_PAGE_LIMIT } from 'site/constants';


export default function useOffersContent(queryParams) {
  const {
    match: {
      params: {
        lcv,
        geo,
        brand: brandSlug,
        model: modelSlug,
        generation: generationSlug,
      },
    },
    location: {
      pathname,
      search,
    },
    history,
    isDesktop,
  } = queryParams;

  const isLcvPage = Boolean(lcv);
  const isMoto = isMotoPath(pathname);

  const vehicleType = isMoto ? 'Moto' : 'Car';
  const variationType = generationSlug && !isMoto && 'Generation' || modelSlug && 'Model' || brandSlug && 'Brand' || geo && 'Geo';

  const {
    condition,
    ...otherRequestParams
  } = buildSearchRequestParams(search);

  const [{
    data: offers = { data: [] },
    isLoading: offersAreLoading,
  }, {
    data: pageSeo = {},
    isLoading: pageSeoIsLoading,
  }] = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', isMoto ? 'getMotoOffers' : 'getOffers', {
          ...otherRequestParams,
          'filter[geo]': geo,
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          [condition === 'new' && 'filter[is_new]']: true,
          [condition === 'trade_in' && 'filter[is_trade_in]']: true,
          [`attributes[dealer_offer${isMoto ? '_moto' : ''}]`]: `base,image,${isDesktop ? 'text' : ''}`,
          [`attributes[${vehicleType.toLowerCase()}_brand]`]: ['base', 'image'],
          [`relations[dealer_offer${isMoto ? '_moto' : ''}]`]: 'brand,model,modification,dealer,dealer_network',
          'relations[dealer]': 'dealer_network',
          include: `${vehicleType.toLowerCase()}_brand,${vehicleType.toLowerCase()}_model,${vehicleType.toLowerCase()}_modification,dealer,dealer_network`,
          offset: getOffset(search, OFFERS_PAGE_LIMIT),
          limit: OFFERS_PAGE_LIMIT,
          sort: queryString.parse(search).sort || '-date',
          with_filtered_count: true,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .catch(err => renderError(err, history)),
      }, {
        queryKey: ['servicesApi', `get${vehicleType}Offers${variationType}Seo`, {
          'filter[geo]': geo,
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      },
    ],
  });

  const results = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', isMoto ? 'getMotoOffersGeos' : 'getOffersGeos', {
          'filter[brand]': brandSlug,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      }, {
        queryKey: ['servicesApi', isMoto ? 'getMotoBrands' : 'getBrands', {
          [`attributes[${vehicleType.toLowerCase()}_brand]`]: 'base',
          'filter[brand]': brandSlug,
          limit: 1,
          ...(isLcvPage && { 'filter[car_type]': 'lcv' }),
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: result => denormalizeData(result)?.[0],
      },
    ],
  });

  const {
    data: [
      geos = [],
      brands = [],
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  const { data: models = [] } = useRequest({
    queryKey: ['servicesApi', isMoto ? 'getMotoModels' : 'getModels', {
      'filter[brand]': brandSlug,
      'filter[with_offers_from_geo]': geo,
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    select: denormalizeData,
    enabled: !!brandSlug,
  });

  const { data: generationSeo = {} } = useRequest({
    queryKey: ['servicesApi', 'getGenerations', {
      'filter[brand]': brandSlug,
      'filter[model]': modelSlug,
      'filter[generation]': generationSlug,
      limit: 1,
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    select: result => denormalizeData(result)?.[0],
    enabled: !!generationSlug,
  });

  const enableSeo = !pageSeo?.text && !!modelSlug;

  const { data: offersSeo = {}, isLoading: seoIsLoading } = useRequest({
    queryKey: ['servicesApi', isMoto ? 'getMotoOffersBrandSeo' : 'getCarOffersBrandSeo', {
      'filter[geo]': geo,
      'filter[brand]': brandSlug,
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
      .then(denormalizeData)
      .then(result => ({
        ...pageSeo,
        text: result.text,
        brandSeoTitle: result.title,
      })),
    enabled: !pageSeoIsLoading && enableSeo,
  });

  return {
    offers,
    offersAreLoading,
    offersSeo: Object.keys(offersSeo).length ? offersSeo : pageSeo,
    generationSeo,
    geos,
    brands,
    models,
    isLoading: resultsAreLoading
      || (seoIsLoading && enableSeo),
  };
}

