import queryString from 'core/libs/query-string';

import { LocalStorage } from 'core/decorators';

import { MOTO_URL } from 'site/constants';

import { COMPARISON_STORAGE_NAMES } from './constants';


export function choiceOfTransportStorage(type) {
  return COMPARISON_STORAGE_NAMES[type];
}

export function updateStorageUuids(uuids, type) {
  LocalStorage.setItem(choiceOfTransportStorage(type), JSON.stringify(uuids));
}

export function getStorageUuids(type) {
  return JSON.parse(LocalStorage.getItem(choiceOfTransportStorage(type))) || [];
}

export function hasModificationInStorage(id, type) {
  const uuids = getStorageUuids(type);

  return uuids && uuids.some(localId => localId === id);
}

export function createCompareQuery(uuids) {
  if (!uuids?.length) return '';

  return queryString.stringify({ uuid: uuids });
}

export function createCompareLink(uuids, isMoto) {
  const queryParams = createCompareQuery(uuids);
  const formattedURL = queryParams ? `/compare?${queryParams}` : '/compare';
  return isMoto ? `${MOTO_URL}${formattedURL}` : formattedURL;
}

/**
 * Проверяет наличие опций в группе, по признаку is_different.
 * Используется в механизме "Все параметры/Только различающиеся"
 * @param items - массив опций или тех. характеристик
 * @returns {boolean}
 */
export function hasDifferences(items) {
  return items.some(({ is_different: isDifferent }) => isDifferent);
}
