import PropTypes from 'prop-types';

import { compose, setPropTypes } from 'core/libs/recompose';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

export default compose(
  withTheme,
  withBreakpoint,
  setPropTypes({
    breakpoint: breakpointPropTypes(),
    position: PropTypes.number,
  }),
);
