import { Fragment } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import {
  withNonPureBreakpoint,
} from 'core/components/breakpoint';

import {
  generateSelectOptions,
  initSelectValue,
  initRangeValue,
  initCheckboxValue,
  initRadioValue,
} from 'site/utils/forms';

import {
  filterEntitiesByRelation,
} from 'site/utils';

import BaseForm from 'site/components/BaseForm';
import FormModal from 'site/components/FormModal';

import withContent from './withContent';
import Form from './Form';

import { MOTO_URL } from 'site/constants';

import {
  RANGES,
  FULL_FILTER_FIELDS,
  PATHNAME_FIELDS,
  DEPENDENCIES,
} from './constants';


class MotoForm extends BaseForm {
  constructor(props) {
    super(props);
    this.state = this.getFormStateFromLocation(props.formOptions);
  }

  componentDidUpdate(prevProps) {
    const {
      formOptions,
      isLoading,
      location: {
        pathname,
        search,
      },
    } = this.props;

    const {
      formOptions: prevFormOptions,
      location: {
        pathname: prevPathname,
        search: prevSearch,
      },
    } = prevProps;

    if (!isLoading && (formOptions !== prevFormOptions || pathname !== prevPathname || search !== prevSearch)) {
      this.setState(this.getFormStateFromLocation(formOptions));
    }
  }

  dependencies = DEPENDENCIES;

  ranges = RANGES;

  pathnameFields = PATHNAME_FIELDS;

  fullFilterFields = FULL_FILTER_FIELDS;

  basePath = MOTO_URL;

  getFormStateFromLocation = (formOptions) => {
    const {
      match: {
        params,
      },
      location,
    } = this.props;

    /**
     * Если значение не найдено, то устанавливаем его в null вместо
     * undefined, потому что undefined при сбросе фильтров не воспринимается
     * для некоторых контролов и там остается предыдущее установленное
     * значение.
     */
    const filteredBrands = formOptions.filteredBrands;
    const brand = filteredBrands?.find(item => (
      item.attributes.slug === params.brand
    )) || null;

    const filteredModels = this.getModelOptions(formOptions.filteredModels, brand);
    const model = brand && filteredModels?.find(item => (
      item.attributes.slug === params.model
    )) || null;

    const formValuesFromUrl = queryString.parse(location.search);

    return {
      brand,
      model,
      price: initRangeValue(
        'price',
        formValuesFromUrl,
        this.ranges.price
      ),
      type_uuid: initSelectValue(
        'type_uuid',
        formValuesFromUrl,
        formOptions.filteredTypes,
      ),
      class_uuid: initSelectValue(
        'class_uuid',
        formValuesFromUrl,
        formOptions.filteredClasses,
      ),
      volume: initRangeValue(
        'volume',
        formValuesFromUrl,
        this.ranges.volume
      ),
      power: initRangeValue(
        'power',
        formValuesFromUrl,
        this.ranges.power
      ),
      engine_type: initRadioValue(
        'engine_type',
        formValuesFromUrl,
      ),
      configurate: initRadioValue(
        'configurate',
        formValuesFromUrl,
      ),
      transmission: initRadioValue(
        'transmission',
        formValuesFromUrl,
      ),
      transfer: initRadioValue(
        'transfer',
        formValuesFromUrl,
      ),
      cooling: initRadioValue(
        'cooling',
        formValuesFromUrl,
      ),
      boost: initCheckboxValue(
        'boost',
        formValuesFromUrl,
      ),
      cylinders: initRangeValue(
        'cylinders',
        formValuesFromUrl,
        this.ranges.cylinders
      ),
      abs: initCheckboxValue(
        'abs',
        formValuesFromUrl,
      ),
      usages: initCheckboxValue(
        'usages',
        formValuesFromUrl,
      ),
    };
  };

  getModelOptions = memoize(
    (models, brand) => generateSelectOptions(filterEntitiesByRelation('brand', models, [brand]))
  );

  render() {
    const {
      formOptions,
      isMobile,
      location,
      match: {
        params: {
          release,
        },
      },
    } = this.props;

    const {
      brand,
    } = this.state;

    // Зависимые опции
    const filteredModels = this.getModelOptions(formOptions.filteredModels, brand);

    const FormWrapper = this.isFullFormOpened ? FormModal : Fragment;

    return (
      <FormWrapper>
        <Form
          formState={this.state}
          formOptions={{
            ...formOptions,
            filteredModels,
          }}
          ranges={this.ranges}
          isMobile={isMobile}
          onChange={this.handleControlChange}
          onCarTypeChange={this.handleCarTypeChange}
          location={location}
          navigateToNewUrl={this.navigateToNewUrl}
          toggleFullForm={this.toggleFullForm}
          resetAllForm={this.resetAllForm}
          allFiltersCount={this.allFiltersCount}
          fullFiltersCount={this.fullFiltersCount}
          isFiltersDisabled={!!release && !this.isFullFormOpened}
        />
      </FormWrapper>
    );
  }
}

MotoForm.propTypes = {
  formOptions: PropTypes.object,
  isLoading: PropTypes.bool,

  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  location: PropTypes.object,

  /** @ignore */
  match: PropTypes.object,

  /** @ignore */
  history: PropTypes.object,
};

export default withNonPureBreakpoint(withRouter(withContent(MotoForm)));
