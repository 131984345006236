import PropTypes from 'prop-types';

import Page from 'core/components/Page';
import PageLoader from 'core/components/Loader/PageLoader';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { withBreakpoint, Mobile } from 'core/components/breakpoint';

import ReleasePromo from 'site/components/ReleasePromo';
import ReleaseBoroda from 'site/components/ReleaseBoroda';
import { Indent, NegativeMobile } from 'site/components/Wrappers';
import { Listing1 } from 'site/components/Ads/mobile';

import Modifications from './blocks/Modifications';
import Bodies from './blocks/Bodies';
import Generations from './blocks/Generations';

import Classmates from 'site/components/Classmates';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import useReleaseContent from './useReleaseContent';


function Release(props) {
  const {
    seo,
    release,
    bodies,
    modifications,
    isBrandTagExists,
    isLoading,
  } = useReleaseContent(props);

  const { isMobile } = props;

  const mainGenerationId = release?.relationships?.generation?.data?.id;
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Page title={seo?.title} description={seo?.description}>
      {isLoading && <PageLoader />}
      {!isLoading && (
        <AdWrapper bottom={vertical}>
          <NegativeMobile>
            <ReleasePromo release={release} isBrandTagExists={isBrandTagExists} />
          </NegativeMobile>
          <Mobile>
            <Indent bottom={vertical} />
          </Mobile>
          <Modifications modifications={modifications} />
          <Indent bottom={vertical} />
          <Bodies bodies={bodies} withShadowBg={isMobile} />
          <Indent bottom={vertical} />
          <Mobile>
            <Listing1 />
          </Mobile>
          <Generations mainGenerationId={mainGenerationId} />
          <Indent bottom={vertical} />
          <Classmates mainReleaseId={release?.id} withShadowBg={isMobile} />
          <ReleaseBoroda release={release} />
        </AdWrapper>
      )}
    </Page>
  );
}

Release.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Release);
