import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { renderError } from 'core/utils/app-status-helper';
import { denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';
import resolveRelationships from 'core/utils/relationships';

import Page from 'core/components/Page';
import PageLoader from 'core/components/Loader/PageLoader';
import H2 from 'core/components/H2';
import H3 from 'core/components/H3';
import Feed from 'core/components/Feed';
import { withBreakpoint } from 'core/components/breakpoint';

import ReleaseBoroda from 'site/components/ReleaseBoroda';
import { Indent, ReleaseInnerPages } from 'site/components/Wrappers';

import { servicesApi } from 'site/api/definitions/services';

import { brandTagExistsQuery } from 'site/queries/brandTagExists';

import CardReleaseColor from 'site/cardsService/CardReleaseColor';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


const relationships = resolveRelationships(['colors'], {}, { colors: [] });

function ReleaseColors(props) {
  const {
    match: {
      params: {
        brand: brandSlug,
        model: modelSlug,
        generation: generationSlug,
        body: bodySlug,
      },
    },
    isMobile,
  } = props;

  const results = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', 'getReleases', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'filter[body]': bodySlug,
          include: 'car_brand,car_release_color,car_feature',
          limit: 1,
          'relations[car_release]': 'brand,colors,features',
          'attributes[car_release]': 'base,media,seo,image,extended',
          'attributes[car_release_color]': 'base,image',
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .then(result => denormalizeData(result)[0])
          .then(rejectEmptyResult)
          .catch(err => renderError(err, history)),
      }, {
        queryKey: ['servicesApi', 'getReleasesSeo', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'filter[submodel]': bodySlug,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      },
      brandTagExistsQuery(props),
    ],
  });

  const {
    data: [
      release = {},
      seo = {},
      isBrandTagExists,
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  if (resultsAreLoading) return <PageLoader />;

  const { colors } = relationships(release);

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const colorsByGroupName = colors.reduce((groups, color) => {
    const { color_group_name: groupName } = color.attributes;

    if (groups[groupName]) {
      groups[groupName].push(color);
    } else {
      groups[groupName] = [color];
    }

    return groups;
  }, {});

  return (
    <Page title={seo?.title} description={seo?.description}>
      <ReleaseInnerPages release={release} isBrandTagExists={isBrandTagExists}>
        <H2 as='h1'>{`Цвета ${release?.attributes?.name}`}</H2>
        <Indent bottom={vertical} />
        {Object.keys(colorsByGroupName).map(groupName => {
          const content = colorsByGroupName[groupName];
          const title = content.length > 1
            ? `${groupName} (${content.length})`
            : groupName;

          return (
            <Indent bottom={vertical} key={groupName}>
              <H3>{title}</H3>
              <Indent bottom={vertical} />
              <Feed
                grid
                content={colorsByGroupName[groupName]}
                interitemSpacing={isMobile ? 0 : 15}
                columns={3}
                card={CardReleaseColor}
              />
            </Indent>
          );
        })}
        <ReleaseBoroda release={release} />
      </ReleaseInnerPages>
    </Page>
  );
}

ReleaseColors.propTypes = {
  isMobile: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
      body: PropTypes.string,
    }),
  }).isRequired,
};

export default withBreakpoint(ReleaseColors);
