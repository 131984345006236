import { useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { get } from 'core/libs/lodash';
import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';

import Battery from 'site/components/Battery';
import Divider from 'site/components/Divider';
import { Indent } from 'site/components/Wrappers';
import ButtonCompare from 'site/components/ButtonCompare';

import { ConfiguratorContext } from 'site/components/ConfiguratorProvider';

import Prices from '../Prices';
import Abilities from '../Abilities';

import styles from './index.styl';
import InstalledOptions from '../InstalledOptions';

function ViewOnMobile(props) {
  const [opened, toggleOpened] = useState(false);
  const handleOpened = useCallback(() => {
    toggleOpened(!opened);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    theme: {
      colors: {
        content,
        primary100,
      },
    },
    modification,
    modification: {
      id: modificationId,
      type,
    },
  } = props;

  const {
    battery: baseBattery,
    price: basePrice,
  } = modification?.attributes || {};

  const configurations = useContext(ConfiguratorContext);

  const battery = get(
    configurations,
    [modificationId, 'configuration', 'battery'],
    baseBattery
  );
  const price = get(configurations, [modificationId, 'configuration', 'price'], 0);

  const totalPrice = price || basePrice;

  if (!totalPrice) return null;

  return (
    <div
      className={cx(
        styles.viewOnMobile,
        opened && styles.opened
      )}
    >
      <style jsx>{`
        .${styles.viewOnMobile}
          background ${content}

          &:before
            background ${primary100}
      `}</style>
      <div className={styles.header} onClick={handleOpened}>
        <Prices
          modificationId={modificationId}
          basePrice={basePrice}
          hideArrow
        />
        <Battery type={3} value={battery.total} />
      </div>
      <div className={styles.body}>
        <Divider />
        <Indent top={16} bottom={11}>
          <Abilities battery={battery} />
        </Indent>
        <Divider />
        <Indent top={16} bottom={11}>
          <ButtonCompare
            modelId={modificationId}
            transportType={type}
            fullWidth
          />
        </Indent>
        <Indent top={16} />
        <InstalledOptions modificationId={modificationId} />
      </div>
    </div>
  );
}

ViewOnMobile.propTypes = {
  modification: PropTypes.object,
  theme: themePropTypes(`{
    colors: {
      primary100,
    }
  }`),
};

export default withTheme(ViewOnMobile);
