import { Fragment } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import { compose } from 'core/libs/recompose';
import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import {
  withNonPureBreakpoint,
} from 'core/components/breakpoint';

import {
  generateSelectOptions,
  initSelectValue,
  initRangeValue,
  initCheckboxValue,
  initRadioValue,
} from 'site/utils/forms';

import BaseForm from 'site/components/BaseForm';
import FormModal from 'site/components/FormModal';

import withContent from './withContent';
import Form from './Form';

import {
  RANGES,
  FULL_FILTER_FIELDS,
  PATHNAME_FIELDS,
  DEPENDENCIES,
  BASE_PATH,
} from './constants';


class MotoOffersForm extends BaseForm {
  constructor(props) {
    super(props);

    this.state = this.getFormStateFromLocation(props.formData.formOptions, props.models, props.geos);
  }

  dependencies = DEPENDENCIES;

  ranges = RANGES;

  pathnameFields = PATHNAME_FIELDS;

  fullFilterFields = FULL_FILTER_FIELDS;

  basePath = BASE_PATH;

  get emptyPath() {
    return BASE_PATH + '/' + this.props.geoSlug;
  }

  componentDidUpdate(prevProps) {
    const {
      formData,
      models,
      geos,
      isLoading,
      location: {
        pathname,
        search,
      },
    } = this.props;

    const {
      formData: prevFormData,
      models: prevModels,
      geos: prevGeos,
      location: {
        pathname: prevPathname,
        search: prevSearch,
      },
    } = prevProps;

    if (!isLoading && formData !== prevFormData) {
      this.ranges = formData.ranges;
      this.setState(this.getFormStateFromLocation(formData.formOptions, models, geos));
    }

    if (!isLoading
      && (models !== prevModels || geos !== prevGeos || pathname !== prevPathname || search !== prevSearch)) {
      this.setState(this.getFormStateFromLocation(formData.formOptions, models, geos));
    }
  }

  getFormStateFromLocation = (formOptions, models = [], geos = []) => {
    const {
      match: {
        params,
      },
      location,
      geo: defaultGeo,
    } = this.props;

    /**
     * Если значение не найдено, то устанавливаем его в null вместо
     * undefined, потому что undefined при сбросе фильтров не воспринимается
     * для некоторых контролов и там остается предыдущее установленное
     * значение.
     */
    const filteredBrands = formOptions?.filteredBrands;
    const brand = filteredBrands?.find(item => (
      item.attributes.slug === params.brand
    )) || null;

    const filteredModels = this.getModelOptions(models, brand);
    const model = brand && filteredModels?.find(item => (
      item.attributes.slug === params.model
    )) || null;

    const formValuesFromUrl = queryString.parse(location.search);

    const filteredGeos = this.getGeoOptions(geos);
    const filteredDefaultGeo = this.getDefaultGeoOption([defaultGeo])[0];

    const geo = filteredGeos?.find(item => (
      item.attributes.url === params.geo
    )) || filteredDefaultGeo;

    return {
      brand,
      model,
      geo,
      price: initRangeValue(
        'price',
        formValuesFromUrl,
        this.ranges.price
      ),
      type: initSelectValue(
        'type',
        formValuesFromUrl,
        formOptions.filteredTypes,
      ),
      class: initSelectValue(
        'class',
        formValuesFromUrl,
        formOptions.filteredClasses,
      ),
      volume: initRangeValue(
        'volume',
        formValuesFromUrl,
        this.ranges.volume
      ),
      power: initRangeValue(
        'power',
        formValuesFromUrl,
        this.ranges.power
      ),
      configurate: initRadioValue(
        'configurate',
        formValuesFromUrl,
      ),
      transmission: initRadioValue(
        'transmission',
        formValuesFromUrl,
      ),
      transfer: initRadioValue(
        'transfer',
        formValuesFromUrl,
      ),
      cooling: initRadioValue(
        'cooling',
        formValuesFromUrl,
      ),
      cylinders: initRangeValue(
        'cylinders',
        formValuesFromUrl,
        this.ranges.cylinders
      ),
      abs: initCheckboxValue(
        'abs',
        formValuesFromUrl,
      ),
      condition: initSelectValue(
        'condition',
        formValuesFromUrl,
        formOptions.filteredConditions,
      ),
      control: initRadioValue(
        'control',
        formValuesFromUrl
      ),
      year: initRangeValue(
        'year',
        formValuesFromUrl,
        this.ranges.year
      ),
    };
  };

  getDefaultGeoOption = memoize(
    (geos = []) => generateSelectOptions(geos, { value: 'attributes.url' })
  );

  getGeoOptions = memoize(
    (geos = []) => generateSelectOptions(geos, { value: 'attributes.url' })
  );

  getModelOptions = memoize(
    (models = []) => generateSelectOptions(models, { value: 'attributes.slug' })
  );

  render() {
    const {
      formData,
      models,
      geos,
      isMobile,
      location,
      match: {
        params: {
          release,
        },
      },
      geo: defaultGeo,
    } = this.props;

    const { brand } = this.state;

    const filteredDefaultGeo = this.getDefaultGeoOption([defaultGeo])[0];
    const filteredGeos = this.getGeoOptions(geos);
    const filteredModels = this.getModelOptions(models, brand);

    const FormWrapper = this.isFullFormOpened ? FormModal : Fragment;

    return (
      <FormWrapper>
        <Form
          formState={{ ...this.state }}
          formOptions={{
            filteredGeos,
            filteredDefaultGeo,
            filteredModels,
            ...formData.formOptions,
          }}
          isMobile={isMobile}
          onChange={this.handleControlChange}
          location={location}
          navigateToNewUrl={this.navigateToNewUrl}
          toggleFullForm={this.toggleFullForm}
          resetAllForm={this.resetAllForm}
          allFiltersCount={this.allFiltersCount}
          fullFiltersCount={this.fullFiltersCount}
          isFiltersDisabled={!!release && !this.isFullFormOpened}
          ranges={this.ranges}
        />
      </FormWrapper>
    );
  }
}

MotoOffersForm.propTypes = {
  formData: PropTypes.shape({
    formOptions: PropTypes.object,
    ranges: PropTypes.object,
  }),
  models: PropTypes.array,
  geos: PropTypes.array,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
};

export default compose(
  withRouter,
  withNonPureBreakpoint,
  withContent,
)(MotoOffersForm);
