import PropTypes from 'prop-types';
import { get } from 'core/libs/lodash';

import SmartImage from 'core/components/SmartImage';
import { withBreakpoint } from 'core/components/breakpoint';

import GalleryWithAds from 'site/components/GalleryWithAds';
import { NegativeMobile } from 'site/components/Wrappers';

import getThumbnail from 'site/utils/get-thumbnail';

import {
  CONTENT_AREA,
  LAYOUT_MAX_WIDTH_MOBILE,
} from 'site/constants';

import styles from './index.styl';


function Cover({ offer, isMobile }) {
  const {
    attributes: {
      photogallery: offerGallery,
      status,
    },
  } = offer;

  const offerImage = getThumbnail(offerGallery.attributes.images[0]);

  const galleryImageCount = get(offerGallery, 'attributes.image_count', 0);
  const showGallery = status !== 'sold' && galleryImageCount > 1;

  if (showGallery) {
    return (
      <NegativeMobile>
        <GalleryWithAds
          key={offerGallery.id}
          photoGallery={offerGallery}
          firstImageFetchPriority='high'
          hashNavigation
        />
      </NegativeMobile>
    );
  }

  const imageWidth = isMobile ? LAYOUT_MAX_WIDTH_MOBILE : CONTENT_AREA;
  const showSoldImage = !!(status === 'sold' && offerImage);

  const Image = (
    <SmartImage
      maxWidth={imageWidth}
      src={offerImage}
    />
  );

  if (showSoldImage) {
    return (
      <div className={styles.imageWrapper}>
        <div className={styles.imageOverlay} />
        {Image}
        <div className={styles.plateWrapper}>
          <div className={styles.pin} />
          <span className={styles.plateText}>продано</span>
        </div>
      </div>
    );
  }

  return Image;
}

Cover.propTypes = {
  offer: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Cover);
