import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import {
  authorQuery,
  topicsQuery,
} from 'core/queries/authorPage';

import { filterRequiredParams } from 'core/utils/api';
import breakpointPropTypes from 'core/utils/prop-types/breakpoint';

import AuthorPage from 'core/components/AuthorPage';
import PageLoader from 'core/components/Loader/PageLoader';
import replaceCardWithAd from 'core/components/Ad/hoc/replaceCardWithAd';
import { withBreakpoint } from 'core/components/breakpoint';
import { DESKTOP, MOBILE } from 'core/components/breakpoint/values';

import PageLayout from 'site/components/PageLayout';
import { RUBRICS_PAGE_LIMIT } from 'site/constants';

import {
  Native1asCard3,
  Native2asCard3,
} from 'site/components/Ads/desktop';

import Card1, { Card1Type4 } from 'site/cards/Card1';
import Card3 from 'site/cards/Card3';


const breakpointCards = {
  [DESKTOP]: Card3,
  [MOBILE]: Card1Type4,
};

function Author(props) {
  const { breakpoint, isMobile } = props;

  const results = useRequest({ queries: [
    authorQuery(props),
    topicsQuery({
      ...props,
      limit: RUBRICS_PAGE_LIMIT,
      include: 'image',
      fields: filterRequiredParams([Card1, Card3], 'fields'),
    }),
  ] });
  const [
    { data: author, isLoading: authorIsLoading },
    { data: topics, isLoading: topicsAreLoading },
  ] = results;

  return (
    <PageLayout>
      {authorIsLoading && <PageLoader />}
      {!authorIsLoading && (
        <AuthorPage
          author={author}
          topics={topics}
          limit={RUBRICS_PAGE_LIMIT}
          card={breakpointCards[breakpoint]}
          cardsReplacement={{
            2: replaceCardWithAd(Native1asCard3, !isMobile),
            6: replaceCardWithAd(Native2asCard3, !isMobile),
          }}
          isLoading={topicsAreLoading}
        />
      )}
    </PageLayout>
  );
}

Author.propTypes = {
  breakpoint: breakpointPropTypes(),
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Author);
