import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import { withRouter } from 'core/libs/router';

import H3 from 'core/components/H3';

import { Indent } from 'site/components/Wrappers';
import BaseForm from 'site/components/BaseForm';

import {
  generateSelectOptions,
  initSelectValue,
  initRangeValue,
  resolveDependenciesFields,
} from 'site/utils/forms';

import withContent from './withContent';
import Form from './Form';

import {
  RANGES,
  DEPENDENCIES,
} from './constants';

import styles from './index.styl';


class OffersNavigator extends BaseForm {
  constructor(props) {
    super(props);
    this.state = this.getFormStateFromLocation(props.formData.formOptions);

    this.getLocationFromState = () => {};
  }

  dependencies = DEPENDENCIES;

  ranges = RANGES;

  componentDidUpdate(prevProps) {
    const { formData } = this.props;

    if (formData !== prevProps.formData) {
      this.ranges = formData.ranges;
      this.setState(this.getFormStateFromLocation(formData.formOptions));
    }
  }

  handleBrandChange = (value, context) => {
    this.props.handleBrandChange(value);
    this.setState(resolveDependenciesFields(context.name, this.dependencies), this.handleFormChange);
  };

  getFormStateFromLocation = (formOptions) => ({
    price: initRangeValue(
      'price',
      {},
      this.ranges.price
    ),
    body: initSelectValue(
      'body',
      {},
      formOptions.filteredBodies,
      'attributes.slug',
    ),
    year: initRangeValue(
      'year',
      {},
      this.ranges.year
    ),
    condition: initSelectValue(
      'condition',
      {},
      formOptions.filteredConditions,
    ),
  });

  getBrandOptions = memoize(
    (brands = []) => generateSelectOptions(brands, { value: 'attributes.slug' })
  );

  getModelOptions = memoize(
    (models = []) => generateSelectOptions(models, { value: 'attributes.slug' })
  );

  render() {
    const {
      brand,
      brands,
      models,
      formData,
    } = this.props;

    // Зависимые опции
    const filteredBrands = this.getBrandOptions(brands);
    const filteredModels = this.getModelOptions(models);

    return (
      <div className={styles.formWrapper} data-qa='offers-search-form'>
        <Indent bottom={25}>
          <H3 is='h2' dataQa='offers-search-header'>Поиск по объявлениям</H3>
        </Indent>
        <Form
          brand={brand}
          models={models}
          formState={this.state}
          formOptions={{
            filteredBrands,
            filteredModels,
            ...formData.formOptions,
          }}
          onChange={this.handleControlChange}
          onBrandChange={this.handleBrandChange}
          ranges={this.ranges}
        />
      </div>
    );
  }
}

OffersNavigator.propTypes = {
  brands: PropTypes.array,
  models: PropTypes.array,
  formData: PropTypes.shape({
    formOptions: PropTypes.object,
    ranges: PropTypes.object,
  }),
  brand: PropTypes.object,
  handleBrandChange: PropTypes.func,
};

export default withRouter(withContent(OffersNavigator));
