import { Fragment } from 'react';
import PropTypes from 'prop-types';

import ListPage from 'core/components/ListPage';
import Feed from 'core/components/Feed';
import PageLoader from 'core/components/Loader/PageLoader';
import { withBreakpoint } from 'core/components/breakpoint';

import TitleWithReleasesCount from 'site/components/TitleWithReleasesCount';
import CardModification1WithPrice from 'site/cardsService/CardServiceModification1/cards/CardModification1WithPrice';
import CardModification2WithPrice from 'site/cardsService/CardServiceModification2/cards/CardModification2WithPrice';

import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE, RELEASES_LIMIT } from 'site/constants';

import { Indent } from 'site/components/Wrappers';


function Releases({ releasesDataAndMeta, isLoading, isMobile, isMoto }) {
  if (!isLoading && !releasesDataAndMeta?.releases?.length) return null;

  const { filtered_count: filteredCount = 0 } = releasesDataAndMeta?.meta || {};

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Fragment>
      <TitleWithReleasesCount releasesCount={filteredCount} isMoto={isMoto} />
      <ListPage
        content={releasesDataAndMeta.releases}
        meta={releasesDataAndMeta.meta}
        limit={RELEASES_LIMIT}
        isLoading={isLoading}
        compactPaging
      >
        {({ content }) => {
          if (isLoading) return <PageLoader />;

          return (
            <Indent bottom={vertical}>
              <Feed
                content={content}
                card={isMobile ? CardModification2WithPrice : CardModification1WithPrice}
              />
            </Indent>
          );
        }}
      </ListPage>
    </Fragment>
  );
}

Releases.propTypes = {
  isLoading: PropTypes.bool,
  isMoto: PropTypes.bool,
  isMobile: PropTypes.bool,
  releasesDataAndMeta: PropTypes.shape({
    releases: PropTypes.arrayOf(modelPropTypes(carReleaseAttributes)),
    meta: PropTypes.shape({
      filtered_count: PropTypes.number,
    }),
  }),
};

export default withBreakpoint(Releases);
