import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { processQueryResults } from 'core/utils/query';
import { denormalizeData } from 'core/utils/api';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import { Desktop, Mobile } from 'core/components/breakpoint';

import AdWrapper from 'core/components/Ad/AdWrapper';
import Page from 'core/components/Page';
import PageLoader from 'core/components/Loader/PageLoader';
import H3 from 'core/components/H3';

import { servicesApi } from 'site/api/definitions/services';

import { SuperFooter } from 'site/components/Ads/desktop';
import { Listing2 } from 'site/components/Ads/mobile';

import Releases from 'site/components/Releases';
import Brands from 'site/components/Brands';
import { Indent } from 'site/components/Wrappers';
import PopularModels from 'site/components/PopularModels';

import { brandsQuery } from 'site/queries/brands';
import { releasesQuery } from 'site/queries/releases';

import DescriptionOfMoto from './DescriptionOfMoto';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';


const title = 'Мотоциклы, квадроциклы, скутеры - онлайн каталог мотоциклов, квадроциклов и скутеров с ценами официальных дилеров.';
const description = 'Подбор мотоциклов, квадроциклов и скутеров. Удобное сравнение, данные по комплектациям и модификациям. Цены дилеров и объявления о продаже.';

function MotoPage(props) {
  const [{
    data: releasesDataAndMeta = {},
    isLoading: releasesDataAndMetaAreLoading,
  }, ...otherResults] = useRequest({
    queries: [
      releasesQuery(props),
      brandsQuery(props), {
        queryKey: ['servicesApi', 'getMotoReleases', {
          is_popular: true,
          'attributes[moto_release]': ['base', 'image'],
          limit: 4,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      },
    ],
  });

  const {
    data: [
      brands = [],
      models = [],
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(otherResults);

  const { isMobile, location } = props;
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  if (location.search) {
    return (
      <Page title={title} description={description}>
        <Releases
          releasesDataAndMeta={releasesDataAndMeta}
          isLoading={releasesDataAndMetaAreLoading}
          isMoto
        />
        <Indent top={vertical} />
      </Page>
    );
  }

  return (
    <Page title={title} description={description}>
      {resultsAreLoading && <PageLoader />}
      {!resultsAreLoading && (
        <AdWrapper bottom={vertical}>
          <Desktop>
            <PopularModels models={models} />
            <Indent bottom={vertical} />
          </Desktop>
          <Mobile>
            <Indent bottom={vertical} />
          </Mobile>
          <H3 dataQa='page-title'>Каталог новой мототехники</H3>
          <Indent bottom={vertical} />
          <Brands brands={brands} isMoto />
          <Desktop>
            <SuperFooter />
          </Desktop>
          <Mobile>
            <Indent bottom={40} />
            <PopularModels models={models} />
          </Mobile>
          <Indent top={vertical} />
          <DescriptionOfMoto />
          <Indent top={vertical} />
          <Mobile>
            <Listing2 />
          </Mobile>
        </AdWrapper>
      )}
    </Page>
  );
}

MotoPage.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default withBreakpoint(MotoPage);
