import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { denormalizeData } from 'core/utils/api';

import PageLoader from 'core/components/Loader/PageLoader';
import H3 from 'core/components/H3';
import { withNonPureBreakpoint } from 'core/components/breakpoint';

import Drum from 'core/components/Drum';

import { servicesApi } from 'site/api/definitions/services';

import { Indent } from 'site/components/Wrappers';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import CardServiceRelease2 from 'site/cardsService/CardServiceRelease2';

import styles from './index.styl';


function Releases({ isMobile, tabRequestParams }) {
  const {
    data: releases,
    isLoading: releasesAreLoading,
    isError,
  } = useRequest({
    queryKey: ['servicesApi', 'getReleases', tabRequestParams],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    select: denormalizeData,
    enabled: process.env.BROWSER_RUNTIME,
  });

  if (releasesAreLoading) return <PageLoader />;
  if (isError) {
    return (
      <div className={styles.errorMessage}>
        <H3>Что-то пошло не так</H3>
      </div>
    );
  }

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const horizontal = isMobile ? 15 : 0;

  const justOneRelease = releases.length === 1;

  if (justOneRelease) {
    return (
      <Indent
        top={vertical}
        bottom={vertical}
        left={horizontal}
        right={horizontal}
      >
        <CardServiceRelease2 content={releases[0]} />
      </Indent>
    );
  }

  return (
    <Indent
      top={vertical}
      bottom={vertical}
      left={horizontal}
      right={horizontal}
    >
      <Drum
        grid
        itemWidth='100%'
        itemWidthMobile='100%'
        spaceBetween={20}
        content={releases}
        card={CardServiceRelease2}
      />
    </Indent>
  );
}

Releases.propTypes = {
  tabRequestParams: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withNonPureBreakpoint(Releases);
