import { useContext } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'core/libs/recompose';
import { withRouter } from 'core/libs/router';

import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import Scroller from 'core/components/Scroller';
import EmptyWrapper from 'core/components/EmptyWrapper';

import { GeoContext } from 'site/components/GeoContext';

import { getTypeOfPage } from 'site/utils';

import {
  SIDE_INDENT,
  SIDE_INDENT_MOBILE,
  GEO_PLACEHOLDER,
  secondaryMenuMap,
} from 'site/constants';

import MenuItem from './MenuItem';

import styles from './index.styl';


function SecondaryMenu(props) {
  const {
    section,
    isMobile,
    location,
    theme: {
      controls: {
        secondaryMenu: secondaryMenuAtoms,
      },
    },
  } = props;

  const { geoSlug, defaultGeo } = useContext(GeoContext);

  const type = getTypeOfPage(location.pathname);
  const menu = secondaryMenuMap[type];
  const sideIndent = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;
  const Menu = isMobile ? Scroller : EmptyWrapper;

  return (
    <nav
      className={styles.secondaryMenu}
      data-qa='secondary-menu'
    >
      <style jsx>{`
        .${styles.secondaryMenu}
          background ${secondaryMenuAtoms.const.background}

        .${styles.itemsList}
          padding 0 ${sideIndent}px
      `}</style>
      <Menu>
        <div className={styles.itemsList}>
          {menu.map((item) => {
            const isGeoLink = item.link.indexOf(GEO_PLACEHOLDER) > -1;
            const slug = geoSlug ? geoSlug : defaultGeo.attributes.url;
            const link = isGeoLink ? item.link.replace(GEO_PLACEHOLDER, slug) : item.link;
            const isActive = section === item.section;

            return (
              <MenuItem
                key={link}
                link={link}
                isActive={isActive}
              >
                {item.content}
              </MenuItem>
            );
          })}
        </div>
      </Menu>
    </nav>
  );
}

SecondaryMenu.propTypes = {
  /** Имя раздела в меню, который будет подсвечиваться как активный */
  section: PropTypes.string,

  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string.isRequired,
  }),

  /** @ignore */
  theme: PropTypes.object,
};

const SecondaryMenuWithHOCs = compose(
  withRouter,
  withTheme,
  withBreakpoint,
)(SecondaryMenu);

SecondaryMenuWithHOCs.displayName = 'SecondaryMenu';

export default SecondaryMenuWithHOCs;
export { SecondaryMenu as StorybookComponent };
