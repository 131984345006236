import { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  rawRubricQuery,
  rawTopicsQuery,
} from 'core/queries/rubric';

import useRequest from 'core/hooks/useRequest';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import Rubric from 'core/components/Rubric';

import RubricSelector from 'site/components/RubricSelector';
import SelectionList from 'site/components/SelectionList';
import PageLayout from 'site/components/PageLayout';

import { Indent } from 'site/components/Wrappers';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE, RUBRICS_PAGE_LIMIT } from 'site/constants';


function RubricPage(props) {
  const {
    location,
    match,
    history,
    isMobile,
  } = props;

  const [
    { data: rawRubric, isLoading: rubricIsLoading },
    { data: rawTopics, isLoading: topicsAreLoading },
  ] = useRequest({ queries:
    [
      rawRubricQuery({ match, history }),
      rawTopicsQuery({
        location,
        match,
        history,
        limit: RUBRICS_PAGE_LIMIT,
        include: SelectionList.requiredPayloadImports,
        fields: SelectionList.requiredPayloadFields,
      }),
    ],
  });

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <PageLayout>
      <Rubric
        rawRubric={rawRubric}
        rawTopics={rawTopics}
        limit={RUBRICS_PAGE_LIMIT}
        headerSpacing={0}
        isLoading={topicsAreLoading || rubricIsLoading}
      >
        {({ topics = [] }) => (
          <Fragment>
            <Indent bottom={isMobile ? VERTICAL_INDENT_MOBILE : 24} />
            <RubricSelector />
            <Indent bottom={vertical} />
            <SelectionList topics={topics} isLoading={topicsAreLoading} />
          </Fragment>
        )}
      </Rubric>
    </PageLayout>
  );
}

RubricPage.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(RubricPage);
