import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withBreakpoint } from 'core/components/breakpoint';
import Scroller from 'core/components/Scroller';
import EmptyWrapper from 'core/components/EmptyWrapper';
import themePropTypes from 'core/utils/prop-types/theme';
import withTheme from 'core/components/theme';

import Divider from 'site/components/Divider';

import { isGetAnswer } from 'site/utils/pdd';
import { pddModePropTypes, pddAnswersPropTypes } from 'site/utils/prop-types/pdd';

import styles from './index.styl';


function QuestionsNavigation(props) {
  const {
    isMobile,
    active,
    mode,
    finish,
    theme,
    answers,
  } = props;

  const ITEM_WIDTH = 35;
  const ITEM_SPACING = 8;

  const isMarafon = mode === 'marafon';

  const isSlider = isMobile || isMarafon;

  const Wrapper = isSlider ? Scroller : EmptyWrapper;

  return (
    <div className={styles.container}>
      <style jsx>{`
        .${styles.container}
          border-bottom 1px solid ${theme.colors.divider}
          :global(body.mobile) &
            border-top 1px solid ${theme.colors.divider}
        .${styles.question}
          width ${ITEM_WIDTH}px
          margin-right ${ITEM_SPACING}px
      `}</style>
      <Wrapper {...isSlider && { scrollLeft: (active - 5) * (ITEM_WIDTH + ITEM_SPACING) }}>
        {answers.map((item, index) => {
          const num = index + 1;

          const isNotExam = mode !== 'exam';

          const isActive = num === active;

          const isCorrect = item && item.isCorrect;

          const noAnswer = !isGetAnswer(item);

          const correct = isCorrect && (isNotExam || finish);

          const error = !isCorrect && !noAnswer && (finish || isNotExam);

          const previousOpacity = !isNotExam && !noAnswer && !finish;

          const showDivider = !isSlider && answers.length > 20 && num === 20;

          return (
            <Fragment key={index}>
              <div
                className={cx(
                  styles.question,
                  isActive && !finish && styles.active,
                  previousOpacity && styles.previous,
                  correct && styles.success,
                  error && styles.error,
                )}
              >
                {num}
              </div>
              {showDivider && <Divider />}
            </Fragment>
          );
        })}
      </Wrapper>
    </div>
  );
}

QuestionsNavigation.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  theme: themePropTypes(`{
    colors: {
      divider,
    }
  }`),

  /** Массив с ответами от пользователя */
  answers: pddAnswersPropTypes,

  /** Активный вопрос Пдд */
  active: PropTypes.number,

  /** Режим: экзамен, тренировка, марафон */
  mode: pddModePropTypes,

  /** Окончание экзамена/тренировки */
  finish: PropTypes.bool,
};

const QuestionsNavigationWithHOCs = withBreakpoint(withTheme(QuestionsNavigation));
QuestionsNavigationWithHOCs.displayName = 'QuestionsNavigation';

export default QuestionsNavigationWithHOCs;
export { QuestionsNavigation as StorybookComponent };
