import { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import OptionModal from 'site/components/OptionModal';

import { ConfiguratorContext } from 'site/components/ConfiguratorProvider';

import {
  getDependencies,
  getDiffSummary,
  getExcludeSummary,
  getRadioDependencies,
} from 'site/utils/configurator';

import Dependencies from './Dependencies';

export default function OptionDependencies(props) {
  const {
    option,
    toggleModal,
    isOpen,
    handleBackward,
    modificationId,
  } = props;

  const {
    getConfiguration,
    ...configurations
  } = useContext(ConfiguratorContext);

  const {
    options,
    packages,
    configuration: {
      price: configurationPrice,
      installed_options: installedOptions,
    } = {},
  } = configurations[modificationId] || {};

  const radioDependencies = getRadioDependencies(option.dependencies);
  const requireDependencies = getDependencies(option.dependencies, 'require');

  const [diffPrice, setDiffPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [radioInstalled, updateRadioInstalled] = useState([]);

  useEffect(() => {
    if (!installedOptions) return;

    const excludeSummary = getExcludeSummary(option, options, packages, installedOptions);
    const diff = getDiffSummary(option, options, packages, installedOptions);
    // Устанавливаем разницу в цене
    setDiffPrice(diff + excludeSummary);
    // Устанавливаем общую стоимость учитывая все зависимости
    setTotalPrice(configurationPrice + diff + excludeSummary);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installedOptions]);

  /**
   * Находим radio зависимости включенные в опцию среди установленных опций
   * или берем опцию по умолчанию с типом consist.
   */
  useEffect(() => {
    if (!(installedOptions && radioDependencies)) return;
    const radios = radioDependencies.filter(({ child, type }) => (
      !!~installedOptions.findIndex(({ id }) => child === id) || type === 'consist'
    ));
    updateRadioInstalled(radios);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [installedOptions]);

  const checked = installedOptions && installedOptions.find(({ id: localId }) => option.id === localId);

  const updateConfiguration = useCallback(() => {
    getConfiguration(modificationId, {
      option_id: option.id,
      is_install_option: !checked,
      options_child: radioInstalled.map(({ child }) => child),
      package_dependence_id: requireDependencies.map(({ child }) => child),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OptionModal
      isOpen={isOpen}
      toggleModal={toggleModal}
      handleClose={toggleModal}
      {...handleBackward && {
        handleBackward: () => {
          toggleModal();
          handleBackward(false);
        },
      }}
      handleAccept={() => {
        updateConfiguration();
        toggleModal();
      }}
      acceptText='Применить'
      diffPrice={diffPrice}
      totalPrice={totalPrice}
    >
      <Dependencies
        modificationId={modificationId}
        option={option}
      />
    </OptionModal>
  );
}

OptionDependencies.propTypes = {
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
  option: PropTypes.object,
  handleBackward: PropTypes.func,
  modificationId: PropTypes.string,
};
