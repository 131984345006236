import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { denormalizeData } from 'core/utils/api';

import { withBreakpoint } from 'core/components/breakpoint';
import H3 from 'core/components/H3';
import Drum from 'core/components/Drum';
import PageLoader from 'core/components/Loader/PageLoader';

import { servicesApi } from 'site/api/definitions/services';

import { Indent } from 'site/components/Wrappers';
import Divider from 'site/components/Divider';

import CardServiceReview1 from 'site/cardsService/CardServiceReview1';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


function Reviews(props) {
  const {
    isMobile,
    match: {
      params: {
        brand: brandSlug,
        model: modelSlug,
        generation: generationSlug,
      },
    },
  } = props;

  const {
    data: reviews,
    isLoading: reviewsAreLoading,
  } = useRequest({
    queryKey: ['servicesApi', 'getReviews', {
      'filter[brand]': brandSlug,
      'filter[model]': modelSlug,
      'filter[generation]': generationSlug,
      'relations[review]': 'release,modification,user',
      'include': 'car_release,car_modification,user',
      'limit': 9,
      'attributes[review]': 'base,image,text',
      'with_filtered_count': true,
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    select: denormalizeData,
    enabled: process.env.BROWSER_RUNTIME,
  });

  if (reviewsAreLoading) return <PageLoader />;
  if (!reviews?.length) return null;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Indent bottom={vertical}>
      <Divider top={30} bottom={20} />
      <Drum
        grid
        showHeaderArrows
        showArrowsOnHover
        arrowsShift={-10}
        title='Отзывы владельцев'
        headerComponent={H3}
        itemWidth='40%'
        itemWidthMobile='100%'
        content={reviews}
        card={CardServiceReview1}
      />
    </Indent>
  );
}

Reviews.propTypes = {
  isMobile: PropTypes.bool,
  match: PropTypes.object,
};

export default withBreakpoint(Reviews);
