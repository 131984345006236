import queryString from 'core/libs/query-string';

import useRequest from 'core/hooks/useRequest';

import { renderError } from 'core/utils/app-status-helper';
import { processQueryResults } from 'core/utils/query';

import { servicesApi } from 'site/api/definitions/services';

import { getOffset } from 'core/utils/url-helper';
import { denormalizeData } from 'core/utils/api';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';
import { isMotoPath } from 'site/utils';

import {
  OFFERS_LIMIT,
  OFFERS_LIMIT_MOBILE,
} from 'site/constants';


export default function useDealerContent(queryParams) {
  const {
    match: {
      params: {
        geo,
        brand: brandSlug,
        offer_type: offerType,
        dealer_slug: dealerSlug,
      },
    },
    location: {
      pathname,
      search,
    },
    history,
    isDesktop,
  } = queryParams;

  const isMoto = isMotoPath(pathname);
  const vehiclePrefix = isMotoPath(pathname) ? 'moto' : 'car,lcv';
  const seoHandle = `get${isMoto ? 'Moto' : 'Car'}DealersDealer${brandSlug ? 'OfBrand' : ''}${offerType === 'used' ? 'Used' : ''}Seo`;
  const limit = isDesktop ? OFFERS_LIMIT : OFFERS_LIMIT_MOBILE;

  const results = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', 'getOffers', {
          ...offerType === 'used' && { 'filter[is_trade_in]': true },
          'filter[dealer_slug]': dealerSlug,
          'filter[brand]': brandSlug,
          'filter[geo]': geo,
          'attributes[dealer_offer]': `base,image,${isDesktop ? 'text' : ''}`,
          'relations[dealer_offer]': 'brand,model,modification,dealer,dealer_network',
          'relations[dealer]': 'network',
          include: 'car_brand,car_model,car_modification,dealer,dealer_network',
          offset: getOffset(search, limit),
          sort: queryString.parse(search).sort || 'price',
          with_filtered_count: true,
          limit,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
      }, {
        queryKey: ['servicesApi', 'getDealers', {
          'filter[geo]': geo,
          'filter[dealer_slug]': dealerSlug,
          'filter[type]': vehiclePrefix,
          'attributes[dealer]': 'base,text,stat',
          'relations[dealer]': `dealer_network,${vehiclePrefix}_brands,geo,geo_parent`,
          include: `dealer_network,${vehiclePrefix}_brand,geo`,
          limit: 1,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .then(result => denormalizeData(result)[0])
          .then(rejectEmptyResult)
          .catch(err => renderError(err, history)),
      }, {
        queryKey: ['servicesApi', seoHandle, {
          'filter[geo]': geo,
          'filter[dealer]': dealerSlug,
          'filter[brand]': brandSlug,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      },
    ],
  });

  const [{ data: offersData = {}, isLoading: offersDataIsLoading }, ...otherResults] = results;

  const {
    data: [
      dealer = {},
      dealerSeo = {},
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(otherResults);

  return {
    dealer,
    dealerSeo,
    offersData,
    offersDataIsLoading,
    isLoading: resultsAreLoading,
  };
}
