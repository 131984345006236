import { Fragment, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import H5 from 'core/components/H5';

import { Indent } from 'site/components/Wrappers';
import OptionSimple from 'site/components/OptionSimple';
import OptionRadioGroup from 'site/components/OptionRadioGroup';
import OptionDependenciesSimple from 'site/components/OptionDependenciesSimple';

import { ConfiguratorContext } from 'site/components/ConfiguratorProvider';

import { getRadioDependencies } from 'site/utils/configurator';

export default function Dependencies({ modificationId, option }) {
  const configurations = useContext(ConfiguratorContext);

  const {
    subgroups,
    options,
  } = configurations[modificationId] || {};

  const radioDependencies = getRadioDependencies(option.dependencies);

  /**
   * Получаем радио-группы с опциями, отфильтрованными только как зависимости от текущей.
   * Т.к. в зависимостях обычно идет урезанное количество опций радио-групп.
   * Например: "Обогрев задних сидений ограничивает выбор обивки 3 видами, а не 10+"
   */
  const currentSubgroups = useMemo(() => {
    if (!(radioDependencies || options) || !subgroups) return [];

    return radioDependencies
      .map(radio => radio.selection_id)
      .filter((id, index, selectionIds) => selectionIds.indexOf(id) === index)
      .map(subgroupId => {
        const subgroup = subgroups[subgroupId];
        const subgroupOptions = subgroup.options
          .filter(id => radioDependencies.some(({ child }) => child === id))
          .map(id => options[id]);
        return { ...subgroup, options: subgroupOptions };
      });
  }, [options, radioDependencies, subgroups]);

  const subgroupsCanRender = !!currentSubgroups.length &&
    currentSubgroups.some(subgroup => !!subgroup.options.length);

  return (
    <Fragment>
      <H5>Вы выбрали</H5>
      <Indent top={10} />
      <OptionSimple
        option={option}
        type={4}
        modificationId={modificationId}
      />

      {subgroupsCanRender && (
        <Indent top={25}>
          <H5>Ваш выбор включает</H5>
          <Indent top={14} />
          {currentSubgroups.map(subgroup => (
            <OptionRadioGroup
              key={subgroup.id}
              modificationId={modificationId}
              subgroup={subgroup}
            />
          ))}
        </Indent>
      )}

      <OptionDependenciesSimple
        type={3}
        option={option}
        modificationId={modificationId}
      />
    </Fragment>
  );
}

Dependencies.propTypes = {
  option: PropTypes.object,
  modificationId: PropTypes.string,
};
