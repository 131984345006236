import { denormalizeData } from 'core/utils/api';
import { renderError } from 'core/utils/app-status-helper';

import { servicesApi } from 'site/api/definitions/services';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';


export const motoReleaseQueries = (queryParams) => {
  const {
    match: {
      params: {
        brand: brandSlug,
        model: modelSlug,
        release: releaseSlug,
      },
    },
    history,
  } = queryParams || {};

  return [
    {
      queryKey: ['servicesApi', 'getMotoReleases', {
        'filter[brand]': brandSlug,
        'filter[model]': modelSlug,
        'filter[year]': releaseSlug,
        'filter[usages]': 'catalog,archive',
        include: 'moto_brand,moto_release',
        'attributes[moto_release]': 'base,image,media,specifications,extended',
        'attributes[moto_brand]': 'base,image',
        'relations[moto_release]': 'brand',
        limit: 1,
      }],
      queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
        .then(result => denormalizeData(result)[0])
        .then(rejectEmptyResult)
        .catch(err => renderError(err, history)),
    }, {
      queryKey: ['servicesApi', 'getMotoReleaseSeo', {
        'filter[brand]': brandSlug,
        'filter[model]': modelSlug,
        'filter[year]': releaseSlug,
      }],
      queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
      select: denormalizeData,
    },
  ];
};
