import useRequest from 'core/hooks/useRequest';

import { topicsQuery } from 'core/queries/topics';

import { processQueryResults } from 'core/utils/query';
import { filterRequiredParams } from 'core/utils/api';

import { brandsQuery } from 'site/queries/brands';
import { newReleasesQuery } from 'site/queries/releases';
import { comparisonsQuery } from 'site/queries/comparisons';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import Card5 from 'site/cards/Card5';


export const nowOnSaleApiParams = {
  include: 'car_brand,car_model,car_modification,dealer,dealer_network',
  'filter[price_min]': 2900000,
  'attributes[dealer_offer]': 'base,image',
  'attributes[dealer_network]': 'base,image',
  'relations[dealer_offer]': 'brand,model,modification,dealer,dealer_network',
  'relations[dealer]': 'dealer_network',
  limit: 7,
};

export default function useMainContent(queryParams) {
  const { isMobile } = queryParams;

  const results = useRequest({
    queries: [
      // newInJournal
      topicsQuery({
        limit: isMobile ? 7 : 10,
        include: filterRequiredParams([Card1], 'include'),
        fields: filterRequiredParams([Card1], 'fields'),
        visibility: 'main_page',
        renderError: () => [],
      }),

      // popular
      topicsQuery({
        limit: 4,
        include: filterRequiredParams([Card1, Card2, Card3, Card5], 'include'),
        fields: filterRequiredParams([Card1, Card2, Card3, Card5], 'fields'),
        sort: '-views_count',
        renderError: () => [],
      }),

      // brands
      brandsQuery(queryParams),

      // newReleases
      newReleasesQuery(),

      // comparisons
      comparisonsQuery(),
    ],
  });

  const {
    data: [
      newInJournal = [],
      popular = [],
      brands = [],
      newReleases = [],
      comparisons = [],
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  return {
    newInJournal,
    popular,
    brands,
    newReleases,
    comparisons,
    isLoading: resultsAreLoading,
  };
}

