import PropTypes from 'prop-types';

import queryString from 'core/libs/query-string';

import bindProps from 'core/components/bindProps';
import { withBreakpoint } from 'core/components/breakpoint';
import H1 from 'core/components/H1';
import Page from 'core/components/Page';
import Feed from 'core/components/Feed';
import ListPage from 'core/components/ListPage';
import PageLoader from 'core/components/Loader/PageLoader';

import PageLayoutService from 'site/components/PageLayoutService';
import { Indent } from 'site/components/Wrappers';
import BestChoice from 'site/components/BestChoice';
import NothingFound from 'site/components/NothingFound';
import Comparison from 'site/components/Comparison';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE, BEST_CHOICE_LIMIT } from 'site/constants';

import CardModification1WithPrice from 'site/cardsService/CardServiceModification1/cards/CardModification1WithPrice';
import CardModification2WithPrice from 'site/cardsService/CardServiceModification2/cards/CardModification2WithPrice';

import useBestChoiceSelectionContent from './useBestChoiceSelectionContent';


function getPositionOffset(page) {
  return (page - 1) * BEST_CHOICE_LIMIT + 1;
}

function BestChoiceSelectionPage(props) {
  const {
    bestChoice,
    editorsChoice,
    comparisons,
    editorsChoiceSeo,
    selections,
    seoIsLoading,
    isLoading,
    footerContentIsLoading,
  } = useBestChoiceSelectionContent(props);

  const {
    isMobile,
    location: {
      search,
    },
  } = props;

  const {
    name,
    description,
    seo_head: seoHead,
    type,
  } = bestChoice?.attributes || {};

  const {
    title: seoTitle,
    description: seoDescription,
  } = editorsChoiceSeo || {};

  const positionOffset = getPositionOffset(queryString.parse(search).page || 1);

  const CardServiceModification1WithAllModifications = bindProps({
    showAllModification: true,
    positionOffset,
  })(CardModification1WithPrice);

  const CardServiceModification2WithAllModifications = bindProps({
    showAllModification: true,
  })(CardModification2WithPrice);

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <PageLayoutService>
      {seoIsLoading && <PageLoader />}
      {!seoIsLoading && (
        <>
          <ListPage
            title={seoTitle}
            description={seoDescription}
            rawData={selections}
            limit={BEST_CHOICE_LIMIT}
            isLoading={isLoading}
          >
            {({ content }) => {
              return (
                <Indent bottom={vertical}>
                  <H1>{seoHead || name}</H1>
                  {description && <p>{description}</p>}
                  <Indent bottom={vertical} />

                  {isLoading && <PageLoader />}

                  {!isLoading && !content?.length && (
                    <Page title={seoTitle} description={seoDescription}>
                      <NothingFound text='Ничего не найдено.' />
                    </Page>
                  )}

                  {!isLoading && content?.length && (
                    <Feed
                      content={content}
                      card={isMobile ? CardServiceModification2WithAllModifications : CardServiceModification1WithAllModifications}
                    />
                  )}
                </Indent>
              );
            }}
          </ListPage>
          {!footerContentIsLoading && (
            <>
              <Indent bottom={vertical} top={vertical}>
                <Comparison content={comparisons} vehicleType={type} />
              </Indent>
              <Indent top={vertical} bottom={vertical}>
                <BestChoice content={editorsChoice} />
              </Indent>
            </>
          )}
        </>
      )}
    </PageLayoutService>
  );
}

BestChoiceSelectionPage.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default withBreakpoint(BestChoiceSelectionPage);
