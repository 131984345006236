import PropTypes from 'prop-types';

import bindProps from 'core/components/bindProps';
import { withNonPureBreakpoint } from 'core/components/breakpoint';

import { Section, Block } from 'core/components/Grid';

import { Indent } from 'site/components/Wrappers';
import InputDropdown from 'site/components/InputDropdown';
import Chip from 'site/components/Chip';
import InputRange from 'site/components/InputRange';
import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';
import ChipRange from 'site/components/ChipRange';

import {
  SIDE_INDENT_MOBILE,
} from 'site/constants';

import ButtonOffers from './Button';

import styles from './index.styl';


const InputDropdownSingle = bindProps({
  isMulti: false,
})(InputDropdown);

function Form(props) {
  const {
    brand,
    models,
    formState,
    formOptions,
    isMobile,
    onChange,
    onBrandChange,
    ranges,
  } = props;

  const {
    filteredBrands,
    filteredModels,
    filteredBodies,
    filteredConditions,
  } = formOptions;

  const {
    model,
    body,
    year,
    condition,
    price,
  } = formState;

  const chipRangeDropdownProps = {
    ...isMobile && { contentPadding: (SIDE_INDENT_MOBILE - 2) + 'px' },
    contentPosition: isMobile ? 'center' : 'left',
  };

  return (
    <ThemeSwitchWithColor tone='dark'>
      <Section>
        <Block width={12}>
          <Indent bottom={isMobile ? 1 : 15}>
            <InputDropdownSingle
              dataQa='form-brand'
              name='brand'
              options={filteredBrands}
              label='Марка'
              placeholder='Введите название марки'
              value={brand}
              onChange={onBrandChange}
              isClearable
              {...isMobile && { hideBorder: true }}
            />
          </Indent>
        </Block>
      </Section>
      <Section>
        <Block width={12}>
          <Indent bottom={25}>
            <InputDropdownSingle
              dataQa='form-model'
              name='model'
              options={filteredModels}
              label='Модельный ряд'
              value={model}
              onChange={onChange}
              placeholder='Введите название модели'
              isDisabled={filteredModels.length < 1}
              isClearable
              {...isMobile && {
                roundingPosition: 'bottom',
                hideBorder: true,
              }}
            />
          </Indent>
        </Block>
      </Section>
      <Section>
        <Block>
          <Indent bottom={25}>
            <InputRange
              dataQa='price'
              name='price'
              title='Цена'
              min={ranges.price.min}
              max={ranges.price.max}
              step={100000}
              unit='₽'
              value={price}
              onChange={onChange}
              arrangeJustify
            />
          </Indent>
        </Block>
      </Section>
      <Indent bottom={15}>
        <Section>
          <div className={styles.chipWrapper}>
            <Chip
              dataQa='form-body'
              name='body'
              placeholder='Кузов'
              options={filteredBodies}
              value={body}
              onChange={onChange}
            />
          </div>
          <div className={styles.chipWrapper}>
            <Chip
              dataQa='form-condition'
              name='condition'
              options={filteredConditions}
              placeholder='Состояние'
              menuWidth={290}
              value={condition}
              onChange={onChange}
            />
          </div>
          <div className={styles.chipWrapper}>
            <ChipRange
              dataQa='form-year'
              name='year'
              title='Год выпуска'
              placeholder='Год выпуска'
              min={ranges.year.min}
              max={ranges.year.max}
              value={year}
              onChange={onChange}
              dropdownProps={chipRangeDropdownProps}
            />
          </div>
        </Section>
      </Indent>
      <div className={styles.buttonWrapper}>
        <ButtonOffers
          brand={brand}
          models={models}
          formState={formState}
          ranges={ranges}
        />
      </div>
    </ThemeSwitchWithColor>
  );
}

Form.propTypes = {
  isMobile: PropTypes.bool,
  brand: PropTypes.object,
  models: PropTypes.array,
  formState: PropTypes.object,
  formOptions: PropTypes.object,
  onChange: PropTypes.func,
  onBrandChange: PropTypes.func,
  ranges: PropTypes.object,
};

export default withNonPureBreakpoint(Form);
