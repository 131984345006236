import { useState } from 'react';

import useRequest from 'core/hooks/useRequest';

import { denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';

import {
  generateSelectOptions,
  generateOptionsGroup,
} from 'site/utils/forms';

import { servicesApi } from 'site/api/definitions/services';


const getFormOptions = (dictionaries = {}) => ({
  filteredBodies: generateSelectOptions(dictionaries.body, { value: 'attributes.slug' }),
  filteredEngineTypes: generateSelectOptions(dictionaries.engine_type, { label: 'title', value: 'value' }),
  filteredTransmissions: generateSelectOptions(dictionaries.transmission, { label: 'title', value: 'value' }),
  filteredCountries: generateSelectOptions(dictionaries.country, { label: 'title', value: 'value' }),
  filteredClasses: generateSelectOptions(dictionaries.class, { label: 'attributes.name', value: 'attributes.url' }),
  filteredAssemblies: generateSelectOptions(dictionaries.assembly, { label: 'title', value: 'value' }),
  filteredBoosts: generateSelectOptions(dictionaries.boost, { label: 'title', value: 'value' }),
  filteredControls: generateSelectOptions(dictionaries.control, { label: 'title', value: 'value' }),
  filteredSeats: dictionaries.seats,
  filteredOptions: generateOptionsGroup(dictionaries.option || {}),
  filteredUsages: generateSelectOptions([{ title: 'В продаже', value: 'catalog' }], { label: 'title', value: 'value' }),
});

/* eslint-disable react/prop-types */
export default function withContent(Component) {
  return function WrappedComponent(props) {
    const {
      match: {
        params: {
          lcv,
        },
      },
    } = props;
    const [isLcv, setIsLcv] = useState(Boolean(lcv));

    const results = useRequest({
      queries: [
        {
          queryKey: ['servicesApi', 'getCatalogDictionaries'],
          queryFn: ({ queryKey: [, method] }) => servicesApi[method]()
            .then(getFormOptions),
          enabled: process.env.BROWSER_RUNTIME,
        }, {
          queryKey: ['servicesApi', 'getCatalogBaseEntities', { type: isLcv ? 'lcv' : 'all' }],
          queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
          select: denormalizeData,
          enabled: process.env.BROWSER_RUNTIME,
        },
      ],
    });

    const {
      data: [
        formOptions = getFormOptions(),
        baseEntities = {},
      ],
    } = processQueryResults(results);

    return (
      <Component
        {...props}
        formOptions={formOptions}
        baseEntities={baseEntities}
        isLcv={isLcv}
        onLcvChange={e => setIsLcv(e.target.checked)}
      />
    );
  };
}
