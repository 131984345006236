import useRequest from 'core/hooks/useRequest';

import { topicQuery } from 'core/queries/topic';

import Link from 'core/components/Link';
import Button from 'core/components/Button';
import Comments from 'core/components/Comments';
import Topic from 'core/components/Topic';
import TopicTopline from 'core/components/TopicTopline';
import PageLoader from 'core/components/Loader/PageLoader';

import Headline from 'site/components/Headline';
import { Indent } from 'site/components/Wrappers';

import TopicWrapper from 'site/pages/topic/TopicWrapper';

import { JOURNAL_URL } from 'site/constants';


export default function CommentsPage(props) {
  const { data, isLoading } = useRequest(topicQuery(props));

  if (isLoading) return <PageLoader />;

  return (
    <Topic content={data}>
      {topic => {
        const {
          id,
          attributes: {
            headline,
            link,
            is_comments_disabled: isCommentsDisabled,
            is_premium: isPremium,
          },
        } = topic;

        return (
          <TopicWrapper isCommentsPage content={topic}>
            {!isPremium && (
              <>
                <Indent bottom={20}>
                  <TopicTopline path={JOURNAL_URL} />
                </Indent>

                <Headline>{headline}</Headline>
              </>
            )}

            <Indent bottom={20}>
              <Link to={link} type='secondary'>
                <Button>Вернуться к статье</Button>
              </Link>
            </Indent>

            <Comments
              adv
              advInterval={4}
              xid={id}
              isCommentsDisabled={isCommentsDisabled}
            />
          </TopicWrapper>
        );
      }}
    </Topic>
  );
}
