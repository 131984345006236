import useRequest from 'core/hooks/useRequest';

import queryString from 'core/libs/query-string';

import { getOffset } from 'core/utils/url-helper';
import { renderError } from 'core/utils/app-status-helper';
import { denormalizeData } from 'core/utils/api';

import { servicesApi } from 'site/api/definitions/services';

import { buildSearchRequestParams } from 'site/utils';


export const REVIEWS_LIMIT = 9;

export default function useReleaseContent(queryParams) {
  const {
    match,
    match: {
      params: {
        brand: brandSlug,
        model: modelSlug,
        generation: generationSlug,
        body: bodySlug,
      },
    },
    location: { search },
    history,
  } = queryParams;

  const requestParams = buildSearchRequestParams(search);

  const [{
    data: reviewsSeo = {},
    isLoading: seoIsLoading,
  }, {
    data: reviews = {},
    isLoading: reviewsAreLoading,
  }] = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', 'getReviewsSeo', match],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .catch(() => ({})),
        select: denormalizeData,
      }, {
        queryKey: ['servicesApi', 'getReviews', {
          ...requestParams,
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'filter[body]': bodySlug,
          offset: getOffset(search, REVIEWS_LIMIT),
          limit: REVIEWS_LIMIT,
          sort: queryString.parse(search).sort || '-like',
          with_filtered_count: true,
          'relations[review]': 'brand,model,release,modification,user',
          include: 'car_brand,car_model,car_release,car_modification,user',
          'attributes[review]': 'base,image,text',
          'attributes[user]': 'base,image',
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .catch(err => renderError(err, history)),
      },
    ],
  });

  return {
    reviews,
    reviewsSeo,
    seoIsLoading,
    isLoading: reviewsAreLoading,
  };
}
