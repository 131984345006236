import { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';

import useRequest from 'core/hooks/useRequest';

import queryString from 'core/libs/query-string';

import { VOID_QUERY } from 'core/site/constants';

import Link from 'core/components/Link';
import Button from 'core/components/Button';
import withTheme from 'core/components/theme';
import { SimpleLoader } from 'core/components/Loader';

import pluralize from 'core/utils/pluralize';

import { servicesApi } from 'site/api/definitions/services';

import { GeoContext } from 'site/components/GeoContext';

import {
  excludeObjectValues,
  getSlugInObject,
} from 'site/utils';

import {
  buildPathnameFromValues,
  buildSearchParamsFromValues,
} from 'site/utils/forms';

import styles from './index.styl';

import { BASE_PATH, PATHNAME_FIELDS } from '../constants';


const OFFERS_WORD_FORMS = ['объявление', 'объявления', 'объявлений'];

function ButtonOffers(props) {
  const {
    brand,
    models,
    modelsAreLoading,
    formState,
    ranges,
  } = props;

  const { geoSlug } = useContext(GeoContext);

  const {
    model,
    condition = [],
    price = {},
    body = [],
    year = {},
  } = formState;

  const disableRequest = brand
    && !modelsAreLoading
    && (!models.length || models.filter(m => brand.id !== m.relationships.brand.data.id).length);

  const {
    data: count = 0,
    isLoading: countIsLoading,
  } = useRequest(disableRequest ? VOID_QUERY : {
    queryKey: ['servicesApi', 'getOffers', {
      'filter[brand]': brand?.value || brand,
      'filter[model]': model?.value || model,
      'filter[body_type]': body ? getSlugInObject(body) : '',
      'filter[condition]': condition.map(item => item.value).join(','),
      'filter[price_min]': price ? price.min : '',
      'filter[price_max]': price ? price.max : '',
      'filter[year_min]': year ? year.min : '',
      'filter[year_max]': year ? year.max : '',
      'filter[geo]': geoSlug,
      only_filtered_count: true,
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    select: data => data?.meta?.filtered_count,
    enabled: process.env.BROWSER_RUNTIME,
  });

  const EMPTY = {};

  const ButtonWrapper = count ? Link : Fragment;

  const pathname = buildPathnameFromValues(
    { ...formState, brand },
    PATHNAME_FIELDS,
    BASE_PATH + (geoSlug ? `/${geoSlug}` : '')
  );

  const searchParams = buildSearchParamsFromValues(
    excludeObjectValues(formState, PATHNAME_FIELDS),
    ranges,
  );

  const buttonWrapperProps = count ? { to: [pathname, queryString.stringify(searchParams)].join('?') } : EMPTY;

  const formattedCount = accounting.formatMoney(count, pluralize(count, OFFERS_WORD_FORMS));
  const buttonText = !count ? 'Нет объявлений' : formattedCount;

  return (
    <ButtonWrapper {...buttonWrapperProps}>
      <Button
        type='limeGradient'
        radius='50px'
        minWidth='100%'
        disabled={!count}
      >
        {countIsLoading ? <SimpleLoader spinnerWidth={24} spinnerHeight={24} /> : <span className={styles.buttonText}>{buttonText}</span>}
      </Button>
    </ButtonWrapper>
  );
}

ButtonOffers.propTypes = {
  brand: PropTypes.object,
  models: PropTypes.array,
  modelsAreLoading: PropTypes.bool,
  countOffers: PropTypes.number,
  formState: PropTypes.object,
  ranges: PropTypes.object,
};

export default withTheme(ButtonOffers);
