import PropTypes from 'prop-types';
import cx from 'classnames';

import useRequest from 'core/hooks/useRequest';

import { processQueryResults } from 'core/utils/query';

import { withBreakpoint, Mobile } from 'core/components/breakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';
import Page from 'core/components/Page';
import PageLoader from 'core/components/Loader/PageLoader';

import ReleasePromo from 'site/components/ReleasePromo';
import Price from 'site/components/Price';
import ButtonCompare from 'site/components/ButtonCompare';
import Specifications from 'site/components/Specifications';
import SpecificationList from 'site/components/SpecificationList';
import Classmates from 'site/components/Classmates';
import SimilarOffers from 'site/components/SimilarOffers';
import WhereToBuy from 'site/components/WhereToBuy';
import { Listing2 } from 'site/components/Ads/mobile';
import { Indent, NegativeMobile } from 'site/components/Wrappers';

import { motoReleaseQueries } from 'site/queries/motoRelease';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';


function MotoReleasePage(props) {
  const { isMobile } = props;

  const results = useRequest({ queries: motoReleaseQueries(props) });

  const {
    data: [
      release = {},
      seo = {},
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  const {
    id: releaseId,
    type,
  } = release || {};

  const {
    min_price: minPrice,
    max_price: maxPrice,
    is_price_undeclared: isPriceUndeclared,
    specifications,
    specification_groups: specificationGroups,
  } = release?.attributes || {};

  const averagePrice = (+minPrice + +maxPrice) / 2;
  const brandName = release?.relationships?.brand?.attributes?.name;
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Page
      title={seo?.title}
      description={seo?.description}
    >
      {resultsAreLoading && <PageLoader />}
      {!resultsAreLoading && (
        <AdWrapper bottom={vertical}>
          <div className={cx(isMobile && styles.mobile)}>
            <NegativeMobile>
              <ReleasePromo release={release} />
            </NegativeMobile>
            <Indent bottom={vertical} />
            <div className={styles.panel}>
              {Boolean(averagePrice || isPriceUndeclared) &&
              <Price
                value={averagePrice}
                isPriceUndeclared={isPriceUndeclared}
                type={2}
              /> }
              <Mobile>
                <Indent bottom={vertical} />
              </Mobile>
              <ButtonCompare modelId={releaseId} transportType={type} />
            </div>
            <Indent bottom={vertical} />
            <Specifications modification={release} />
            <Indent bottom={vertical} />
            <SpecificationList
              specifications={specifications}
              specificationGroups={specificationGroups}
            />
            <Indent bottom={vertical} />
            <Classmates withShadowBg />
            <SimilarOffers />
          </div>
          <Indent bottom={vertical} />
          <WhereToBuy brandName={brandName} />
          <Indent bottom={vertical} />
          <Mobile>
            <Listing2 />
          </Mobile>
        </AdWrapper>
      )}
    </Page>
  );
}

MotoReleasePage.propTypes = {
  isMobile: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      model: PropTypes.string,
      release: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.object,
};

export default withBreakpoint(MotoReleasePage);
