import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import ListPage from 'core/components/ListPage';
import Feed from 'core/components/Feed';
import PageLoader from 'core/components/Loader/PageLoader';

import { withBreakpoint } from 'core/components/breakpoint';
import bindProps from 'core/components/bindProps';

import { getOffset } from 'core/utils/url-helper';

import { Indent, PageIndent } from 'site/components/Wrappers';

import CardDealer from 'site/cardsService/CardServiceDealer';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';


const LIMIT = 10;
const LIMIT_MOBILE = 20;


function DealersCardList(props) {
  const {
    dealers,
    isLoading,
    cardClickHandler,
    isMobile,
    location: {
      search,
    },
    match: {
      params: {
        brand: brandSlug,
      },
    },
  } = props;

  const limit = isMobile ? LIMIT_MOBILE : LIMIT;
  const offset = useMemo(
    () => getOffset(search, limit),
    [search, limit]
  );
  const currentPageDealers = dealers?.slice(offset, offset + limit);

  const verticalIndent = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <PageIndent>
      <ListPage
        content={currentPageDealers}
        meta={{ filtered_count: dealers?.length || 0 }}
        limit={limit}
        compactPaging
        isLoading={isLoading}
      >
        {({ content: pageDealers }) => {
          if (isLoading) return <PageLoader />;

          return (
            <Indent bottom={verticalIndent} top={verticalIndent}>
              <Feed
                grid
                content={pageDealers}
                card={isMobile
                  ? bindProps({ type: 1, brandSlug })(CardDealer)
                  /**
                   * На серверном рендеринге делаем ссылку на дилера.
                   * Для этого вместо clickHandler'а передаём brandSlug,
                   * по аналогии с мобилкой.
                   *
                   * На клиенте будет передаваться хэндлер, и будет возможность
                   * показывать дилера на карте.
                   */
                  : bindProps(!process.env.BROWSER_RUNTIME
                    ? { brandSlug }
                    : { handleClick: cardClickHandler }
                  )(CardDealer)}
              />
            </Indent>
          );
        }}
      </ListPage>
    </PageIndent>
  );
}

DealersCardList.propTypes = {
  /** Дилеры для отображения в списке. */
  dealers: PropTypes.array,

  /** Индикатор загрузки данных. */
  isLoading: PropTypes.bool,

  /** Общее количество дилеров, полученных из запроса. */
  filteredCount: PropTypes.number,

  /** Слаг бренда для передачи в карточки. */
  brandSlug: PropTypes.string,

  /** Обработчик клика по карточке фида. */
  cardClickHandler: PropTypes.func,

  /** Количество карточек на страницу. */
  limit: PropTypes.number,

  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
    }),
  }),

  /** @ignore */
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default withRouter(withBreakpoint(DealersCardList));
