import PropTypes from 'prop-types';
import { Redirect } from 'core/libs/router';

import useRequest from 'core/hooks/useRequest';

import { renderError } from 'core/utils/app-status-helper';
import { denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';

import resolveRelationships from 'core/utils/relationships';

import Page from 'core/components/Page';
import PageLoader from 'core/components/Loader/PageLoader';
import { withBreakpoint } from 'core/components/breakpoint';

import { servicesApi } from 'site/api/definitions/services';

import ReleaseBoroda from 'site/components/ReleaseBoroda';
import { ReleaseInnerPages } from 'site/components/Wrappers';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';

import { brandTagExistsQuery } from 'site/queries/brandTagExists';

import Feature from './Feature';
import Comment from './Comment';


const relationships = resolveRelationships(['features'], {}, { features: [] });

function ReleaseFeature(props) {
  const {
    match: {
      params: {
        lcv,
        brand: brandSlug,
        model: modelSlug,
        generation: generationSlug,
        body: bodySlug,
        feature_id: featureId,
        suffix,
        comments,
      },
    },
  } = props;

  const results = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', 'getReleases', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'filter[body]': bodySlug,
          include: 'car_brand,car_feature',
          'relations[car_release]': 'brand,features',
          'attributes[car_release]': 'base,media,seo,image,extended',
          'attributes[car_feature]': 'base,image,text',
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .then(result => denormalizeData(result)[0])
          .then(rejectEmptyResult)
          .catch(err => renderError(err, history)),
      }, {
        queryKey: ['servicesApi', 'getReleasesFeatureSeo', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'filter[submodel]': bodySlug,
          'filter[feature]': featureId,
          ...comments && { comments: true },
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      },
      brandTagExistsQuery(props),
    ],
  });

  const {
    data: [
      release = {},
      seo = {},
      isBrandTagExists,
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  if (resultsAreLoading) return <PageLoader />;

  const { features } = relationships(release);
  const releaseFeatureExists = features.some(feature => String(feature.attributes.slug) === featureId);

  /**
   * TODO: Обрабатываем редирект по мотивам задачки UCMS-5693
   */
  if (!releaseFeatureExists) {
    const redirectPath = [
      lcv,
      brandSlug,
      modelSlug,
      generationSlug,
      bodySlug,
      features.length > 0 ? suffix : null,
    ].filter(Boolean).join('/');

    return <Redirect to={`/${redirectPath}`} />;
  }

  const currentFeature = features.find(feature => String(feature.attributes.slug) === featureId);
  const filteredFeatures = features.filter(feature => String(feature.attributes.slug) !== featureId);

  const featureProps = { currentFeature, filteredFeatures };

  return (
    <Page title={seo?.title} description={seo?.description}>
      <ReleaseInnerPages release={release} isBrandTagExists={isBrandTagExists}>
        {comments
          ? <Comment {...featureProps} />
          : <Feature {...featureProps} />
        }
        <ReleaseBoroda release={release} />
      </ReleaseInnerPages>
    </Page>
  );
}

ReleaseFeature.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      lcv: PropTypes.string,
      brand: PropTypes.string.isRequired,
      model: PropTypes.string.isRequired,
      generation: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      suffix: PropTypes.string.isRequired,
      feature_id: PropTypes.string.isRequired,
      comments: PropTypes.string,
    }),
  }),
};

export default withBreakpoint(ReleaseFeature);
