import { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';

import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';

import { ConfiguratorContext } from 'site/components/ConfiguratorProvider';

import styles from './index.styl';

function Totals({ modificationId, theme, basePrice }) {
  const configurations = useContext(ConfiguratorContext);

  const {
    installed_price: installedPrice,
  } = configurations[modificationId]?.configuration || {};

  return (
    <Fragment>
      <style jsx>{`
        .${styles.row}
          &:before
            border-bottom 1px dashed ${theme.colors.divider}

        .${styles.name}
        .${styles.price}
          background ${theme.colors.content}
      `}</style>
      <div className={styles.row}>
        <div className={styles.name}>Базовая цена</div>
        <div className={styles.price}>{accounting.formatMoney(basePrice)}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.name}>Опции</div>
        <div className={styles.price}>{accounting.formatMoney(installedPrice || 0)}</div>
      </div>
    </Fragment>
  );
}

Totals.propTypes = {
  theme: themePropTypes(`{
    colors: {
      divider,
    }
  }`),
  basePrice: PropTypes.number,
  modificationId: PropTypes.string,
};

export default withTheme(Totals);
