import useRequest from 'core/hooks/useRequest';

import { denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';

import { generateSelectOptions } from 'site/utils/forms';

import { servicesApi } from 'site/api/definitions/services';


const getFormOptions = (dictionaries = {}, baseEntities = {}) => ({
  filteredModels: baseEntities.models || [],
  filteredBrands: generateSelectOptions(baseEntities.brands),
  filteredTypes: generateSelectOptions(dictionaries.type),
  filteredClasses: generateSelectOptions(dictionaries.class),
  filteredEngineTypes: generateSelectOptions(dictionaries.engine_type, { label: 'title', value: 'value' }),
  filteredConfigurate: generateSelectOptions(dictionaries.configurate, { label: 'title', value: 'value' }),
  filteredTransmission: generateSelectOptions(dictionaries.transmission, { label: 'title', value: 'value' }),
  filteredTransfer: generateSelectOptions(dictionaries.transfer, { label: 'title', value: 'value' }),
  filtiredCooling: generateSelectOptions(dictionaries.cooling, { label: 'title', value: 'value' }),
  filteredBoost: generateSelectOptions(dictionaries.boost, { label: 'title', value: 'value' }),
  filteredAbs: generateSelectOptions(dictionaries.abs, { label: 'title', value: 'value' }),
  filteredUsages: generateSelectOptions([{ title: 'В продаже', value: 'catalog' }], { label: 'title', value: 'value' }),
});

export default function withContent(Component) {
  return function WrappedComponent(props) {
    const results = useRequest({
      queries: [
        {
          queryKey: ['servicesApi', 'getMotoDictionaries'],
          queryFn: ({ queryKey: [, method] }) => servicesApi[method](),
          enabled: process.env.BROWSER_RUNTIME,
        }, {
          queryKey: ['servicesApi', 'getMotoBaseEntities'],
          queryFn: ({ queryKey: [, method] }) => servicesApi[method](),
          select: denormalizeData,
          enabled: process.env.BROWSER_RUNTIME,
        },
      ],
    });

    const {
      data: [
        dictionaries = {},
        baseEntities = {},
      ],
      isLoading,
    } = processQueryResults(results);

    return (
      <Component
        {...props}
        formOptions={getFormOptions(dictionaries, baseEntities)}
        isLoading={isLoading}
      />
    );
  };
}
