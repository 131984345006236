import { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import { Indent } from 'site/components/Wrappers';
import Divider from 'site/components/Divider';

import modelPropTypes, { carModificationAttributes } from 'site/utils/prop-types/model';

import InstalledOptions from '../InstalledOptions';
import Abilities from '../Abilities';
import Totals from '../Totals';

import { ConfiguratorContext } from 'site/components/ConfiguratorProvider';


function Complectation({ modification, match }) {
  const {
    id: modificationId,
    attributes: {
      price: basePrice,
    },
  } = modification;

  const isPropertyPage = match.params.property;

  const configurations = useContext(ConfiguratorContext);

  const {
    battery,
  } = configurations[modificationId]?.configuration || {};

  return (
    <div className='complectation'>
      <Abilities battery={battery} />
      <Divider />
      <Indent top={20} />
      <Totals modificationId={modificationId} basePrice={basePrice} />
      {!isPropertyPage && (
        <Fragment>
          <Indent top={10} />
          <InstalledOptions modificationId={modificationId} />
        </Fragment>
      )}
    </div>
  );
}

Complectation.propTypes = {
  modification: modelPropTypes(carModificationAttributes),
  match: PropTypes.shape({
    params: PropTypes.shape({
      property: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(Complectation);
