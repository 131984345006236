import useRequest from 'core/hooks/useRequest';

import { renderError } from 'core/utils/app-status-helper';
import { denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';

import { servicesApi } from 'site/api/definitions/services';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';

import { brandTagExistsQuery } from 'site/queries/brandTagExists';


export default function useReleaseContent(queryParams) {
  const {
    match: {
      params: {
        brand: brandSlug,
        model: modelSlug,
        generation: generationSlug,
        body: bodySlug,
      },
    },
    history,
  } = queryParams;

  const results = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', 'getReleases', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'filter[body]': bodySlug,
          include: 'car_brand,car_feature',
          limit: 1,
          'relations[car_release]': 'brand,features',
          'attributes[car_release]': 'base,image,media,seo,extended,stats',
          'attributes[car_brand]': 'base,image',
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .then(result => denormalizeData(result)[0])
          .then(rejectEmptyResult)
          .catch(err => renderError(err, history)),
      }, {
        queryKey: ['servicesApi', 'getReleasesSeo', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'filter[submodel]': bodySlug,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      }, {
        queryKey: ['servicesApi', 'getModifications', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'filter[body]': bodySlug,
          include: 'car_modification_completion,car_release',
          'attributes[car_modification]': 'base,extended,stats,prices',
          'attributes[car_release]': 'stats',
          'relations[car_modification]': 'completion,release',
          'relations[car_release]': 'stats',
          sort: '-price',
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      }, {
        queryKey: ['servicesApi', 'getReleases', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'attributes[car_release]': 'base,image',
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      },
      brandTagExistsQuery(queryParams),
    ],
  });

  const {
    data: [
      release = {},
      seo = {},
      modifications = [],
      bodies = [],
      isBrandTagExists,
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  return {
    release,
    seo,
    modifications,
    bodies,
    isBrandTagExists,
    isLoading: resultsAreLoading,
  };
}
