import { useState } from 'react';

import useRequest from 'core/hooks/useRequest';

import { denormalizeData } from 'core/utils/api';

import { servicesApi } from 'site/api/definitions/services';

import { getOwnershipCostQueryKey } from '../useModificationContent';


/* eslint-disable react/prop-types */
export default function withContent(Component) {
  return function WrappedComponent(props) {
    const { match } = props;

    const [run, setRun] = useState(props.run);
    const [geoUrl, setGeoUrl] = useState(props.geoUrl);

    const { data: geos = [], isLoading: geosAreLoading } = useRequest({
      queryKey: ['servicesApi', 'getCatalogOsagoGeos', {}],
      queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
      select: denormalizeData,
      enabled: process.env.BROWSER_RUNTIME,
    });

    const { data: ownershipCost = {}, isLoading: costIsLoading } = useRequest({
      queryKey: getOwnershipCostQueryKey(run, geoUrl, match.params),
      queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
        .then(denormalizeData)
        .then(data => data[0].attributes.cost_of_ownership),
      enabled: process.env.BROWSER_RUNTIME,
    });

    const groupedOptions = geos.map((geo) => {
      if (geo.attributes.children?.length) {
        return {
          label: geo.attributes.name,
          options: geo.attributes.children.concat([{
            attributes: {
              name: `${geo.attributes.name} - другой город`,
              url: geo.attributes.url,
            },
          }]),
        };
      }
      return geo;
    });

    return (
      <Component
        {...props}
        ownershipCost={ownershipCost}
        run={run}
        geoUrl={geoUrl}
        geos={geos}
        groupedOptions={groupedOptions}
        onRunChange={setRun}
        onGeoChange={setGeoUrl}
        isLoading={geosAreLoading}
        costIsLoading={costIsLoading}
      />
    );
  };
}
