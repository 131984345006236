import { useContext } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';
import { GlobalTopicContext } from 'core/components/GlobalTopicProvider';

import ContentBackground from 'core/components/ContentBackground';
import { StickyContainer } from 'core/components/Shapka';
import Layout, { Header, Content, Footer } from 'core/components/Layout';
import { Mobile, Desktop } from 'core/components/breakpoint';

import CookiesBanner from 'core/components/CookiesBanner';

import Stories from 'site/components/Stories';
import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';

import { isGameCompare } from '../../utils';

import Shapka from '../Shapka';
import Scooter from '../Scooter';

import { Billboard } from '../Ads/desktop';
import { TopBanner } from '../Ads/mobile';


function SiteLayout({ children, location }) {
  const isMainPage = location.pathname === '/';
  const isGameComparePage = isGameCompare(location.pathname);

  const content = useContext(GlobalTopicContext);
  const isPremiumTopic = content?.attributes?.is_premium;

  return (
    <StickyContainer>
      <ThemeSwitchWithColor tone={isGameComparePage ? 'dark' : 'light'}>
        <ContentBackground>
          <Layout>
            <Header>
              <Shapka isSecondShapkaForPremium={isPremiumTopic} />
            </Header>
            <Content>
              {!isPremiumTopic && (
                <>
                  <Desktop>
                    <Billboard />
                  </Desktop>
                  <Mobile>
                    <TopBanner />
                  </Mobile>
                </>
              )}
              {(!isMainPage && !isPremiumTopic) && <Stories />}
              {children}
            </Content>
            <Footer>
              <Scooter />
            </Footer>
            <CookiesBanner />
          </Layout>
        </ContentBackground>
      </ThemeSwitchWithColor>
    </StickyContainer>
  );
}

SiteLayout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};

export default withRouter(SiteLayout);
