import { Fragment } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/components/theme';
import { withBreakpoint, Mobile, Desktop } from 'core/components/breakpoint';
import Button from 'core/components/Button';
import ButtonWithIcon from 'core/components/ButtonWithIcon';
import ThemeSwitchWithColor from 'site/components/ThemeSwitchWithColor';
import ButtonTextWrap from 'site/components/Wrappers/ButtonTextWrap';

import ArrowStickLeft from 'site/icons/ArrowStickLeft';
import CircleCrossShifted from 'site/icons/CircleCrossShifted';

import Count from './Count';

import {
  SIDE_INDENT,
  SIDE_INDENT_MOBILE,
} from 'site/constants';

import styles from './index.styl';

const titlePart = {
  catalog: 'каталогу',
  offers: 'объявлениям',
};

function FullFormTopline(props) {
  const {
    isDesktop,
    theme,
    navigateToNewUrl,
    allFiltersCount,
    resetAllForm,
    formType,
    formState,
    isLcv,
    isMoto,
    ranges,
  } = props;

  const horizontalIndent = isDesktop ? SIDE_INDENT : SIDE_INDENT_MOBILE;

  const titlePartByType = titlePart[formType];
  return (
    <ThemeSwitchWithColor tone='dark'>
      <div className={styles.topline}>
        <style jsx>{`
        .${styles.topline}
          padding 10px ${horizontalIndent}px
          background ${theme.colors.active1}
        .title
          font ${theme.texts.menu.font}
          text-transform ${theme.texts.menu.textTransform}
          letter-spacing ${theme.texts.menu.letterSpacing}
      `}</style>
        <div className={styles.right}>
          <div className={styles.close} onClick={navigateToNewUrl}>
            <ArrowStickLeft width='14' height='14' />
          </div>
          <span className='title'>
            Фильтры{isDesktop && `  по ${titlePartByType}`}
          </span>
        </div>
        <div>
          {allFiltersCount > 0 && (
            <Fragment>
              <Mobile>
                <ButtonWithIcon
                  radius='18px'
                  type='secondary'
                  size='small'
                  iconPosition='right'
                  icon={CircleCrossShifted}
                  minWidth={0}
                  className={styles.reset}
                  onClick={resetAllForm}
                >
                  <ButtonTextWrap>{`Сброс (${allFiltersCount})`}</ButtonTextWrap>
                </ButtonWithIcon>
              </Mobile>
              <Desktop>
                <span
                  className={styles.reset}
                  onClick={resetAllForm}
                >
                  {`Сбросить все (${allFiltersCount})`}
                </span>
              </Desktop>
            </Fragment>
          )}
          <div className={styles.button}>
            <Button
              type='limeGradient'
              radius='5px'
              onClick={e => navigateToNewUrl(e, true)}
            >
              <span className='title'>
                <Count
                  formType={formType}
                  formState={formState}
                  isLcv={isLcv}
                  isMoto={isMoto}
                  ranges={ranges}
                  isDesktop={isDesktop}
                />
              </span>
            </Button>
          </div>
        </div>
      </div>
    </ThemeSwitchWithColor>
  );
}

FullFormTopline.defaultProps = {
  formType: 'catalog',
};

FullFormTopline.propTypes = {
  theme: PropTypes.object,
  isDesktop: PropTypes.bool,
  navigateToNewUrl: PropTypes.func,
  toggleFullForm: PropTypes.func,
  resetAllForm: PropTypes.func,
  allFiltersCount: PropTypes.number,
  formType: PropTypes.oneOf(['catalog', 'offers']),
  formState: PropTypes.object,
  isLcv: PropTypes.bool,
  isMoto: PropTypes.bool,
  ranges: PropTypes.object,
};

export default withTheme(withBreakpoint(FullFormTopline));
