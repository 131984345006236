import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { withBreakpoint } from 'core/components/breakpoint';
import ContentBackground from 'core/components/ContentBackground';

import Navigation from 'site/components/Navigation';
import Divider from 'site/components/Divider';
import PageLayoutService from 'site/components/PageLayoutService';

import modelPropTypes, { carReleaseAttributes } from 'site/utils/prop-types/model';

function ReleaseInnerPages({ isMobile, release, children, isBrandTagExists }) {
  return (
    <Fragment>
      <ContentBackground>
        <Navigation release={release} isBrandTagExists={isBrandTagExists} />
        <Divider />
      </ContentBackground>
      <PageLayoutService bgContentList={isMobile}>{children}</PageLayoutService>
    </Fragment>
  );
}

ReleaseInnerPages.propTypes = {
  release: modelPropTypes(carReleaseAttributes),
  isMobile: PropTypes.bool,
  isBrandTagExists: PropTypes.bool,
};

export default withBreakpoint(ReleaseInnerPages);
