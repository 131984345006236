import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { renderError } from 'core/utils/app-status-helper';
import { denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';

import Page from 'core/components/Page';
import PageLoader from 'core/components/Loader/PageLoader';

import { servicesApi } from 'site/api/definitions/services';

import ReleaseBoroda from 'site/components/ReleaseBoroda';
import { ReleaseInnerPages } from 'site/components/Wrappers';
import PhotoAndVideo from 'site/components/PhotoAndVideo';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';

import { brandTagExistsQuery } from 'site/queries/brandTagExists';


export default function ReleasePhoto(props) {
  const {
    match: {
      params: {
        brand: brandSlug,
        model: modelSlug,
        generation: generationSlug,
        body: bodySlug,
      },
    },
  } = props;

  const results = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', 'getReleases', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'filter[body]': bodySlug,
          include: 'car_brand,car_feature',
          limit: 1,
          'relations[car_release]': 'brand,features',
          'attributes[car_release]': 'base,image,media,seo,extended',
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .then(result => denormalizeData(result)[0])
          .then(rejectEmptyResult)
          .catch(err => renderError(err, history)),
      }, {
        queryKey: ['servicesApi', 'getReleasesPhotoSeo', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'filter[submodel]': bodySlug,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      },
      brandTagExistsQuery(props),
    ],
  });

  const {
    data: [
      release = {},
      seo = {},
      isBrandTagExists,
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  if (resultsAreLoading) return <PageLoader />;

  return (
    <Page title={seo?.title} description={seo?.description}>
      <ReleaseInnerPages release={release} isBrandTagExists={isBrandTagExists}>
        <PhotoAndVideo release={release} />
        <ReleaseBoroda release={release} />
      </ReleaseInnerPages>
    </Page>
  );
}

ReleasePhoto.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
      body: PropTypes.string,
    }),
  }).isRequired,
};
