import PropTypes from 'prop-types';

import { resolve } from 'core/utils/env';

import useRequest from 'core/hooks/useRequest';

import { topicQuery } from 'core/queries/topic';

import InfiniteTopic from 'core/components/InfiniteTopic';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import { checkScrollToSiteInTopic } from 'core/components/ScrollToSite';
import Topic from 'core/components/Topic';
import GameCompare from 'core/components/GameCompare';
import { isCompareTopic } from 'core/components/GameCompare/utils';

import { isGameCompare } from 'site/utils';

import Boroda from 'site/components/Boroda';
import Sponsored from 'site/components/Ads/Sponsored';

import TopicContent from './TopicContent';
import TopicHeader from './TopicHeader';
import TopicFooter from './TopicFooter';
import TopicWrapper from './TopicWrapper';
import TopicSkeleton from './skeleton';


function topicRenderer(topic, enhancedTopic) {
  const {
    content,
    infinityIndex,
  } = enhancedTopic;

  const isScrollToSite = checkScrollToSiteInTopic(enhancedTopic);

  const {
    attributes: {
      is_premium: isPremium,
      topic_type: topicType,
    },
  } = content;

  const isGameComparePage = isCompareTopic(topicType);

  return (
    <TopicWrapper
      content={content}
      infinityIndex={infinityIndex}
    >
      {!isPremium &&
        <TopicHeader content={content} isGameCompare={isGameComparePage} />
      }
      {!isGameComparePage && <TopicContent content={content} />}
      <Sponsored />
      {isGameComparePage && <GameCompare content={content} />}
      {(!isGameComparePage && !isPremium) && (
        <TopicFooter content={content} hideSuperFooter={isScrollToSite} />
      )}
      {!isScrollToSite && !isGameComparePage && (
        <Boroda infinityIndex={infinityIndex} isTopicPage />
      )}
    </TopicWrapper>
  );
}

function TopicPage(props) {
  const { data, isLoading } = useRequest(topicQuery(props));

  if (isLoading) return <TopicSkeleton />;

  const { isDesktop, location } = props;
  const isGameComparePage = isGameCompare(location.pathname);

  if (isGameComparePage) {
    return (
      <Topic content={data}>
        {topicRenderer}
      </Topic>
    );
  }

  return (
    <InfiniteTopic
      content={data}
      rcmBlockId={resolve({
        '*': 'cee2fcd7b22b488585d5e78ef5cb0659',
        'production': isDesktop ? '307f344086cf455db92b0e6ab02816b1' : '9ae56b2cbfae4fe5ac8914bf1b8f5858',
      })}
    >
      {topicRenderer}
    </InfiniteTopic>
  );
}

TopicPage.propTypes = {
  isDesktop: PropTypes.bool,
  location: PropTypes.object,
};

export default withBreakpoint(TopicPage);
