import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import Feed from 'core/components/Feed';
import PageLoader from 'core/components/Loader/PageLoader';

import withTheme from 'core/components/theme';

import resolveRelationships from 'core/utils/relationships';
import { denormalizeData } from 'core/utils/api';

import { servicesApi } from 'site/api/definitions/services';

import { Indent } from 'site/components/Wrappers';

import CardBrandOffers from 'site/cardsService/CardBrandOffers';
import CardBrandOffersSimple from 'site/cardsService/CardBrandOffersSimple';

import styles from './index.styl';

const TOP_LINE_LIMIT = 5;
const statRelationships = resolveRelationships(['brand'], {}, {
  brand: {
    attributes: {},
  },
});


function OffersBrands(props) {
  const {
    geoSlug,
    theme: {
      colors: {
        content: colorContent,
      },
    },
  } = props;

  const {
    data: offers = [],
    isLoading: offersAreLoading,
  } = useRequest({
    queryKey: ['servicesApi', 'getBrandOffersStat', {
      'filter[geo]': geoSlug,
      'relations[brand_offers_stat]': 'brand',
      'attributes[car_brand]': ['base', 'image'],
      include: 'car_brand',
      sort: '-count',
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    select: denormalizeData,
    enabled: process.env.BROWSER_RUNTIME,
  });

  if (offersAreLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
        <PageLoader />
      </div>
    );
  }

  if (!offers?.length) return null;

  const topList = offers.slice(0, TOP_LINE_LIMIT);
  const othersList = offers
    .slice(TOP_LINE_LIMIT)
    .sort(statsSorter);

  return (
    <div className={styles.offersBrands}>
      <style jsx>{`
        .${styles.offersBrands}
          background ${colorContent}
      `}</style>
      <Feed
        content={topList}
        card={({ ...cardProps }) => <CardBrandOffers fullHeight {...cardProps} />}
        columns={topList.length}
        interitemSpacing={20}
        grid
      />
      <Indent bottom={50} />
      <div className={styles.othersList}>
        {othersList.map((brandStats, i) => (
          <Indent key={i} bottom={10}>
            <CardBrandOffersSimple content={brandStats} />
          </Indent>
        ))}
      </div>
    </div>
  );
}

OffersBrands.propTypes = {
  geoSlug: PropTypes.string,
  theme: PropTypes.object,
};

function statsSorter(statA, statB) {
  const brandAName = statRelationships(statA).brand.name.toLowerCase();
  const brandBName = statRelationships(statB).brand.name.toLowerCase();

  if (brandAName < brandBName) return -1;
  if (brandAName > brandBName) return 1;
  return 0;
}

export default withTheme(OffersBrands);
