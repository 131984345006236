import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { Helmet } from 'core/libs/helmet';

import resolveRelationships from 'core/utils/relationships';

import { withBreakpoint, Mobile } from 'core/components/breakpoint';
import PageLoader from 'core/components/Loader/PageLoader';
import withQueryClient from 'core/components/withQueryClient';

import Specifications from 'site/components/Specifications';
import CustomLink from 'site/components/CustomLink';
import CostList from 'site/components/CostList';
import TitleBackward from 'site/components/TitleBackward';

import { Indent } from 'site/components/Wrappers';

import ConfiguratorProvider from 'site/components/ConfiguratorProvider';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import { processLcvUrlPrefix } from 'site/utils';

import Options from './options';
import Properties from './properties';
import ModificationLayout from './layout';
import useModificationContent from './useModificationContent';


const requiredPayloadImports = ['release'];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  attributes: {},
});

function ModificationPage(props) {
  const {
    modification,
    modificationSeo,
    comparisons,
    isLoading,
  } = useModificationContent(props);

  const {
    isMobile,
    match: {
      params: {
        lcv: lcvSlug,
        brand: brandSlug,
        model: modelSlug,
        generation: generationSlug,
        body: bodySlug,
        modification: modificationSlug,
        property: propertySlug,
      },
    },
  } = props;

  const {
    release: {
      name,
    },
  } = relationships(modification);

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const isPropertyPage = !!propertySlug;
  const lcvUrlPrefix = processLcvUrlPrefix(Boolean(lcvSlug));
  const pageBaseUrl = `${lcvUrlPrefix}/${brandSlug}/${modelSlug}/${generationSlug}/${bodySlug}/${modificationSlug}`;
  const backUrl = `${lcvUrlPrefix}/${brandSlug}/${modelSlug}/${generationSlug}/${bodySlug}`;

  const {
    have_options: haveOptions,
  } = modification?.attributes || {};

  return (
    <Fragment>
      <Helmet>
        <title>{modificationSeo?.title}</title>
        <meta name='description' content={modificationSeo?.description} />
      </Helmet>
      {isLoading && <PageLoader />}
      {!isLoading && (
        <ConfiguratorProvider modifications={[modification]}>
          <ModificationLayout comparisons={comparisons} modification={modification}>
            <Mobile>
              <TitleBackward title={name} url={backUrl} />
              <Indent bottom={vertical} />
            </Mobile>
            <CostList modification={modification} />
            <Indent bottom={VERTICAL_INDENT} />
            <Specifications modification={modification} />
            <Indent bottom={VERTICAL_INDENT} />
            {haveOptions && (
              <Indent bottom={VERTICAL_INDENT}>
                {isPropertyPage
                  ? (
                    <CustomLink to={pageBaseUrl}>
                      Показать комплектации и опции
                    </CustomLink>
                  ) : (
                    <CustomLink to={`${pageBaseUrl}/property`}>
                      Показать все тех. характеристики
                    </CustomLink>
                  )
                }
              </Indent>
            )}
            {isPropertyPage
              ? <Properties modification={modification} />
              : <Options modification={modification} />
            }
          </ModificationLayout>
        </ConfiguratorProvider>
      )}
    </Fragment>
  );
}

ModificationPage.propTypes = {
  queryClient: PropTypes.object,
  isMobile: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      lcv: PropTypes.string,
      brand: PropTypes.string,
      model: PropTypes.string,
      generation: PropTypes.string,
      body: PropTypes.string,
      modification: PropTypes.string,
      property: PropTypes.string,
    }),
  }).isRequired,
};

export default withBreakpoint(withQueryClient(ModificationPage));
