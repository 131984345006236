import { Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import CommentsPreview from 'core/components/CommentsPreview';
import Page from 'core/components/Page';
import PageLoader from 'core/components/Loader/PageLoader';
import Scroller from 'core/components/Scroller';
import { Desktop, Mobile, withBreakpoint } from 'core/components/breakpoint';
import { TopicContentMaxWidth, Indent, NegativeMobile } from 'core/components/Wrappers';

import withTheme from 'core/components/theme';

import themePropTypes from 'core/utils/prop-types/theme';

import Divider from 'site/components/Divider';
import TitleBackward from 'site/components/TitleBackward';
import SocialShare from 'site/components/SocialShare';

import { CoverImage, CoverGallery } from './covers';
import ReviewTable from './ReviewTable/';
import Info from './Info/';
import MoreReviews from './MoreReviews';

import { REVIEW_COMMENTS_ID_PREFIX, VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import useReviewContent from './useReviewContent';
import styles from './index.styl';


function ReviewPage(props) {
  const {
    reviewData,
    reviews,
    reviewSeo,
    isLoading,
  } = useReviewContent(props);

  const {
    theme,
    isMobile,
  } = props;

  const {
    review,
  } = reviewData || {};

  const {
    brand,
    model,
  } = props.match.params;

  const {
    url,
    name,
    slug: qutoId,
    text: reviewText,
    image,
    photogallery,
  } = review?.attributes || {};

  const imageCount = photogallery?.attributes?.image_count;
  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Page title={reviewSeo?.title} description={reviewSeo?.description}>
      <style jsx>{`
        .date
          color ${theme.colors.primary400}

        .title
          font-family ${theme.fonts.display}

        .cover
          background ${theme.colors.content}
          box-shadow ${isMobile ? 'none' : `0 2px 5px 0 ${theme.colors.primary100}`}
      `}</style>
      {isLoading && <PageLoader />}
      {!isLoading && (
        <>
          <TitleBackward
            url={`/review/${brand}/${model}`}
            title={name}
          />
          <Indent bottom={28} />
          <SocialShare topicLink={url} />
          <Indent bottom={20} />
          <NegativeMobile>
            {image && imageCount === 1 && <CoverImage image={image} />}
            {+imageCount > 1 && <CoverGallery photoGallery={photogallery} />}
          </NegativeMobile>
          <div className={cx(styles.cover, 'cover')}>
            <div className={styles.info}>
              <Desktop>
                <Info review={review} />
                <Divider top={22} bottom={28} />
              </Desktop>
              <Mobile>
                <NegativeMobile>
                  <Scroller>
                    <Info review={review} />
                  </Scroller>
                  <Divider top={14} bottom={20} />
                </NegativeMobile>
              </Mobile>
              <ReviewTable review={review} />
            </div>
          </div>
          <Mobile>
            <Divider top={26} bottom={20} />
          </Mobile>
          <TopicContentMaxWidth>
            <Indent bottom={20} />
            {reviewText.split('\n').map((item, i) => (
              <p key={i}>{item}</p>
            ))}
            {reviews.length > 0 && (
              <Fragment>
                <Desktop>
                  <Divider top={26} bottom={20} />
                </Desktop>
                <MoreReviews reviews={reviews} />
              </Fragment>
            )}
            <Indent bottom={20} />
            <CommentsPreview
              xid={REVIEW_COMMENTS_ID_PREFIX + qutoId}
              commentsUrl={`${url}/comments`}
            />
          </TopicContentMaxWidth>
          <Indent bottom={vertical} />
        </>
      )}
    </Page>
  );
}

ReviewPage.propTypes = {
  isMobile: PropTypes.bool,
  match: PropTypes.object.isRequired,
  theme: themePropTypes(`{
    colors: {
      primary100,
      primary400,
      content,
    },
  }`),
};

export default withBreakpoint(withTheme(ReviewPage));
