import { createContext, useState, useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { uid } from 'uid';
import { normalize, schema } from 'normalizr';

import { SessionStorage } from 'core/decorators';

import withQueryClient from 'core/components/withQueryClient';

import { servicesApi } from 'site/api/definitions/services';

import { GeoContext } from 'site/components/GeoContext';

import modelPropTypes, { carModificationAttributes } from 'site/utils/prop-types/model';


export const ConfiguratorContext = createContext({});

const optionSheme = new schema.Entity('options');
const subgroupSheme = new schema.Entity('subgroups', { options: [optionSheme] });
const groupSheme = new schema.Entity('groups');
groupSheme.define({ options: [optionSheme], subgroups: [subgroupSheme] });
const shemeOfOptions = new schema.Array(groupSheme);

const packageSheme = new schema.Entity('packages');
packageSheme.define({ packages: [packageSheme] });
const shemeOfPackages = new schema.Array(packageSheme);

const getStorageName = id => `configurator_${id}`;

function generateUID(id) {
  const storageName = getStorageName(id);

  if (!SessionStorage.getItem(storageName)) {
    SessionStorage.setItem(storageName, uid());
  }
}

/**
 * Собираем опции/пакеты для каждой модификации.
 * Опции на странице сравнения приходят нормализованными
 * (из-за того что дерево строится плоским).
 * Для страницы конфигуратора нормализуем вручную
 * (из-за того что дерево имеет сложную структуру).
 */
function generateStoreOptions(modification, merged) {
  const {
    attributes: {
      options = {},
      packages = {},
    },
  } = modification;

  const isCompare = !!merged;

  if (isCompare) {
    const normalizedOptions = normalize(merged.options, shemeOfOptions);
    const { groups, subgroups } = normalizedOptions.entities;

    return {
      id: modification.id,
      options,
      packages,
      subgroups,
      groups,
    };
  }

  const normalizedOptions = normalize(options, shemeOfOptions);
  const normalizedPackages = normalize(packages, shemeOfPackages);

  return {
    id: modification.id,
    ...normalizedOptions.entities,
    ...normalizedPackages.entities,
  };
}

/**
 * Провайдер предоставляет данные и методы для работы с конфигуратором.
 * Данные о конфигурациях хранятся в хэш-таблице, с ключом по id модификации.
 *
 * Используется на страницах:
 * + конфигуратор - модификация одна;
 * + сравнение - модификаций сколько угодно.
 *
 * removeConfiguration - кодлбек удаляющий данные о модификации из контекста
 * getConfiguration - кодлбек делающий запрос на ручку `/configurator`.
 * Ответ ConfiguratorResponseOption сохраняется в соответсвующую ячейку по id модификации.
 */

function ConfiguratorProvider(props) {
  const {
    children,
    modifications,
    merged,
    queryClient,
  } = props;

  const [configurations, setConfigurations] = useState({});
  const { geoSlug } = useContext(GeoContext);

  const configurationRequest = useCallback((modificationId, optionRequestParams = {}) => {
    generateUID(modificationId);
    queryClient
      .fetchQuery({
        queryKey: ['servicesApi', 'getCatalogConfigurator', {
          modification_uuid: modificationId,
          geo: geoSlug,
          key: SessionStorage.getItem(getStorageName(modificationId)),
          ...optionRequestParams,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
      })
      .then(configuration => {
        const currentModification = modifications.find(({ id }) => modificationId === id);
        const storeOptions = generateStoreOptions(currentModification, merged);
        setConfigurations(prevConfigurations => ({
          ...prevConfigurations,
          [modificationId]: { configuration, ...storeOptions },
        }));
      });
  }, [queryClient, geoSlug, modifications, merged]);

  useEffect(() => {
    modifications.forEach(({ id: modId }) => configurationRequest(modId));
  }, [modifications, configurationRequest]);

  return (
    <ConfiguratorContext.Provider
      value={{
        getConfiguration: configurationRequest,
        removeConfiguration: id => {
          setConfigurations(prevConfigurations => {
            const {
              [id]: configurationToDelete, // eslint-disable-line
              ...otherConfigurations
            } = prevConfigurations;

            return { ...otherConfigurations };
          });
        },
        ...configurations,
      }}
    >
      {children}
    </ConfiguratorContext.Provider>
  );
}

ConfiguratorProvider.propTypes = {
  modifications: PropTypes.arrayOf(modelPropTypes(carModificationAttributes)),
  /**
   * Опций используемые в сравнении
   */
  merged: PropTypes.object,
  queryClient: PropTypes.object,
};

export default withQueryClient(ConfiguratorProvider);
