import PropTypes from 'prop-types';

import H1 from 'core/components/H1';
import Feed from 'core/components/Feed';
import AdWrapper from 'core/components/Ad/AdWrapper';
import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';
import PageLoader from 'core/components/Loader/PageLoader';

import ListSortPage from 'site/components/ListSortPage';
import PageLayoutService from 'site/components/PageLayoutService';
import { Context } from 'site/components/Ads/desktop';
import { Listing1 } from 'site/components/Ads/mobile';
import {
  ViewportHeightWrap,
  Indent,
} from 'site/components/Wrappers';

import CardServiceReview1 from 'site/cardsService/CardServiceReview1';
import CardServiceReview2 from 'site/cardsService/CardServiceReview2';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  reviewSortFilters,
} from 'site/constants';

import useReviewsContent, { REVIEWS_LIMIT } from './useReviewsContent';


function Reviews(props) {
  const {
    reviews,
    reviewsSeo,
    seoIsLoading,
    isLoading,
  } = useReviewsContent(props);

  if (seoIsLoading) return <PageLoader />;

  const {
    isMobile,
    match,
  } = props;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const {
    brand: brandSlug,
    model: modelSlug,
  } = match.params;

  const feedProps = {
    grid: true,
    interitemSpacing: isMobile ? 14 : 20,
    card: isMobile ? CardServiceReview1 : CardServiceReview2,
  };

  function findNameByType(type, slug) {
    if (!slug) return '';

    const foundItem = reviews?.included?.find(item => item.type === type);

    return foundItem?.attributes?.name;
  }

  const brandName = findNameByType('car_brand', brandSlug);
  const modelName = findNameByType('car_model', modelSlug);

  const titleText = `Отзывы об автомобилях ${brandName || ''} ${modelName || ''}`;

  return (
    <PageLayoutService>
      <ViewportHeightWrap>
        <H1 dataQa='page-title'>{titleText}</H1>
        <Indent bottom={vertical} />
        <ListSortPage
          title={reviewsSeo?.title}
          description={reviewsSeo?.description}
          entitiesData={reviews}
          limit={REVIEWS_LIMIT}
          sortHeader={{
            type: 1,
            filters: reviewSortFilters,
          }}
          isLoading={isLoading}
        >
          {({ content: denormalizedReviews }) => {
            if (isLoading) return <PageLoader />;

            const firstGroup = denormalizedReviews.slice(0, 4);
            const secondGroup = denormalizedReviews.slice(4);
            return (
              <AdWrapper top={vertical} bottom={vertical}>
                <Feed
                  content={firstGroup}
                  {...feedProps}
                />
                <Desktop>
                  <Context />
                </Desktop>
                <Mobile>
                  <Listing1 />
                </Mobile>
                <Feed
                  content={secondGroup}
                  {...feedProps}
                />
                <Indent bottom={vertical} />
              </AdWrapper>
            );
          }}
        </ListSortPage>
      </ViewportHeightWrap>
      <Indent bottom={vertical} />
    </PageLayoutService>
  );
}

Reviews.propTypes = {
  isMobile: PropTypes.bool,
  match: PropTypes.object.isRequired,
};

export default withBreakpoint(Reviews);
