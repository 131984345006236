import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { topicsQuery } from 'core/queries/topics';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import themePropTypes from 'core/utils/prop-types/theme';
import { filterRequiredParams } from 'core/utils/api';

import bindProps from 'core/components/bindProps';
import withTheme from 'core/components/theme';
import { withBreakpoint } from 'core/components/breakpoint';

import H3 from 'core/components/H3';
import Feed from 'core/components/Feed';
import Link from 'core/components/Link';
import { Block, Section } from 'core/components/Grid';
import PageLoader from 'core/components/Loader/PageLoader';

import ButtonWithIcon from 'core/components/ButtonWithIcon';
import { Indent, LimeGradientBg } from 'site/components/Wrappers';
import ArrowRightSm from 'site/icons/ArrowRightSm';
import ButtonTextWrap from 'site/components/Wrappers/ButtonTextWrap';
import { SIDE_INDENT_MOBILE } from 'site/constants';

import styles from './index.styl';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';


const dataQaSmallCard = 'small-card-auto-news';
const Card1Type9 = bindProps({ type: 9, dataQa: dataQaSmallCard  })(Card1);

function BorodaService({ isMobile, theme }) {
  const { data: content = [], isLoading } = useRequest(topicsQuery({
    limit: 6,
    include: filterRequiredParams([Card1, Card2], 'include'),
    fields: filterRequiredParams([Card1, Card2], 'fields'),
    renderError: () => [],
  }, { enabled: process.env.BROWSER_RUNTIME }));

  const horisontal = isMobile ? SIDE_INDENT_MOBILE : 20;

  const first = content[0];
  const second = content.slice(1, 6);

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.gradient}
          padding-left ${horisontal}px
          padding-right ${horisontal}px
          margin 0 ${-horisontal}px

        .${styles.separator}
          :global(.desktop) &
            border-right 1px dashed ${theme.colors.divider}
      `}</style>
    </scope>
  );

  return (
    <LimeGradientBg className={scope.wrapClassNames(styles.gradient)} data-qa='auto-news'>
      <H3>Автомобильные новости</H3>
      <Indent bottom={15} />
      {isLoading && <PageLoader />}
      {!isLoading && (
        <Section>
          <Block width={8} mobile={12}>
            <div className={scope.wrapClassNames(styles.separator)}>
              <Card2
                content={first}
                type={isMobile ? 6 : 3}
                dataQa='main-card-auto-news'
              />
            </div>
          </Block>
          <Block width={4} mobile={12}>
            {second && (
              <Indent top={isMobile ? 10 : -10}>
                <Feed
                  card={Card1Type9}
                  interitemSpacing={0}
                  content={second}
                />
                <Link
                  type='secondary'
                  to='/journal'
                  className={styles.journalLink}
                >
                  <ButtonWithIcon
                    icon={ArrowRightSm}
                    iconPosition='right'
                    minWidth='100%'
                    size='small'
                  >
                    <ButtonTextWrap>Все новости</ButtonTextWrap>
                  </ButtonWithIcon>
                </Link>
              </Indent>
            )}
          </Block>
        </Section>
      )}
      <scope.styles />
    </LimeGradientBg>
  );
}

BorodaService.propTypes = {
  isMobile: PropTypes.bool,
  breakpoint: breakpointPropTypes(),
  theme: themePropTypes(`{
    colors: {
      divider,
    }
  }`),
};

export default withTheme(withBreakpoint(BorodaService));
