import { denormalizeData } from 'core/utils/api';

import { servicesApi } from 'site/api/definitions/services';


const COMPARISON_LIMIT = 4;


export const comparisonsQuery = (queryParams, config = {}) => {
  const { id, bestChoice } = queryParams || {};

  let methodSlug;
  let apiParams;

  const { slug: editorsChoice, type: vehicleType } = bestChoice?.attributes || {};

  if (vehicleType === 'moto') {
    methodSlug = 'getMotoCompareTop';
    apiParams = {
      'attributes[moto_release]': 'base,image,extended,',
      limit: COMPARISON_LIMIT,
    };
  } else {
    methodSlug = 'getCompareTop';
    apiParams = {
      'attributes[car_release]': 'base,image',
      'attributes[car_modification]': 'base,extended',
      'relations[car_modification]': 'release',
      modification_uuid: id,
      include: 'car_release',
      limit: COMPARISON_LIMIT,
    };
  }

  if (editorsChoice) {
    apiParams['filter[editors_choice]'] = editorsChoice;
  }

  return {
    ...config,
    queryKey: ['servicesApi', methodSlug, apiParams],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
      .then(({ data, included }) => (
        /**
         * Т. к. ответе приходят пары наиболее частых сравнений модификаци,
         * используем denormalizeData для каждой модификации отдельно.
         * Здесь же создаём сущность, которую можно передавать в Feed.
         */
        data.map((pair, index) => ({
          id: index,
          items: pair.map(comparison => denormalizeData({ data: comparison, included })),
        }))
      )),
  };
};
