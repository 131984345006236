import { denormalizeData } from 'core/utils/api';

import { servicesApi } from 'site/api/definitions/services';

import { isMotoPath } from 'site/utils';


export const brandsQuery = (queryParams) => {
  const { location: { pathname } } = queryParams || {};

  const isMoto = isMotoPath(pathname);
  const vehiclePrefix = isMoto ? 'moto' : 'car';

  return {
    queryKey: ['servicesApi', isMoto ? 'getMotoBrands' : 'getBrands', {
      is_popular: true,
      [`attributes[${vehiclePrefix}_brand]`]: 'base,image',
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    select: denormalizeData,
  };
};
