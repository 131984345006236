import { useState } from 'react';

import useRequest from 'core/hooks/useRequest';

import { denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';

import { generateSelectOptions } from 'site/utils/forms';

import { servicesApi } from 'site/api/definitions/services';

import { RANGES } from './constants';


const getFormOptions = (dictionaries = {}) => ({
  filteredBodies: generateSelectOptions(dictionaries.body, { value: 'attributes.slug' }),
  filteredConditions: generateSelectOptions(dictionaries.condition, { label: 'title', value: 'value' }),
});

const EMPTY_FORM_DATA = {
  formOptions: getFormOptions(),
  ranges: RANGES,
};

export default function withContent(Component) {
  return function WrappedComponent(props) {
    const [brand, setBrand] = useState(null);

    const results = useRequest({
      queries: [
        {
          queryKey: ['servicesApi', 'getBrands'],
          queryFn: ({ queryKey: [, method] }) => servicesApi[method](),
          select: denormalizeData,
          enabled: process.env.BROWSER_RUNTIME,
        }, {
          queryKey: ['servicesApi', 'getOffersDictionaries'],
          queryFn: ({ queryKey: [, method] }) => servicesApi[method]()
            .then(data => ({
              formOptions: getFormOptions(data),
              ranges: { ...RANGES, ...data?.ranges },
            })),
          enabled: process.env.BROWSER_RUNTIME,
        },
      ],
    });

    const {
      data: [
        brands = [],
        formData = EMPTY_FORM_DATA,
      ],
    } = processQueryResults(results);

    const { data: models = [] } = useRequest({
      queryKey: ['servicesApi', 'getModels', {
        'filter[brand]': brand?.value,
        'relations[car_model]': 'brand',
        include: 'car_brand',
      }],
      queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
      select: denormalizeData,
      enabled: process.env.BROWSER_RUNTIME && !!brand?.value,
    });

    return (
      <Component
        {...props}
        brand={brand}
        brands={brands}
        models={models}
        formData={formData}
        handleBrandChange={setBrand}
      />
    );
  };
}
