import PropTypes from 'prop-types';

import { withRouter } from 'core/libs/router';

import Feed from 'core/components/Feed';
import AdWrapper from 'core/components/Ad/AdWrapper';

import { withNonPureBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';

import ListSortPage from 'site/components/ListSortPage';
import {
  ViewportHeightWrap,
  Indent,
} from 'site/components/Wrappers';

import modelPropTypes, {
  offerAttributes,
} from 'site/utils/prop-types/model';

import {
  offersSortFilters,
  OFFERS_LIMIT,
  OFFERS_LIMIT_MOBILE,
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import CardServiceOffer1 from 'site/cardsService/CardServiceOffer1';
import CardServiceOffer2 from 'site/cardsService/CardServiceOffer2';

import { Context } from 'site/components/Ads/desktop';
import { ListingSpec } from 'site/components/Ads/mobile';


function OffersList(props) {
  const {
    offersData,
    isLoading,
    isMobile,
  } = props;

  const verticalIndent = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const feedProps = {
    grid: true,
    interitemSpacing: isMobile ? 7 : 10,
    card: isMobile ? CardServiceOffer1 : CardServiceOffer2,
  };

  return (
    <ViewportHeightWrap>
      <ListSortPage
        entitiesData={offersData}
        limit={isMobile ? OFFERS_LIMIT_MOBILE : OFFERS_LIMIT}
        sortHeader={{
          type: 3,
          filters: offersSortFilters,
          defaultOrder: 'asc',
        }}
        nothingFoundTitle='Нет автомобилей в продаже :('
        isLoading={isLoading}
      >
        {({ content: offers = [] }) => {
          const firstGroup = offers.slice(0, 5);
          const secondGroup = offers.slice(5);
          return (
            <AdWrapper top={verticalIndent} bottom={verticalIndent}>
              <Feed
                content={firstGroup}
                {...feedProps}
              />
              <Desktop>
                <Context />
              </Desktop>
              <Mobile>
                <ListingSpec />
              </Mobile>
              <Feed
                content={secondGroup}
                {...feedProps}
              />
              <Indent bottom={verticalIndent} />
            </AdWrapper>
          );
        }}
      </ListSortPage>
    </ViewportHeightWrap>
  );
}

OffersList.propTypes = {
  offersData: PropTypes.shape({
    data: PropTypes.arrayOf(modelPropTypes(offerAttributes)),
    meta: PropTypes.shape({
      filtered_count: PropTypes.number,
    }),
  }),
  limit: PropTypes.number,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.shape({
      geo: PropTypes.string.isRequired,
      brand: PropTypes.string,
      dealer_slug: PropTypes.string.isRequired,
    }),
  }),
};

export default withRouter(withNonPureBreakpoint(OffersList));
