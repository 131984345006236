import { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import cx from 'classnames';
import Select from 'react-select';

import { debounce } from 'core/libs/lodash';
import { withRouter } from 'core/libs/router';
import { compose } from 'core/libs/recompose';

import { withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';
import PageLoader from 'core/components/Loader/PageLoader';

import themePropTypes from 'core/utils/prop-types/theme';

import H3 from 'core/components/H3';
import Checkbox from 'core/components/Checkbox';
import { Block, Section } from 'core/components/Grid';

import { Indent } from 'site/components/Wrappers';
import Input from 'site/components/Input';

import modelPropTypes, { geoAttributes } from 'site/utils/prop-types/model';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  SIDE_INDENT,
  NUMBER_OF_YEARS,
} from 'site/constants';
import { expensesDic } from 'site/dictionaries';

import ExpensesSkeleton from './Skeleton';
import withContent from './withContent';

import styles from './index.styl';


const DEFAULT_VALUE_ARRAY = new Array(NUMBER_OF_YEARS).fill(0);

class Expenses extends PureComponent {
  static propTypes = {
    ownershipCost: PropTypes.object,
    run: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    geoUrl: PropTypes.string,
    geos: PropTypes.arrayOf(modelPropTypes(geoAttributes)),
    groupedOptions: PropTypes.array,
    onGeoChange: PropTypes.func,
    onRunChange: PropTypes.func,
    breakpoint: PropTypes.string,
    isMobile: PropTypes.bool,
    isLoading: PropTypes.bool,
    costIsLoading: PropTypes.bool,
    theme: themePropTypes(`{
      colors: {
        primary100,
        primary500,
        divider,
        contentList
      },
    }`),
  };

  constructor(props) {
    super(props);
  }

  state = {
    selectedExpenses: {
      depreciation: true,
      maintenance: true,
      fuel: true,
      transport_tax: true,
      osago: true,
      kasko: false,
    },
  };

  onRunChange = e => {
    const newValue = e.target.value.replace(/[^\d]/g, '');
    this.props.onRunChange(+newValue);
  };

  onGeoChange = debounce(value => {
    this.props.onGeoChange(value.attributes.url);
  });

  toggleExpense(expense) {
    this.setState({
      selectedExpenses: {
        ...this.state.selectedExpenses,
        [expense]: !this.state.selectedExpenses[expense],
      },
    });
  }

  renderExpenses(item) {
    const { theme, costIsLoading } = this.props;
    const itemChecked = this.state.selectedExpenses[item.title];

    return (
      <tr
        className={cx(
          'row',
          !itemChecked && styles.uncheckedExpense
        )}
        key={item.key}
      >
        <style jsx>{`
          .row
            td
              border-bottom-color ${theme.colors.divider}
      `}</style>
        <td>
          <Checkbox
            checked={itemChecked}
            onChange={() => { this.toggleExpense(item.title); }}
          >
            <span>{expensesDic[item.title]}</span>
          </Checkbox>
        </td>
        {costIsLoading && <ExpensesSkeleton />}
        {!costIsLoading && (
          item.expenses.map((yearExpense, i) => {
            return <td key={i}>{accounting.formatNumber(yearExpense)}</td>;
          })
        )}
      </tr>
    );
  }

  renderTableOfCosts(expenses) {
    const {
      run,
      theme,
      costIsLoading,
    } = this.props;

    if (run) {
      const totalByYears = [];

      for (const [key, value] of expenses) {
        if (this.state.selectedExpenses[key]) {
          value?.reduce((yearSum, currVal, i) => {
            yearSum[i] = yearSum[i] ? yearSum[i] + currVal : currVal;
            return yearSum;
          }, totalByYears);
        }
      }

      const total = totalByYears?.reduce((acc, curr)=>(acc + curr), 0);
      const avrPerYear = total / NUMBER_OF_YEARS;
      const avrPerKm = avrPerYear / (+run || 1);

      return (
        <Fragment>
          <style jsx>{`
          .row
            td
            th
              border-bottom-color ${theme.colors.divider}

            &:last-child
              td
                border-bottom-color ${theme.colors.primary100}

          .summaryRow
            &:before
              border-bottom 1px dashed ${theme.colors.divider}

          .name
          .value
            background ${theme.colors.contentList}

          .hint
            color ${theme.colors.primary500}
            font 13px/15px ${theme.fonts.text}
        `}</style>
          <div className={styles.expensesWrapper}>
            <table className={styles.expensesTable}>
              <tbody>
                <tr className='row'>
                  <th>Расходы на:</th>
                  <th>1 год</th>
                  <th>2 год</th>
                  <th>3 год</th>
                  <th>4 год</th>
                  <th>5 год</th>
                </tr>
                {Array.from(expenses, ([title, values], i) => {
                  return this.renderExpenses({ title: title, expenses: values?.length ? values : DEFAULT_VALUE_ARRAY, key: i });
                })}
                <tr>
                  <td>Итого</td>
                  {costIsLoading && <ExpensesSkeleton />}
                  {!costIsLoading && totalByYears?.map((yearTotal, i) => <td key={i}>{accounting.formatNumber(yearTotal)}</td>)}
                </tr>
              </tbody>
            </table>
          </div>
          <div className={styles.summary}>
            <div className={cx(styles.summaryRow, 'summaryRow')}>
              <div className={cx(styles.name, 'name')}>Всего за 5 лет</div>
              <div className={cx(styles.value, 'value')}>{accounting.formatMoney(total)}</div>
            </div>
            <div className={cx(styles.summaryRow, 'summaryRow')}>
              <div className={cx(styles.name, 'name')}>За 1 год (в среднем)</div>
              <div className={cx(styles.value, 'value')}>{accounting.formatMoney(avrPerYear, '₽/год')}</div>
            </div>
            <div className={cx(styles.summaryRow, 'summaryRow')}>
              <div className={cx(styles.name, 'name')}>За 1 км. пути</div>
              <div className={cx(styles.value, 'value')}>{accounting.formatMoney(avrPerKm, '₽/км')}</div>
            </div>
            <p className='hint'><sup>*</sup> Представленные данные носят ознакомительно-информационный характер и не являются публичной офертой</p>
          </div>
        </Fragment>
      );
    }
    return <p>Введите данные по годовому пробегу</p>;
  }

  render() {
    const {
      run,
      geoUrl,
      geos,
      groupedOptions,
      ownershipCost,
      isMobile,
      isLoading,
      breakpoint,
    } = this.props;

    if (isLoading) return <PageLoader />;

    const {
      depreciation,
      maintenance,
      fuel,
      transport_tax: transportTax,
      osago,
      kasko,
    } = ownershipCost;

    const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

    const expenses = new Map([
      ['depreciation', depreciation],
      ['maintenance', maintenance],
      ['fuel', fuel],
      ['transport_tax', transportTax],
      ['osago', osago],
      ['kasko', kasko],
    ]);

    return (
      <div className={styles[breakpoint]}>
        <H3 id='tco'>Стоимость владения<sup>*</sup></H3>
        <Indent bottom={vertical} />
        <Section>
          <Block width={12} desktop={4}>
            <Input
              value={accounting.formatNumber(run)}
              onChange={this.onRunChange}
              label='Годовой пробег (км)'
              placeholder='15 000'
              pattern='[0-9 ]*'
              maxLength={7}
            />
          </Block>
          <Block desktop={SIDE_INDENT + 'px'} mobile={12}>
            {isMobile && <Indent bottom={vertical} />}
          </Block>
          <Block width={12} desktop={4}>
            <Select
              options={groupedOptions}
              getOptionLabel={value => value.attributes.name}
              getOptionValue={value => value.attributes.url}
              value={geos.find(geo => geo.attributes.url === geoUrl)}
              onChange={this.onGeoChange}
            />
          </Block>
        </Section>
        <Indent bottom={vertical} />
        {this.renderTableOfCosts(expenses)}
      </div>
    );
  }
}

export default compose(
  withRouter,
  withContent,
  withTheme,
  withBreakpoint,
)(Expenses);
