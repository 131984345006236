import { useState, useRef, useCallback } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { scrollToElement } from 'core/utils/dom';

import { withBreakpoint, Mobile, Desktop } from 'core/components/breakpoint';

import Swipe, { Slide } from 'core/components/Swipe';
import Bullets from 'core/components/Bullets';

import { Indent } from 'site/components/Wrappers';

import Navigation from './Navigation';
import ShowMore from './ShowMore';
import CarReleaseImage from './CarReleaseImage';
import Arrows from 'core/components/Gallery/Arrows';

import styles from './index.styl';

import {
  SLIDE_HEIGHT_MOBILE,
  SLIDE_HEIGHT,
} from 'site/constants';


function CarReleaseGallery({ release, isMobile }) {
  const {
    attributes: {
      photogallery,
      images: {
        attributes: {
          images: galleryImages,
        },
      },
    },
  } = release;

  const containerEl = useRef(null);
  const swipeEl = useRef(null);

  const [activeIndex, setActiveIndex] = useState(0);

  const onActiveIndexChange = useCallback(({ index }) => setActiveIndex(index), []);
  const goToHandler = useCallback(index => swipeEl.current.goto(index), []);
  const focusHandler = useCallback(() => containerEl.current.focus({
    preventScroll: true,
  }), []);
  const scrollToGallery = () => scrollToElement(containerEl.current, 1 / 3);

  const nextHandler = useCallback(() => swipeEl.current.next(), []);
  const prevHandler = useCallback(() => swipeEl.current.prev(), []);


  const hasGallery = photogallery && photogallery.attributes.image_count > 0;

  return (
    <div
      ref={containerEl}
      onClick={scrollToGallery}
      className={cx(
        'gallery',
        'carReleaseGallery',
        styles.carReleaseGallery,
        isMobile && styles.mobile,
      )}
    >
      <div className={styles.imageContainer}>
        <Swipe
          onInteraction={focusHandler}
          ref={swipeEl}
          onActiveIndexChange={onActiveIndexChange}
        >
          {galleryImages.map((image, i) => {
            const { id: imageId } = image;

            return (
              <Slide width='100%' key={imageId}>
                <CarReleaseImage
                  image={image}
                  withLcpFixer={i === 0}
                />
              </Slide>
            );
          }).concat(
            hasGallery && (
              <Slide width='100%' key={galleryImages.length + '_showMore'}>
                <ShowMore
                  slideHeight={isMobile ? SLIDE_HEIGHT_MOBILE : SLIDE_HEIGHT}
                  goToHandler={goToHandler}
                  photogallery={photogallery}
                />
              </Slide>
            )
          )}
        </Swipe>
        {!isMobile && (activeIndex < galleryImages.length) && (
          <Arrows
            {...{
              hasExtraSlide: hasGallery,
              totalCount: galleryImages.length,
              activeIndex,
              prevHandler,
              nextHandler,
            }}
          />
        )}
      </div>

      <Desktop>
        <Indent left={36}>
          <Navigation
            activeIndex={activeIndex}
            galleryImages={galleryImages}
            focusHandler={focusHandler}
            goToHandler={goToHandler}
            hasGallery={hasGallery}
          />
        </Indent>
      </Desktop>

      <Mobile>
        <Indent top={10}>
          <Bullets
            currentIndex={activeIndex}
            total={galleryImages.length + 1}
            onChange={goToHandler}
          />
        </Indent>
      </Mobile>
    </div>
  );
}

CarReleaseGallery.propTypes = {
  /** Объект с параметрами релиза */
  release: PropTypes.object,
  /** @ignore */
  isMobile: PropTypes.bool,
};

const CarReleaseGalleryWithHOCs = withBreakpoint(CarReleaseGallery);
CarReleaseGalleryWithHOCs.displayName = 'CarReleaseGallery';

export default CarReleaseGalleryWithHOCs;

export { CarReleaseGallery as StorybookComponent };
