import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { renderError } from 'core/utils/app-status-helper';
import { processQueryResults } from 'core/utils/query';
import { denormalizeData } from 'core/utils/api';
import { capitalize } from 'core/utils/string-helper';
import resolveRelationships from 'core/utils/relationships';

import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';

import AdWrapper from 'core/components/Ad/AdWrapper';
import Page from 'core/components/Page';
import PageLoader from 'core/components/Loader/PageLoader';

import { servicesApi } from 'site/api/definitions/services';

import HtmlWithTheme from 'site/components/HtmlWithTheme';
import TitleCatalog from 'site/components/TitleCatalog';
import WhereToBuy from 'site/components/WhereToBuy';
import { Indent } from 'site/components/Wrappers';

import { SuperFooter } from 'site/components/Ads/desktop';
import { Listing1, ListingSpec } from 'site/components/Ads/mobile';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import Releases from './Releases';

import {
  buildSearchRequestParams,
} from 'site/utils';


const relationships = resolveRelationships(['brand', 'model'], {}, {
  brand: {
    data: {},
  },
});

function GenerationPage(props) {
  const {
    location: {
      search,
    },
    history,
    match: {
      params: {
        brand: brandSlug,
        model: modelSlug,
        generation: generationSlug,
      },
    },
    isMobile,
  } = props;

  const results = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', 'getGenerations', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'attributes[car_generation]': 'base,seo',
          include: 'car_brand,car_model',
          'relations[car_generation]': 'brand,model',
          'attributes[car_brand]': 'base,image',
          limit: 1,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .then(result => denormalizeData(result)[0])
          .then(rejectEmptyResult)
          .catch(err => renderError(err, history)),
      }, {
        queryKey: ['servicesApi', 'getCarGenerationSeo', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      }, {
        queryKey: ['servicesApi', 'getReleases', {
          ...buildSearchRequestParams(search),
          'attributes[car_release]': 'base,image,stats',
          'relations[car_release]': 'body',
          'include': 'car_submodel',
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      },
    ],
  });

  const {
    data: [
      generation = {},
      generationSeo = {},
      releases = [],
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  const {
    brand: {
      data: brand,
      name: brandName,
    },
    model: {
      name: modelName,
    },
  } = relationships(generation);

  const {
    name: generationName,
    seo_text: seoText,
  } = generation?.attributes || {};

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const title = `${capitalize(brandName)} ${modelName}, ${generationName}`;

  const {
    title: seoTitle,
    description: seoDescription,
  } = generationSeo || {};

  return (
    <Page title={seoTitle} description={seoDescription}>
      {resultsAreLoading && <PageLoader />}
      {!resultsAreLoading && (
        <AdWrapper bottom={vertical}>
          {!!brand && <TitleCatalog brand={brand} title={title} />}
          <Indent bottom={vertical} />

          <Releases releases={releases} />
          <Indent bottom={vertical} />

          <Desktop><SuperFooter /></Desktop>
          <Mobile><Listing1 /></Mobile>

          <WhereToBuy brandName={brandName} />
          <Indent bottom={vertical} />

          <HtmlWithTheme html={seoText} />
          <Indent bottom={vertical} />

          <Mobile><ListingSpec /></Mobile>
        </AdWrapper>
      )}
    </Page>
  );
}

GenerationPage.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,

  history: PropTypes.object,

  /** @ignore */
  location: PropTypes.shape({
    search: PropTypes.string,
  }),

  /** @ignore */
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string.isRequired,
      model: PropTypes.string.isRequired,
      generation: PropTypes.string.isRequired,
    }),
  }),
};

export default withBreakpoint(GenerationPage);
