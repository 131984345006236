import { Fragment } from 'react';

import useRequest from 'core/hooks/useRequest';

import { topicsQuery } from 'core/queries/topics';

import { SimpleLoader } from 'core/components/Loader';
import Texts from 'core/components/Texts';
import Feed from 'core/components/Feed';

import { Indent } from 'site/components/Wrappers';

import { Card1Type4 } from 'site/cards/Card1';


export default function CarNews() {
  const {
    data: content,
    isLoading: contentIsLoading,
  } = useRequest(
    topicsQuery({
      limit: 4,
      card: Card1Type4,
      renderError: () => [],
    })
  );

  if (contentIsLoading) return <SimpleLoader />;

  return (
    <Fragment>
      <Texts type='title3'>Автомобильные новости</Texts>
      <Indent bottom={5} />
      <Feed
        content={content}
        card={Card1Type4}
        interitemSpacing={0}
      />
    </Fragment>
  );
}
