import PropTypes from 'prop-types';


import useRequest from 'core/hooks/useRequest';

import { rawAuthorsQuery } from 'core/queries/authorsPage';

import H1 from 'core/components/H1';
import AuthorsPage from 'core/components/AuthorsPage';
import { withBreakpoint } from 'core/components/breakpoint';

import PageLayout from 'site/components/PageLayout';

import { Indent } from 'site/components/Wrappers';
import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


function Authors(props) {
  const vertical = props.isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const { data: rawAuthors, isLoading } = useRequest(rawAuthorsQuery(props));

  return (
    <PageLayout>
      <H1>Авторы</H1>
      <Indent bottom={vertical} />
      <AuthorsPage rawAuthors={rawAuthors} isLoading={isLoading} />
    </PageLayout>
  );
}

Authors.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Authors);
