import { bebop as bebopApi } from 'core/api/definitions/bebop';


export const brandTagExistsQuery = (queryParams) => {
  const { match } = queryParams || {};
  const brandSlug = match?.params?.brand;

  return {
    queryKey: ['bebopApi', 'getTag', { tag_slug: brandSlug }],
    queryFn: brandSlug
      ? ({ queryKey: [, method, params] }) => bebopApi[method](params)
        .then(() => true)
        .catch(() => false)
      : Promise.reject(false),
  };
};
