export const PATHNAME_FIELDS = ['geo', 'brand', 'model', 'generation'];

export const RANGES = {
  price: {
    min: 300000,
    max: 30000000,
  },
  power: {
    min: 10,
    max: 500,
  },
  acceleration: {
    min: 1,
    max: 10,
  },
  trunk_volume: {
    min: 100,
    max: 5000,
  },
  clearance: {
    min: 70,
    max: 280,
  },
  year: {
    min: 1980,
    max: new Date().getFullYear(),
  },
};

/**
 * Список значений для предустановленных опций в радиогруппе "Объем багажника"
 */
export const TRUNK_VOLUME_OPTIONS = [
  { label: 'Любой', value: { min: RANGES.trunk_volume.min, max: RANGES.trunk_volume.max } },
  { label: 'Крошечный (100—250 л.)', value: { min: 100, max: 250 } },
  { label: 'Обычный (250—400 л.)', value: { min: 250, max: 400 } },
  { label: 'Большой (400—550 л.)', value: { min: 400, max: 550 } },
  { label: 'Огромный (550+ л.)', value: { min: 551 } },
];

export const CLEARANCE_OPTIONS = [
  { label: 'Любой', value: { min: RANGES.clearance.min, max: RANGES.clearance.max } },
  { label: 'Больше 160 мм.', value: { min: 160, max: RANGES.clearance.max } },
  { label: 'Больше 200 мм.', value: { min: 200, max: RANGES.clearance.max } },
];

export const FULL_FILTER_FIELDS = [
  'dealer_type',
  'country',
  'class',
  'assembly',
  'power',
  'boost',
  'control',
  'acceleration',
  'trunk_volume',
  'clearance',
  'number_of_seats',
];

export const BASE_PATH = '/inventory';

export const DEPENDENCIES = {
  brand: {
    model: () => null,
    generation: () => null,
  },
  model: {
    generation: () => null,
  },
};
