import cx from 'classnames';
import PropTypes from 'prop-types';

import { resolveScopedStyles } from 'core/utils/styled-jsx';

import Image from 'core/components/Image';

import cardHOC from 'core/components/card';

import resolveRelationships from 'core/utils/relationships';
import relationshipsPropTypes, { imagePropTypes } from 'core/utils/prop-types/relationships';

import PremiumStory from 'site/icons/PremiumStory.svg';

import styles from './index.styl';

const relationships = resolveRelationships(
  ['image'],
  {},
  { image: { versions: {} } },
);

/**
 * Максимальная ширина изображения, чтобы не очень много и не мало.
 * @type {number}
 */
const IMAGE_WIDTH = 145;
const IMAGE_HEIGHT = 190;

/**
 * Базовая карточка для стори-топика.
 */
function StoryTopicCard(props) {
  const { content, theme } = props;

  const {
    is_premium: isPremium,
    headline,
    list_headline: listHeadline,
  } = content?.attributes || {};

  const {
    image: {
      versions,
    },
  } = relationships(content);

  const {
    colors: {
      primary,
      lime3,
      active1,
      white,
    },
    fonts: {
      text: textFont,
    },
  } = theme;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.premiumIcon}
          color ${active1}
      `}</style>
    </scope>
  );

  return (
    <div className={cx(styles.baseCard, isPremium && styles._isPremium)} data-qa='story-topic-card' >
      <style jsx>{`
        .${styles.headline}
          color ${white}
          font 700 12px/14px ${textFont}

          span
            .${styles._isPremium} &
              color ${primary}
              background ${lime3}
              font italic 700 12px/14px ${textFont}
      `}</style>
      <div className={styles.image}>
        <Image
          versions={versions}
          maxWidth={IMAGE_WIDTH}
          aspectRatio={IMAGE_WIDTH / IMAGE_HEIGHT}
          instant
        />
      </div>
      <div className={styles.content}>
        <div className={styles.headline}>
          {listHeadline || headline}
        </div>
      </div>
      {isPremium && <PremiumStory className={scope.wrapClassNames(styles.premiumIcon)} />}
      {isPremium && <scope.styles />}
    </div>
  );
}

StoryTopicCard.propTypes = {
  /** @ignore */
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  /** @ignore */
  theme: PropTypes.object,
};

const CardWithHOCs = cardHOC(StoryTopicCard);
CardWithHOCs.displayName = 'StoryTopicCard';

export default CardWithHOCs;

export { StoryTopicCard as StorybookComponent };
