import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { rawContentQuery } from 'core/queries/searchPage';

import SearchPage from 'core/components/SearchPage';
import ColumnLayout from 'core/components/ColumnLayout';

import { withBreakpoint } from 'core/components/breakpoint';

import { PageIndent } from 'core/components/Wrappers';
import InnerSideColumn from 'site/components/InnerSideColumn';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpec,
} from 'site/components/Ads/mobile';

import filters from './filters';

import { Card1Type4 } from 'site/cards/Card1';
import Card3 from 'site/cards/Card3';

/**
 * Результаты поиска на стейджах не будут соответствовать действительности,
 * потому что апишка поиска работает только с продовыми данными.
 * Для стейджей будут выдаваться рандомный список топиков.
 */
function Search(props) {
  const {
    location,
    history,
    isMobile,
  } = props;
  const { data, isLoading } = useRequest(rawContentQuery({
    location,
    history,
    card: isMobile ? Card1Type4 : Card3,
  }));

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumn showBoesque={false} />}>
        <SearchPage
          rawContent={data}
          filters={filters}
          ad1={SuperFooter}
          ad2={Context}
          ad1Mobile={Listing1}
          ad2Mobile={ListingSpec}
          card={isMobile ? Card1Type4 : Card3}
          interitemSpacing={isMobile ? 0 : 30}
          dataQa='search-page'
          isLoading={isLoading}
        />
      </ColumnLayout>
    </PageIndent>
  );
}

Search.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Search);
