/* global ymaps */
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';

import { withRouter } from 'core/libs/router';

import loadScript from 'core/utils/load-script';

import H3 from 'core/components/H3';
import { withBreakpoint } from 'core/components/breakpoint';

import modelPropTypes, { dealerAttributes } from 'site/utils/prop-types/model';

import Balloon from './Balloon';
import BalloonWrapper from './BalloonWrapper';

import styles from './index.styl';


const SIZE_LAYOUT = 350000;

const scriptLoadStates = {
  initial: 'initial',
  loading: 'loading',
  ready: 'ready',
};


class DealersMap extends React.Component {
  componentDidMount() {
    const {
      dealers,
    } = this.props;

    if (dealers?.length) {
      this.loadMap(dealers);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      selectedItemId,
      dealers,
      isLoading,
    } = this.props;

    if (dealers !== prevProps.dealers && !isLoading) {
      switch (this.mapScriptState) {
        case scriptLoadStates.initial:
          if (dealers?.length && !this.qutoMap) {
            this.loadMap(dealers);
          }
          break;

        case scriptLoadStates.loading:
          break;

        case scriptLoadStates.ready:
        default:
          this.updatePoints(dealers);
          break;
      }
    }

    if (selectedItemId && selectedItemId !== prevProps.selectedItemId && this.checkInitMap) {
      const item = this.objectManager.objects.getById(selectedItemId);

      this.qutoMap
        .setCenter(item.geometry.coordinates, this.qutoMap.options.get('maxZoom') - 2)
        .then(() => {
          const objectState = this.objectManager.getObjectState(selectedItemId);

          if (objectState.isClustered) {
            this.objectManager.clusters.state.set('activeObject', item);
            this.objectManager.clusters.balloon.open(objectState.cluster.id);
          } else {
            this.objectManager.objects.balloon.open(selectedItemId);
          }
        });
    }
  }

  componentWillUnmount() {
    if (this.qutoMap) {
      this.qutoMap.destroy();
    }
  }

  qutoMap = null;
  objectManager = null;

  mapElRef = React.createRef();
  mapScriptState = scriptLoadStates.initial;

  layoutOverrides = {
    dealersMapInstance: this,
    clickLink: (e) => {
      e.preventDefault();

      const { pathname } = e.currentTarget;

      if (pathname) {
        this.props.history.push(pathname);
      }
    },
    onCloseClick: function(e) {
      e.preventDefault();

      this.events.fire('userclose');
    },
    applyElementOffset: function() {
      const element = this._element;
      const elemArrow = element.querySelector('.balloonArrow');

      const mapSizes = this.dealersMapInstance.qutoMap.container.getSize();
      const mapArea = mapSizes[0] * mapSizes[1];
      const isPanelBalloons = mapArea <= SIZE_LAYOUT;


      element.style.left = isPanelBalloons ? 0 : `${-(element.offsetWidth / 2)}px`;
      element.style.top = `${-(element.offsetHeight + (isPanelBalloons ? '' : elemArrow.offsetHeight / 2))}px`;
    },
    build: function() {
      this.constructor.superclass.build.call(this);

      this._element = document.querySelector('.balloonWrapper');
      this.applyElementOffset();

      this._element.querySelector('.balloonButtonClose').addEventListener('click', this.onCloseClick.bind(this));
      this._linkElements = Array.prototype.slice.call(this._element.querySelectorAll('a'));

      this.dealersMapInstance.props.handlePinClick(false);

      this._linkElements.forEach(item => {
        item.addEventListener('click', this.clickLink);
      });
    },
    rebuild: function() {
      this.dealersMapInstance.props.handlePinClick(!this.dealersMapInstance.qutoMap.balloon.isOpen());
    },
    getShape: function() {
      const element = this._element;

      if (!element) {
        return null;
      }

      return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
        [element.offsetWidth, element.offsetHeight],
        [element.offsetLeft, element.offsetTop],
      ]));
    },
    clear: function() {
      this._element.querySelector('.balloonButtonClose').removeEventListener('click', this.onCloseClick.bind(this));
      this.dealersMapInstance.props.handlePinClick(true);

      this._linkElements.forEach(item => {
        item.removeEventListener('click', this.clickLink);
      });
    },
  };

  get checkInitMap() {
    return this.qutoMap && this.objectManager;
  }

  loadMap = dealers => {
    this.mapScriptState = scriptLoadStates.loading;

    return loadScript('https://api-maps.yandex.ru/2.1/?lang=ru_RU&amp;apikey=41729aa6-e545-4472-aa8e-fe3954d32370')
      .then(() => {
        const checkInterval = setInterval(() => {
          if (ymaps.util) {
            clearInterval(checkInterval);
            this.mapScriptState = scriptLoadStates.ready;
            this.initMap(dealers);
            this.updatePoints(dealers);
          }
        }, 500);
      });
  };

  initMap = dealers => {
    const {
      isMobile,
      controls,
    } = this.props;

    const points = dealers.map(({ attributes }) => (
      [attributes.latitude, attributes.longitude]
    ));

    const centerAndZoom = this.getCenterAndZoom(points);

    this.qutoMap = new ymaps.Map(
      'dealersMap',
      {
        center: centerAndZoom?.center,
        zoom: centerAndZoom?.zoom,
        controls,
      }, {
        maxZoom: 18,
      }
    );

    /**
     * balloonContentBody создаётся при преобразовании дилера в сущность карты в createFeatureFromDealer
     */
    const clusterBalloonHTML = ReactDOMServer.renderToString(
      <BalloonWrapper>
        {`
          {% for geoObject in properties.geoObjects %}
            {{ geoObject.properties.balloonContentBody|raw }}
          {% endfor %}
        `}
      </BalloonWrapper>
    );

    const clusterBalloonLayout = ymaps.templateLayoutFactory.createClass(clusterBalloonHTML, this.layoutOverrides);

    this.objectManager = new ymaps.ObjectManager({
      clusterize: true,
      clusterHasBaloon: false,
      clusterHasHint: false,
      clusterBalloonLayout: clusterBalloonLayout,
      clusterBalloonPanelLayout: clusterBalloonLayout,
      clusterBalloonPanelMaxMapArea: SIZE_LAYOUT,
    });

    this.qutoMap.geoObjects.add(this.objectManager);

    const disableScrollZoomDrag = () => {
      this.qutoMap.behaviors.disable(['scrollZoom', isMobile && 'drag']);
    };

    const enableScrollZoomDrag = () => {
      this.qutoMap.behaviors.enable(['scrollZoom', isMobile && 'drag']);
    };

    disableScrollZoomDrag();

    const fullscreenControl = this.qutoMap.controls.get('fullscreenControl');
    const zoomControl = this.qutoMap.controls.get('zoomControl');

    if (fullscreenControl) {
      fullscreenControl.events
        .add('select', enableScrollZoomDrag)
        .add('deselect', disableScrollZoomDrag);
    }

    if (zoomControl) {
      zoomControl.options.set('size', isMobile ? 'small' : 'medium');
    }
  };

  createObjectManagerCollection = dealers => ({
    type: 'FeatureCollection',
    features: dealers?.map(this.createFeatureFromDealer),
  });

  createFeatureFromDealer = (dealer) => {
    const {
      id,
      attributes: {
        latitude,
        longitude,
      },
    } = dealer;

    const balloonMarkup = <Balloon dealer={dealer} {...this.props} />;

    const balloonLayout = ymaps.templateLayoutFactory.createClass(
      ReactDOMServer.renderToString(<BalloonWrapper>{balloonMarkup}</BalloonWrapper>),
      this.layoutOverrides
    );

    return {
      type: 'Feature',
      id,
      geometry: {
        type: 'Point',
        coordinates: [latitude, longitude],
      },
      options: {
        balloonPanelMaxMapArea: SIZE_LAYOUT,
        balloonPanelLayout: balloonLayout,
        balloonLayout: balloonLayout,
      },
      properties: {
        /**
         * Используется при построение balloon cluster
         */
        balloonContentBody: ReactDOMServer.renderToString(balloonMarkup),
      },
    };
  };

  updatePoints = dealers => {
    this.objectManager.removeAll();
    if (dealers?.length && this.checkInitMap) {
      this.objectManager.add(this.createObjectManagerCollection(dealers));
      this.qutoMap.setBounds(this.objectManager.getBounds(), { checkZoomRange: true });
    }
  };

  getCenterAndZoom = (points) => {
    if (!this.mapElRef.current) {
      return null;
    }

    const bounds = ymaps.util.bounds.fromPoints(points);
    const size = [this.mapElRef.current.offsetWidth, this.mapElRef.current.offsetHeight];

    return ymaps.util.bounds.getCenterAndZoom(bounds, size);
  };
  render() {
    const {
      dealers,
      isLoading,
      height,
    } = this.props;

    return (
      <div className={styles.mapContainer}>
        <div
          ref={this.mapElRef}
          id='dealersMap'
          className={styles.dealersMap}
          style={{ height }}
          data-qa='dealers-map'
        />
        {!isLoading && !dealers?.length && (
          <div className={styles.noDealersOverlay}>
            <H3 is='div'>
              <span className={styles.noDealersOverlayText}>Дилеров не найдено</span>
            </H3>
          </div>
        )}
      </div>
    );
  }
}
DealersMap.propTypes = {
  /** Список дилеров, соответствующих модели `dealerAttributes` */
  dealers: PropTypes.arrayOf(modelPropTypes(dealerAttributes)),

  /** id сущности, для которой должен быть открыт балун. */
  selectedItemId: PropTypes.string,

  /** Индикатор загрузки данных. */
  isLoading: PropTypes.bool,

  /**
   * высота блока под карту
   */
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  /**
   * Контролы, которые будут доступны на карте.
   */
  controls: PropTypes.arrayOf(PropTypes.string),

  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  history: PropTypes.object,

  /** Обработчик клика по пину */
  handlePinClick: PropTypes.func,
};

DealersMap.defaultProps = {
  height: 500,
  controls: ['zoomControl', 'fullscreenControl'],
  handlePinClick: () => null,
};

const DealersMapWithHOCs = withRouter(withBreakpoint(DealersMap));

DealersMapWithHOCs.displayName = 'DealersMap';

export default DealersMapWithHOCs;
export { DealersMap as StorybookComponent };
