import PropTypes from 'prop-types';

import { compose } from 'core/libs/recompose';

import useRequest from 'core/hooks/useRequest';

import { denormalizeData } from 'core/utils/api';

import { withBreakpoint } from 'core/components/breakpoint';
import bindProps from 'core/components/bindProps';
import H3 from 'core/components/H3';
import PageLoader from 'core/components/Loader/PageLoader';
import Drum from 'core/components/Drum';
import withTabs from 'core/components/withTabs';

import { servicesApi } from 'site/api/definitions/services';

import { Indent, ListContentBg } from 'site/components/Wrappers';

import CardServiceOffer1 from 'site/cardsService/CardServiceOffer1';

import Shape from 'site/icons/Shape.svg';
import Time from 'site/icons/Time.svg';
import LightningGreen from 'site/icons/LightningGreen.svg';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  SIDE_INDENT,
  SIDE_INDENT_MOBILE,
} from 'site/constants';

import { nowOnSaleApiParams } from  '../useMainContent';

import Tab from './Tab';
import styles from './index.styl';


const DrumCard = bindProps({ dataQa: 'card-now-on-sale' })(CardServiceOffer1);

const tabs = [
  {
    tabIcon: Shape,
    title: 'Популярные в вашем регионе',
    type: 'popular',
  },
  {
    tabIcon: LightningGreen,
    title: 'Максимально заряженные',
    type: 'charged',
  },
  {
    tabIcon: Time,
    title: 'Свежие обьявления',
    type: 'last',
  },
];

function NowOnSale({ geoSlug, type, isMobile }) {
  const {
    data: content = [],
    isLoading,
  } = useRequest({
    queryKey: ['servicesApi', 'getOffersList', {
      'filter[geo]': geoSlug,
      type,
      ...nowOnSaleApiParams,
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
      .catch(err => console.error(err)),
    select: denormalizeData,
  });

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const horisontal = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;

  const blockContent = content.length > 0 ? (
    <Drum
      headerComponent={H3}
      grid
      itemWidth={270}
      itemWidthMobile={270}
      content={content}
      card={DrumCard}
      arrowsShift={-10}
    />
  ) : (
    <div className={styles.errorMessage}>
      <H3>Ничего не найдено</H3>
    </div>
  );

  return (
    <div data-qa='now-on-sale' >
      <Indent left={-horisontal} right={-horisontal}>
        <ListContentBg style={{ padding: `${vertical}px ${horisontal}px` }}>
          {isLoading ? <PageLoader /> : blockContent}
        </ListContentBg>
      </Indent>
    </div>
  );
}

export default compose(
  withTabs({
    tabs,
    tabComponent: Tab,
    indent: 0,
    useScrollerOnMobile: true,
  }),
  withBreakpoint,
)(NowOnSale);

NowOnSale.propTypes = {
  geoSlug: PropTypes.string,
  type: PropTypes.string,
  isMobile: PropTypes.bool,
};
