import { Fragment } from 'react';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { renderError } from 'core/utils/app-status-helper';
import { denormalizeData } from 'core/utils/api';
import { processQueryResults } from 'core/utils/query';

import { Helmet } from 'core/libs/helmet';

import Comments from 'core/components/Comments';
import { Mobile, withBreakpoint } from 'core/components/breakpoint';
import withTheme from 'core/components/theme';
import PageLoader from 'core/components/Loader/PageLoader';

import { servicesApi } from 'site/api/definitions/services';

import Divider from 'site/components/Divider';
import TitleBackward from 'site/components/TitleBackward';
import { Indent, NegativeMobile } from 'site/components/Wrappers';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';

import { REVIEW_COMMENTS_ID_PREFIX } from 'site/constants';

import { CoverImage, CoverGallery } from './covers';


function ReviewCommentsPage(props) {
  const {
    theme,
    isMobile,
    match,
    match: {
      params: {
        brand: brandSlug,
        model: modelSlug,
        generation: generationSlug,
        body: bodySlug,
        review_id: reviewId,
      },
    },
  } = props;

  const results = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', 'getReviews', {
          'filter[brand]': brandSlug,
          'filter[model]': modelSlug,
          'filter[generation]': generationSlug,
          'filter[body]': bodySlug,
          'filter[review]': reviewId,
          'attributes[review]': 'base,image,media',
          limit: 1,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .then(result => denormalizeData(result)[0])
          .then(rejectEmptyResult)
          .catch(err => renderError(err, history)),
      }, {
        queryKey: ['servicesApi', 'getReviewsSeo', match],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .catch(() => ({})),
        select: denormalizeData,
      },
    ],
  });

  const {
    data: [
      review = {},
      reviewSeo = {},
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  if (resultsAreLoading) return <PageLoader />;

  const {
    name,
    slug: qutoId,
    image,
    photogallery,
  } = review?.attributes || {};

  const imageCount = photogallery?.attributes?.image_count;

  return (
    <Fragment>
      <style jsx>{`
        .date
          color ${theme.colors.primary400}

        .title
          font-family ${theme.fonts.display}

        .cover
          background ${theme.colors.content}
          box-shadow ${isMobile ? 'none' : `0 2px 5px 0 ${theme.colors.primary100}`}
      `}</style>
      <Helmet>
        <title>{reviewSeo.title}</title>
        <meta name='description' content={reviewSeo.description} />
      </Helmet>
      <TitleBackward
        url={`/review/${brandSlug}/${modelSlug}/${generationSlug}/${bodySlug}/${reviewId}`}
        title={name}
      />
      <Indent bottom={15} />
      <NegativeMobile>
        {image && imageCount === 1 && <CoverImage image={image} />}
        {!!imageCount && imageCount > 1 && <CoverGallery photoGallery={photogallery} />}
      </NegativeMobile>
      <Mobile>
        <Divider top={26} bottom={20} />
      </Mobile>
      <Comments xid={REVIEW_COMMENTS_ID_PREFIX + qutoId} />
    </Fragment>
  );
}

ReviewCommentsPage.propTypes = {
  isMobile: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string.isRequired,
      model: PropTypes.string.isRequired,
      generation: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
      review_id: PropTypes.string.isRequired,
    }),
  }).isRequired,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(ReviewCommentsPage));
