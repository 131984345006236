import color from 'core/libs/color';
import oneLine from 'common-tags/lib/oneLine';
import deepmerge from 'core/libs/deepmerge';

import { MOBILE, DESKTOP } from 'core/components/breakpoint/values';
import bindProps from 'core/components/bindProps';

import Logo from 'site/components/Logo';
import Video from 'site/icons/Video';
import Photo from 'site/icons/Photo';
import Phone from 'site/icons/Phone';
import Comments from 'site/icons/Comments';
import ArrowLeftSm from 'site/icons/ArrowLeftSm';
import ArrowRightSm from 'site/icons/ArrowRightSm';
import ArrowLeftDrum from 'site/icons/ArrowLeftDrum';
import ArrowRightDrum from 'site/icons/ArrowRightDrum';
import Vs from 'site/icons/VS.svg';

import Spinner from 'core/components/Icon/Spinner';

import StoryTopicCard from 'site/cards/StoryTopicCard';
import CompareCard from 'site/cards/CompareCard';

import RobotoRegular from './fonts/Roboto/roboto-regular.woff';
import RobotoRegular2 from './fonts/Roboto/roboto-regular.woff2';
import RobotoItalic from './fonts/Roboto/roboto-italic.woff';
import RobotoItalic2 from './fonts/Roboto/roboto-italic.woff2';
import RobotoMedium from './fonts/Roboto/roboto-medium.woff';
import RobotoMedium2 from './fonts/Roboto/roboto-medium.woff2';
import RobotoBold from './fonts/Roboto/roboto-bold.woff';
import RobotoBold2 from './fonts/Roboto/roboto-bold.woff2';
import GilroyExtraBold from './fonts/Gilroy/gilroy-extrabold-webfont.woff';
import GilroyExtraBold2 from './fonts/Gilroy/gilroy-extrabold-webfont.woff2';
import GilroyLight from './fonts/Gilroy/gilroy-light-webfont.woff';
import GilroyLight2 from './fonts/Gilroy/gilroy-light-webfont.woff2';

import errorImg from 'site/pages/images/jsError.png';

import {
  SIDE_INDENT,
  VERTICAL_INDENT,
} from 'site/constants';

import { Card2Type2 } from 'site/cards/Card2';

export const LogoMd = bindProps({ type: 2 })(Logo);
const LogoLg = bindProps({ type: 3 })(Logo);

export const fonts = {
  text: 'Roboto, Roboto-fallback, sans-serif',
  display: 'Gilroy, Gilroy-fallback, sans-serif',
  get nuance() {
    return this.text;
  },
  faces: [
    {
      fontFamily: 'Roboto',
      src: oneLine`
        local('Roboto-Regular'),
        url(${RobotoRegular2}) format('woff2'),
        url(${RobotoRegular}) format('woff')
      `,
    },
    {
      fontFamily: 'Roboto',
      fontStyle: 'italic',
      src: oneLine`
        local('Roboto-Italic'),
        url(${RobotoItalic2}) format('woff2'),
        url(${RobotoItalic}) format('woff')
      `,
    },
    {
      fontFamily: 'Roboto',
      fontWeight: 700,
      src: oneLine`
        local('Roboto-Bold'),
        url(${RobotoBold2}) format('woff2'),
        url(${RobotoBold}) format('woff')
      `,
    },
    {
      fontFamily: 'Roboto',
      fontWeight: 500,
      src: oneLine`
        local('Roboto-Medium'),
        url(${RobotoMedium2}) format('woff2'),
        url(${RobotoMedium}) format('woff')
      `,
    },
    {
      fontFamily: 'Gilroy',
      fontWeight: 800,
      src: oneLine`
        local('Gilroy-ExtraBold'),
        url(${GilroyExtraBold2}) format('woff2'),
        url(${GilroyExtraBold}) format('woff')
      `,
    },
    {
      fontFamily: 'Gilroy',
      fontWeight: 300,
      src: oneLine`
        local('Gilroy-Light'),
        url(${GilroyLight2}) format('woff2'),
        url(${GilroyLight}) format('woff')
      `,
    },
    {
      fontFamily: 'Roboto-fallback',
      sizeAdjust: '100%',
      ascentOverride: '93%',
      src: 'local("Arial")',
    },
    {
      fontFamily: 'Gilroy-fallback',
      sizeAdjust: '100%',
      ascentOverride: '73%',
      lineGapOverride: '48%',
      src: 'local("Arial")',
    },
  ],
};

const icons = {
  logo: LogoMd,
  Video,
  Photo,
  Phone,
  comments: Comments,
  Spinner,
};

export default function baseTheme(atoms) {
  atoms.colors.theme = atoms.colors.active1;

  atoms.colors.placeholder = color(atoms.colors.primary).alpha(0.3).string();

  atoms.colors.content600 = color(atoms.colors.primary).alpha(0.6).string();

  atoms.colors.primary100 = color(atoms.colors.primary).alpha(0.1).string();
  atoms.colors.primary150 = color(atoms.colors.primary).alpha(0.15).string();
  atoms.colors.primary250 = color(atoms.colors.primary).alpha(0.25).string();
  atoms.colors.primary400 = color(atoms.colors.primary).alpha(0.4).string();
  atoms.colors.primary500 = color(atoms.colors.primary).alpha(0.5).string();
  atoms.colors.primary600 = color(atoms.colors.primary).alpha(0.6).string();
  atoms.colors.primary800 = color(atoms.colors.primary).alpha(0.8).string();

  const animations = {
    hover: '150ms ease-out',
  };

  const texts = {
    lead: {
      font: `400 22px/32px ${fonts.text}`,
      color: color(atoms.colors.primary).alpha(0.8).string(),
      marginTop: '15px',
      letterSpacing: 'normal',
    },

    body: {
      font: `16px/23px ${fonts.text}`,
    },

    bodySmall: {
      font: `15px/20px ${fonts.text}`,
      color: atoms.colors.text,
    },

    h1: {
      color: atoms.colors.text,
      font: `bold 36px/38px ${fonts.display}`,
    },

    h2: {
      color: atoms.colors.text,
      font: `bold 32px/36px ${fonts.display}`,
    },

    h3: {
      color: atoms.colors.text,
      font: `bold 22px/1.2 ${fonts.display}`,
    },

    h4: {
      color: atoms.colors.text,
      font: `bold 18px/20px ${fonts.display}`,
    },

    h5: {
      color: atoms.colors.text,
      font: `bold 18px/20px ${fonts.display}`,
    },

    h6: {
      color: atoms.colors.text,
      textTransform: 'uppercase',
      font: `bold 16px/18px ${fonts.text}`,
    },

    get title1() {
      return this.h1;
    },
    get title2() {
      return this.h2;
    },
    get title3() {
      return this.h3;
    },
    get title4() {
      return this.h4;
    },
    get title5() {
      return this.h5;
    },
    get title6() {
      return this.h6;
    },
    get headline() {
      return this.h1;
    },

    caption1: {
      font: `15px/20px ${fonts.text}`,
    },

    caption3: {
      font: `14px/18px ${fonts.text}`,
      color: atoms.colors.hint,
    },

    quote: {
      font: `20px/30px ${fonts.display}`,
    },

    menu: {
      font: `500 14px/16px ${fonts.text}`,
      letterSpacing: '.75px',
      textTransform: 'uppercase',
    },
  };

  const sizes = {
    extraSmall: {
      font: `500 14px/16px ${fonts.text}`,
      width: '52px',
      height: '24px',
      padding: '4px 7px',
    },
    small: {
      font: `12px/14px ${fonts.text}`,
      width: '140px',
      height: '30px',
      padding: '6px 15px',
    },
    medium: {
      font: `14px/16px ${fonts.text}`,
      width: '150px',
      height: '40px',
      padding: '8px 22px',
    },
    large: {
      font: `16px/16px ${fonts.text}`,
      width: '180px',
      height: '52px',
      padding: '10px 30px',
    },
    extraLarge: {
      font: `14px/16px ${fonts.text}`,
      width: '275px',
      height: '40px',
      padding: '10px 50px',
    },
  };

  const button = {
    const: {
      sizes,
      radius: '4px',
    },

    primary: {
      idle: {
        color: '#FFF',
        background: atoms.colors.active1,
        /**
         * указываем пустой border для стилевой совместимости,
         * когда тип конпки primary/secondary меняется по клику
         */
        border: '1px solid transparent',
      },

      hover: {
        color: '#FFF',
        background: color(atoms.colors.active1).mix(color(atoms.colors.text), 0.1).string(),
        border: '1px solid transparent',
      },

      active: {
        color: '#FFF',
        background: atoms.colors.active1,
        border: '1px solid transparent',
      },

      disabled: {
        color: '#FFF',
        background: `${color(atoms.colors.active2).alpha(0.7).string()}`,
        border: '1px solid transparent',
      },
    },

    secondary: {
      idle: {
        color: atoms.colors.text,
        background: '#FFF',
        border: `1px solid ${color(atoms.colors.text).alpha(0.1).string()}`,
      },

      hover: {
        color: '#FFF',
        background: atoms.colors.active1,
        border: `1px solid ${atoms.colors.active1}`,
      },

      active: {
        color: '#FFF',
        background: atoms.colors.active1,
        border: `1px solid ${atoms.colors.active1}`,
      },

      disabled: {
        color: color(atoms.colors.text).alpha(0.25).string(),
        background: 'transparent',
        border: `1px solid ${color(atoms.colors.text).alpha(0.1).string()}`,
      },
    },

    primaryGrey: {
      idle: {
        color: atoms.colors.text,
        background: atoms.colors.content,
        border: 'none',
      },

      hover: {
        color: atoms.colors.text,
        background: atoms.colors.content,
        border: 'none',
      },

      active: {
        color: atoms.colors.text,
        background: atoms.colors.content,
        border: 'none',
      },

      disabled: {
        color: atoms.colors.text,
        background: atoms.colors.content,
        border: 'none',
      },
    },

    ghost: {
      idle: {
        color: atoms.colors.text,
      },

      hover: {
        color: atoms.colors.text,
      },

      active: {
        color: atoms.colors.text,
      },

      disabled: {
        color: color(atoms.colors.text).alpha(0.25).string(),
      },
    },

    ghostActive1: {
      idle: {
        color: atoms.colors.active1,
        background: 'none',
      },

      hover: {
        color: color(atoms.colors.active1)
          .mix(color(atoms.colors.text), 0.4)
          .string(),
        background: 'none',
      },

      active: {
        color: color(atoms.colors.active1)
          .mix(color(atoms.colors.text), 0.4)
          .string(),
        background: 'none',
      },

      disabled: {
        color: color(atoms.colors.active1).alpha(0.25).string(),
        background: 'none',
      },
    },

    green: {
      idle: {
        color: '#FFF',
        background: atoms.colors.green,
      },

      hover: {
        color: '#FFF',
        background: atoms.colors.darkGreen,
      },

      active: {
        color: '#FFF',
        background: atoms.colors.green,
      },

      disabled: {
        color: '#FFF',
        background: atoms.colors.green,
      },
    },

    limeGradient: {
      idle: {
        color: '#000',
        background: `linear-gradient(360deg,
          ${atoms.colors.lime1} 10.9%,
          ${atoms.colors.lime2} 53.6%,
          ${atoms.colors.lime3} 100%)`,
      },

      hover: {
        color: '#000',
        background: `linear-gradient(360deg,
          ${atoms.colors.lime1} 80%,
          ${atoms.colors.lime2} 90%,
          ${atoms.colors.lime3} 100%)`,
      },

      active: {
        color: '#000',
        background: `linear-gradient(360deg,
          ${atoms.colors.lime1} 0%,
          ${atoms.colors.lime2} 10%,
          ${atoms.colors.lime3} 20%)`,
      },

      disabled: {
        color: '#000d',
        background: `linear-gradient(360deg,
          ${color(atoms.colors.lime1).alpha(0.9).string()} 0%,
          ${color(atoms.colors.lime2).alpha(0.9).string()} 10%,
          ${color(atoms.colors.lime3).alpha(0.9).string()} 20%)`,
      },
    },
  };

  const smartTeaser = {
    carRelease: {
      title: {
        font: `700 20px/25px ${fonts.display}`,
      },
      info: {
        font: `500 16px/18px ${fonts.text}`,
      },
      link: {
        hover: {
          color: atoms.colors.active1,
        },
      },
    },
    gameCompare: {
      readMore: {
        color: atoms.colors.content,
      },
    },
  };

  const link = {
    article: {
      idle: {
        color: atoms.colors.active1,
        decoration: 'none',
      },

      hover: {
        color: atoms.colors.text,
        decoration: 'underline',
      },

      active: {
        color: atoms.colors.text,
        decoration: 'underline',
      },

      visited: {
        color: atoms.colors.blue1,
        decoration: 'none',
      },
    },

    secondary: {
      idle: {
        color: atoms.colors.active1,
        decoration: 'none',
      },

      hover: {
        color: color(atoms.colors.active1).mix(color(atoms.colors.text), 0.3).string(),
        decoration: 'none',
      },

      active: {
        color: atoms.colors.active1,
        decoration: 'none',
      },

      visited: {
        color: atoms.colors.active1,
        decoration: 'none',
      },
    },

    tertiary: {
      idle: {
        color: atoms.colors.text,
      },
      hover: {
        color: atoms.colors.active2,
      },
      active: {
        color: atoms.colors.active2,
      },
      visited: {
        color: atoms.colors.text,
      },
    },

    underlined: {
      idle: {
        color: atoms.colors.text,
        decoration: 'underline',
      },
      hover: {
        color: atoms.colors.active2,
        decoration: 'underline',
      },
      active: {
        color: atoms.colors.active2,
        decoration: 'underline',
      },
      visited: {
        color: atoms.colors.text,
        decoration: 'underline',
      },
      current: {
        color: atoms.colors.active2,
        decoration: 'underline',
      },
    },

    primaryUnderlined: {
      idle: {
        color: atoms.colors.active1,
        decoration: 'underline',
      },

      hover: {
        color: atoms.colors.text,
        decoration: 'none',
      },

      active: {
        color: atoms.colors.text,
        decoration: 'none',
      },

      visited: {
        color: atoms.colors.blue1,
        decoration: 'underline',
      },
    },
  };

  const shapkaLink = {
    font: `400 16px/24px ${fonts.text}`,
    letterSpacing: '0.15px',
  };

  const shapka = {
    link: {
      ...shapkaLink,
      padding: '0 15px',
    },
    dropdown: {
      link: {
        ...shapkaLink,
      },
    },
    padding: `0 ${SIDE_INDENT}px`,
    logo: {
      Icon: LogoLg,
      width: 190,
      height: 44,
    },

    menu: {
      navMargin: '0 0 0 80px',
    },

    labelNoAds: {
      fontWeight: 'bold',
      color: '#fff',
      textTransform: 'none',
    },
  };

  const mark = {
    padding: '0 0.1em',
    background: '#EAF0B7',
  };

  const topicFooter = {
    commentButton: {
      type: 'secondary',
      textTransform: 'uppercase',
      font: `500 14px/16px ${fonts.text}`,
      topSpacing: VERTICAL_INDENT,
    },
    socialMedia: {
      type: 'fancy',
    },
    block: {
      spacing: VERTICAL_INDENT,
    },
  };

  const secondaryMenu = {
    const: {
      background: atoms.colors.active1,
      font: `500 14px/16px ${fonts.text}`,
      backgroundDropdown: atoms.colors.active2,
    },
    idle: {
      itemBorderColor: 'transparent',
      color: 'rgba(255, 255, 255, 0.5)',
    },
    hover: {
      itemBorderColor: 'rgba(255, 255, 255, 0.5)',
      color: 'rgba(255, 255, 255, 0.5)',
    },
    active: {
      itemBorderColor: 'rgba(255, 255, 255, 1)',
      color: 'rgba(255, 255, 255, 1)',
    },
  };

  const scooter = {
    social: {
      margin: '0 0 0 20px',
      size: 40,
      iconSize: 20,
      borderRadius: 3,
      idle: {
        color: '#FFF',
        background: atoms.colors.text,
        border: 'none',
      },
      hover: {
        color: '#FFF',
        background: atoms.colors.active2,
        border: 'none',
      },
    },
    menuLink: {
      textTransform: 'uppercase',
      font: `500 14px/26px ${fonts.text}`,
    },
    logo: {
      Icon: LogoMd,
      width: 150,
      height: 40,
    },
  };

  const counter = {
    font: `800 14px/20px ${fonts.text}`,
    color: atoms.colors.text,
  };

  const gallery = {
    slide: {
      background: atoms.colors.contentList,
    },
    info: {
      padding: '15px 0 0',
    },
    arrows: {
      Left: ArrowLeftDrum,
      Right: ArrowRightDrum,

      idle: {
        stroke: '#C4C4C4',
      },
      idleFullscreen: {
        stroke: '#C4C4C4',
      },
      hover: {
        stroke: '#000',
      },
      hoverFullscreen: {
        stroke: '#000',
      },
    },
    icons: {
      background: color(atoms.colors.primary).alpha(0.47).string(),
    },
  };

  const input = {
    const: {
      color: atoms.colors.text,
      sizes,
    },

    basic: {
      idle: {
        background: 'transparent',
        border: `1px solid ${atoms.colors.grey4}`,
        placeholderColor: atoms.colors.hint,
      },

      hover: {
        background: 'transparent',
        border: `1px solid ${atoms.colors.hint}`,
      },

      focused: {
        background: atoms.colors.layout,
        border: `1px solid ${atoms.colors.hint}`,
      },
    },
  };

  const paginatron = {
    text: {
      font: `16px/18px ${fonts.nuance}`,
      color: atoms.colors.text,
    },

    icon: {
      arrow: {
        size: '18px',
      },

      doubleArrow: {
        size: '18px',
      },

      idle: {
        fill: button.ghost.idle.color,
      },
      hover: {
        fill: button.ghost.hover.color,
      },
      disabled: {
        fill: button.ghost.disabled.color,
      },
    },

    outerButtons: {
      innerBorder: button.secondary.idle.border,
      idle: {
        background: button.secondary.idle.background,
        color: button.secondary.idle.color,
      },
      hover: {
        background: button.secondary.hover.background,
        color: atoms.colors.content,
      },
    },

    radius: button.const.radius,
  };

  const spammer = {
    background: atoms.colors.contentList,

    [MOBILE]: {
      borderRadius: '5px',
      formElements: {
        radius: '5px',
        size: 'medium',
      },
    },

    [DESKTOP]: {
      borderRadius: '5px',
      formElements: {
        radius: '5px',
        size: 'large',
      },
    },
  };

  const inputDropdown = {
    idle: {
      backgroundColor: 'rgba(0, 0, 0, 0.09)',
      color: atoms.colors.text,
      labelColor: atoms.colors.text,
      borderBottom: '2px solid rgba(0, 0, 0, 0.3)',
      indicatorColor: 'rgba(0, 0, 0, 0.5)',
    },
    hover: {
      backgroundColor: 'rgba(187, 187, 187, 0.15)',
      color: atoms.colors.text,
      labelColor: atoms.colors.text,
      borderBottom: '2px solid rgba(0, 0, 0, 0.3)',
      indicatorColor: 'rgba(0, 0, 0, 0.5)',
    },
    active: {
      backgroundColor: 'rgba(187, 187, 187, 0.15)',
      color: atoms.colors.text,
      labelColor: atoms.colors.active1,
      borderBottom: `2px solid ${atoms.colors.active1}`,
      indicatorColor: 'rgba(0, 0, 0, 0.5)',
    },
    hasValue: {
      backgroundColor: 'rgba(187, 187, 187, 0.15)',
      color: atoms.colors.text,
      labelColor: atoms.colors.active1,
      borderBottom: '2px solid rgba(0, 0, 0, 0.3)',
      indicatorColor: 'rgba(0, 0, 0, 0.5)',
    },
    disabled: {
      backgroundColor: 'rgba(187, 187, 187, 0.15)',
      color: atoms.colors.text,
      labelColor: color(atoms.colors.text).alpha(0.2).string(),
      borderBottom: '2px solid transparent',
      indicatorColor: 'rgba(0, 0, 0, 0.5)',
    },
  };

  const comments = {
    const: {
      color: atoms.colors.active1,
    },
  };

  const commentsPreview = {
    headline: {
      font: `bold 22px/1.2 ${fonts.display}`,
      color: atoms.colors.text,
      textTransform: 'none',
    },
    name: {
      font: `bold 14px/20px ${fonts.text}`,
    },
    date: {
      font: `normal 12px/16px ${fonts.text}`,
    },
    comment: {
      font: `normal 14px/20px ${fonts.text}`,
    },
    button: {
      margin: 0,
    },
  };

  const drum = {
    const: {
      bottomSpacing: '18px',
      bulletsMargin: '16px 0 0 0',
    },

    arrow: {
      Left: ArrowLeftDrum,
      Right: ArrowRightDrum,

      fade: {
        outer: 'rgba(255, 255, 255, 0.7)',
        inner: 'rgba(255, 255, 255, 0)',
      },

      idle: {
        color: atoms.colors.text,
      },

      hover: {
        stroke: '#000',
        color: (
          color(atoms.colors.text).alpha(0.6).string()
        ),
      },
    },

    headerArrows: {
      const: {
        Left: ArrowLeftSm,
        Right: ArrowRightSm,
        width: 10,
        height: 18,
      },
    },
  };

  const bullet = {
    const: {
      size: '6px',
    },

    basic: {
      idle: {
        background: atoms.colors.primary250,
      },

      hover: {
        background: atoms.colors.primary,
      },

      active: {
        background: atoms.colors.primary,
      },
    },
  };

  const layout = {
    sideColumnIndent: '40px',
    indents: {
      top: VERTICAL_INDENT,
      right: SIDE_INDENT,
      bottom: VERTICAL_INDENT,
      left: SIDE_INDENT,
    },
  };

  const formInput = {
    const: {
      placeholderColor: 'rgba(0, 0, 0, 0.4)',
      multiValue: {
        border: `1px solid ${color(atoms.colors.primary).alpha(0.5).string()}`,
        background: 'transparent',
      },
      multiValueLabel: {
        color: atoms.colors.primary,
      },
      optionColor: '#000',
    },
    basic: {
      idle: {
        backgroundColor: color(atoms.colors.primary).alpha(0.09).string(),
        borderColor: color(atoms.colors.primary).alpha(0.3).string(),
        color: atoms.colors.text,
        label: {
          color: atoms.colors.text,
        },
        multiValueRemove: {
          fill: atoms.colors.primary,
          backgroundColor: 'transparent',
        },
      },
      hover: {
        backgroundColor: color(atoms.colors.primary).alpha(0.14).string(),
        borderColor: color(atoms.colors.primary).alpha(0.4).string(),
        color: atoms.colors.text,
        label: {
          color: atoms.colors.text,
        },
        multiValueRemove: {
          fill: atoms.colors.content,
          backgroundColor: color(atoms.colors.primary).alpha(0.5).string(),
        },
      },
      active: {
        backgroundColor: color(atoms.colors.primary).alpha(0.04).string(),
        borderColor: atoms.colors.active1,
        color: atoms.colors.text,
        label: {
          color: atoms.colors.active1,
        },
        multiValueRemove: {
          fill: atoms.colors.primary,
          backgroundColor: 'transparent',
        },
      },
      hasValue: {
        backgroundColor: color(atoms.colors.primary).alpha(0.04).string(),
        borderColor: atoms.colors.active1,
        color: atoms.colors.text,
        label: {
          color: atoms.colors.active1,
        },
        multiValueRemove: {
          fill: atoms.colors.primary,
          backgroundColor: 'transparent',
        },
      },
      disabled: {
        backgroundColor: color(atoms.colors.primary).alpha(0.04).string(),
        borderColor: 'transparent',
        color: color(atoms.colors.text).alpha(0.3).string(),
        label: {
          color: color(atoms.colors.text).alpha(0.4).string(),
        },
        multiValueRemove: {
          fill: atoms.colors.primary,
          backgroundColor: 'transparent',
        },
      },
    },
  };

  const inputCompact = {
    label: {
      color: color(atoms.colors.text).alpha(0.4).string(),
      font: `13px/24px ${fonts.text}`,
    },
    input: {
      color: atoms.colors.text,
      font: `500 16px/24px ${fonts.text}`,
    },
    idle: {
      borderColor: color(atoms.colors.primary).alpha(0.2).string(),
    },
    hover: {
      borderColor: color(atoms.colors.primary).alpha(0.5).string(),
    },
    focus: {
      borderColor: atoms.colors.primary,
    },
    invalid: {
      borderColor: atoms.colors.error,
    },
  };

  const inputRange = {
    title: {
      color: atoms.colors.text,
    },
    track: {
      outside: {
        background: color(atoms.colors.active1).alpha(0.3).string(),
      },
      between: {
        background: atoms.colors.active1,
      },
    },
    thumb: {
      background: atoms.colors.active1,
    },
    unit: {
      color: color(atoms.colors.text).alpha(0.5).string(),
    },
    mark: {
      color: atoms.colors.active1,
    },
  };

  const chip = {
    idle: {
      color: atoms.colors.text,
      backgroundColor: '#FFF',
      borderColor: color(atoms.colors.text).alpha(0.4).string(),
      indicatorColor: atoms.colors.text,
      indicatorBackground: 'transparent',
    },
    hover: {
      color: atoms.colors.text,
      backgroundColor: '#FFF',
      borderColor: color(atoms.colors.text).alpha(0.4).string(),
      indicatorColor: atoms.colors.text,
      indicatorBackground: 'transparent',
    },
    active: {
      color: atoms.colors.text,
      backgroundColor: '#FFF',
      borderColor: color(atoms.colors.text).alpha(0.4).string(),
      indicatorColor: atoms.colors.text,
      indicatorBackground: 'transparent',
    },
    hasValue: {
      color: atoms.colors.text,
      backgroundColor: '#FFF',
      borderColor: atoms.colors.text,
      indicatorColor: '#FFF',
      indicatorBackground: atoms.colors.text,
    },
    hasValueHover: {
      color: atoms.colors.text,
      backgroundColor: '#FFF',
      borderColor: atoms.colors.text,
      indicatorColor: '#FFF',
      indicatorBackground: atoms.colors.error,
    },
    disabled: {
      color: color(atoms.colors.text).alpha(0.4).string(),
      backgroundColor: '#FFF',
      borderColor: color(atoms.colors.text).alpha(0.4).string(),
      indicatorColor: color(atoms.colors.text).alpha(0.4).string(),
      indicatorBackground: 'transparent',
    },
  };

  const chipOption = {
    single: {
      idle: {
        backgroundColor: '#FFF',
      },
      hover: {
        backgroundColor: atoms.colors.dividerLight,
      },
      selected: {
        backgroundColor: atoms.colors.dividerLight,
      },
      selectedHover: {
        backgroundColor: atoms.colors.dividerLight,
      },
    },
    multi: {
      idle: {
        backgroundColor: '#FFF',
      },
      hover: {
        backgroundColor: atoms.colors.dividerLight,
      },
      selected: {
        backgroundColor: atoms.colors.dividerLight,
      },
      selectedHover: {
        backgroundColor: atoms.colors.dividerLight,
      },
    },
  };

  const radioCloud = {
    idle: {
      color: atoms.colors.primary,
      background: color(atoms.colors.content).mix(color(atoms.colors.primary), 0.3).string(),
    },
    hover: {
      color: atoms.colors.primary,
      background: color(atoms.colors.content).mix(color(atoms.colors.primary), 0.4).string(),
    },
    hoverChecked: {
      color: atoms.colors.content,
      background: atoms.colors.primary,
    },
    checked: {
      color: atoms.colors.content,
      background: atoms.colors.primary,
    },
    focus: {
      color: atoms.colors.primary,
      background: color(atoms.colors.content).mix(color(atoms.colors.primary), 0.3).string(),
    },
    focusChecked: {
      color: atoms.colors.content,
      background: atoms.colors.primary,
    },
    disabled: {
      color: color(atoms.colors.primary).alpha(0.5).string(),
      background: color(atoms.colors.content).mix(color(atoms.colors.primary), 0.1).string(),
    },
    disabledChecked: {
      color: color(atoms.colors.content).alpha(0.9).string(),
      background: color(atoms.colors.primary).alpha(0.9).string(),
    },
  };

  const stopCoronavirus = {
    link: '/journal/label/koronavirus',
  };

  const ads = {
    urlPreviewImage: {
      retina: require('site/pages/images/prime/macbookPreview-2x.png'),
      common: require('site/pages/images/prime/macbookPreview-1x.png'),
    },
  };

  atoms.colors.adsColor = '#fff';

  const checkbox = {
    dark: {
      idle: {
        fill: atoms.colors.text,
      },
      focus: {
        fill: atoms.colors.text,
      },
      checked: {
        fill: atoms.colors.text,
      },
      disabled: {
        fill: atoms.colors.text,
      },
      checkedDisabled: {
        fill: atoms.colors.text,
      },
    },
  };

  const personal = {
    circleCheckMark: {
      stroke: '#fff',
    },
    profileAvatar: {
      isCircular: true,
    },
  };

  const switcher = {
    colors: {
      off: color(atoms.colors.content).mix(color(atoms.colors.active1), 0.5).hex(),
      on: atoms.colors.active1,
      boxShadow: atoms.colors.active1, // It's important for accessibility.
      text: atoms.colors.text,
    },
  };

  const topicHeaderTexts = {
    title: {
      font: `800 36px/40px ${fonts.display}`,
      letterSpacing: '0.5px',
      color: atoms.colors.primary,
    },
    subtitle: {
      font: `300 32px/40px ${fonts.display}`,
      letterSpacing: '0.5px',
      color: atoms.colors.primary,
    },
    lead: { ...texts.lead },
    marginBottom: 24,
  };

  const topicTopline = {
    font: `14px/1 ${fonts.nuance}`,
    color: color(atoms.colors.primary).alpha(0.4).string(),
    rubric: {
      font: `14px/1 ${fonts.text}`,
      color: atoms.colors.text,
      border: 'none',
      borderRadius: '0',
      padding: '0',
      hover: {
        color: atoms.colors.text,
        background: 'transparent',
        decoration: 'underline',
      },
      premiumTopic: {
        color: atoms.colors.lime1,
      },
    },
  };

  const vikontReadMore = {
    block: {
      background: 'transparent',
      padding: '20px 20px 30px',
      borderRadius: 0,
    },
    title: {
      color: atoms.colors.primary,
      font: `14px/20px ${fonts.text}`,
      marginBottom: '10px',
    },
    drum: {
      arrowsShift: -10,
      itemWidth: 260,
      spaceBetween: 20,
      sideIndent: 20,
    },
    singleCard: {
      alignItems: 'center',
    },
    feed: {
      interitemSpacing: 15,
    },
  };

  const vikontIncut = {
    borderWidth: '0',
    padding: '0 0 0 16px',
    textAlign: 'left',
    font: texts.lead.font,
    color: texts.lead.color,
  };

  const errorPage = {
    imgUrl: errorImg,
    imgWidth: 210,
    card: Card2Type2,
  };

  const pages = {
    error: {
      ...errorPage,
      subtitle: {
        name: 'ЧТО-ТО ПОШЛО НЕ ТАК',
      },
    },
    notFound: {
      ...errorPage,
      title: {
        name: 'Ошибка 404',
      },
      subtitle: {
        name: 'СТРАНИЦА НЕ НАЙДЕНА',
      },
    },
    sentryFallback: {
      ...errorPage,
      title: {
        name: 'Что-то пошло не так',
      },
      subtitle: {
        name: 'МЫ УЖЕ ЗНАЕМ ОБ ЭТОМ И СКОРО ВСЕ ИСПРАВИМ',
      },
      card: null,
    },
    development: {
      ...errorPage,
      title: {
        name: 'Раздел в разработке',
      },
      subtitle: {
        name: 'Пожалуйста, зайдите сюда позже',
      },
      card: null,
    },
  };

  const expert = {
    name: {
      idle: {
        font: `500 16px/23px ${fonts.nuance}`,
        margin: '0',
      },
      hover: {
        font: `500 16px/23px ${fonts.nuance}`,
        margin: '0',
      },
    },
    jobTitle: {
      color: atoms.colors.greyText,
      font: `400 13px/20px ${fonts.nuance}`,
    },
    label: {
      background: atoms.colors.primary,
      font: `700 10px/1 ${fonts.nuance}`,
      letterSpacing: '0.02em',
      borderRadius: '4px',
    },
  };

  const author = {
    types: {
      expert: {
        background: `linear-gradient(360deg, ${atoms.colors.lime1} 10.9%, ${atoms.colors.lime2} 53.6%, ${atoms.colors.lime3} 100%)`,
        color: atoms.colors.primary,
        borderRadius: '15px',
        border: `1px solid ${atoms.colors.primary}`,
        textTransform: 'uppercase',
      },
    },
  };

  const authorPage = {
    emailLinkType: 'primaryUnderlined',
  };

  const notes = {
    background: atoms.colors.contentList,
  };

  const topicContent = {
    widgetsMaxWidth: 620,
    widgetsWithMaxWidth: {
      'tableMarkdown': true,
      'smartTeaser': true,
    },
    widgetsWithNegativeMobileIndent: {
      'smartTeaser': true,
    },
  };

  const story = {
    slide: {
      width: 145,
      spaceBetween: 12,
    },
  };

  const storyCard = {
    card: {
      Card: StoryTopicCard,
    },
    statusLine: {
      border: `2px solid ${atoms.colors.lime1}`,
      borderRadius: '6px',
    },
  };

  const storyWidgets = {
    color: atoms.colors.layout,
    navigation: {
      background: atoms.colors.lime1,
    },
  };

  const storyWidgetsBaseCard = {
    color: atoms.colors.layout,
    headline: {
      font: `600 24px/30px ${fonts.text}`,
      textTransform: 'normal',
    },
    lead: {
      font: `400 14px/18px ${fonts.text}`,
    },
    button: {
      minWidth: 80,
    },
  };

  const storyWidgetsVariants = {
    headline: {
      font: `800 24px/30px ${fonts.display}`,
    },

    states: {
      idle: {
        caption: {
          background: atoms.colors.lime1,
        },
        image: {
          boxShadow: `-3px 3px 0 0 ${atoms.colors.lime1}`,
        },
      },
      hover: {
        caption: {
          background: atoms.colors.lime1,
        },
        image: {
          boxShadow: `-5px 5px 0 0 ${atoms.colors.lime1}`,
        },
      },
      checked: {
        caption: {
          background: atoms.colors.lime1,
        },
        image: {
          boxShadow: `-3px 3px 0 0 ${atoms.colors.lime1}`,
        },
      },
    },

    twoVariants: {
      caption: {
        font: `800 24px/30px ${fonts.display}`,
      },
    },
    threeVariants: {
      caption: {
        font: `800 18px/22px ${fonts.display}`,
      },
    },
  };

  const storyWidgetsRatingCard = {
    headline: {
      font: `800 28px/35px ${fonts.text}`,
    },
    count: {
      font: `96px/1.2 ${fonts.text}`,
    },
    percent: {
      font: `36px/45px ${fonts.text}`,
    },
  };

  const storyWidgetsHiddenPhoto = {
    states: {
      correct: {
        answerText: {
          background: atoms.colors.lime1,
        },
      },
    },

    variant: {
      hover: {
        border: `1px solid ${atoms.colors.lime1}`,
        background: atoms.colors.lime1,
      },
      checkedCorrect: {
        border: `1px solid ${atoms.colors.lime1}`,
        background: atoms.colors.lime1,
      },
    },
  };

  const reactions = {
    tiny: {
      font: `600 12px/15px ${fonts.text}`,
    },

    small: {
      font: `600 14px/16px ${fonts.text}`,
      radius: '13px',
      padding: '3px 7px',
    },

    primary: {
      idle: {
        color: atoms.colors.lime1,
        border: '1px solid transparent',
      },
      checked: {
        color: atoms.colors.lime1,
        border: `1px solid ${atoms.colors.lime1}`,
        background: 'transparent',
      },
      focus: {
        color: atoms.colors.lime1,
        border: '1px solid transparent',
      },
      disabled: {
        color: atoms.colors.lime1,
        border: '1px solid transparent',
      },
      checkedDisabled: {
        color: atoms.colors.lime1,
        border: `1px solid ${atoms.colors.lime1}`,
        background: 'transparent',
      },
    },
  };

  const reactionsEmojiBar = {
    const: {
      padding: '24px 25px',
      borderRadius: '4px',
      background: atoms.colors.grey3,
      border: `1px solid ${atoms.colors.grey4}`,
      boxShadow: 'none',
    },

    title: {
      font: `700 18px/22px ${fonts.display}`,
    },
  };

  const gameCompare = {
    showComments: true,
    tooltip: {
      theme: 'dark',
      buttonType: 'primaryGrey',
      scheme: {
        innerColor: '#ffea00',
      },
      info: {
        background: atoms.colors.primary,
        hover: {
          background: 'linear-gradient(360deg, #CEFF00 0%, #E0FF30 47.52%, #EDFF00 100%), #FFEA00',
        },
      },
    },
    progressbar: {
      strokeColor: '#4c4c4c',
      iconFill: atoms.colors.primary,
      vs: Vs,
    },
    leaderBoard: {
      card: CompareCard,
      table: {
        background: '#212121',
      },
      count: {
        background: '#3e3e3e',
      },
      progressbar: {
        background: '#373737',
        activeBackground: '#4b4b4b',
        defaultProgress: 'linear-gradient(360deg, #CEFF00 0%, #E0FF30 47.52%, #EDFF00 100%), #FFEA00',
      },
      header: {
        questionBackground: '#424242',
        textTransform: 'uppercase',
        color: '#7a7a7a',
      },
      button: {
        buttonType: 'primary',
      },
      draw: {
        background: '#000',
      },
    },
    compare: {
      borderRadius: 10,
      credits: {
        color: '#a4a4a4',
      },
      text: {
        background: '#4c4c4c',
      },
      draw: {
        backgroundText: `linear-gradient(45deg, ${atoms.colors.active1}, #c7eb31)`,
      },
    },
    share: {
      textTransform: 'uppercase',
      color: atoms.colors.layout,
      marginTop: '30px',
    },
    winner: {
      iconStep: 45,
    },
  };

  const login = {
    popup: {
      button: {
        font: `600 16px/19px ${fonts.text}`,
      },
      content: {
        font: `400 12px/18px ${fonts.text}`,
      },
    },
    modal: {
      button: {
        font: `600 16px/21px ${fonts.text}`,
        color: '#fff',
      },
      content: {
        font: `400 16px/23px ${fonts.text}`,
        color: atoms.colors.text,
      },
    },
  };

  const cookiesBanner = {
    button: {
      btnType: 'limeGradient',
      btnRadius: '2px',
    },
  };

  return deepmerge({
    fonts,
    icons,
    texts,
    animations,
    controls: {
      topicHeaderTexts,
      input,
      button,
      link,
      shapka,
      secondaryMenu,
      scooter,
      counter,
      gallery,
      paginatron,
      spammer,
      inputDropdown,
      comments,
      commentsPreview,
      drum,
      mark,
      topicFooter,
      topicContent,
      bullet,
      formInput,
      inputRange,
      inputCompact,
      chip,
      chipOption,
      radioCloud,
      stopCoronavirus,
      checkbox,
      personal,
      switcher,
      vikontReadMore,
      vikontIncut,
      topicTopline,
      expert,
      author,
      authorPage,
      notes,
      story,
      smartTeaser,
      storyCard,
      storyWidgets,
      storyWidgetsBaseCard,
      storyWidgetsVariants,
      storyWidgetsRatingCard,
      storyWidgetsHiddenPhoto,
      reactions,
      reactionsEmojiBar,
      gameCompare,
      login,
      cookiesBanner,
    },
    layout,
    ads,
    pages,
  }, atoms);
}
