import { Fragment } from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import queryString from 'core/libs/query-string';
import { withRouter } from 'core/libs/router';

import {
  generateSelectOptions,
  initSwitchWithSeparatorValues,
  initSelectValue,
  initRadioValue,
  initCheckboxValue,
  initRangeValue,
  isLcvOnly,
} from 'site/utils/forms';

import {
  filterEntitiesByRelation,
} from 'site/utils';

import withContent from './withContent';
import Form from './Form';

import BaseForm from 'site/components/BaseForm';
import FormModal from 'site/components/FormModal';

import { CATALOG_URL } from 'site/constants';

import {
  RANGES,
  FULL_FILTER_FIELDS,
  PATHNAME_FIELDS,
  DEPENDENCIES,
} from './constants';


class CatalogForm extends BaseForm {
  constructor(props) {
    super(props);

    this.state = this.getFormStateFromLocation(props.baseEntities, props.formOptions);
  }

  componentDidUpdate(prevProps) {
    const {
      formOptions,
      baseEntities,
      isLcv,
      location: {
        pathname,
        search,
      },
    } = this.props;

    const {
      formOptions: prevFormOptions,
      baseEntities: prevBaseEntities,
      isLcv: prevIsLcv,
      location: {
        pathname: prevPathname,
        search: prevSearch,
      },
    } = prevProps;

    if (
      baseEntities !== prevBaseEntities
        || formOptions !== prevFormOptions
        || pathname !== prevPathname
        || search !== prevSearch
    ) {
      this.setState(this.getFormStateFromLocation(baseEntities, formOptions));
    }

    // переинициализируем форму, если изменилось значение isLcv
    if (isLcv !== prevIsLcv) {
      if (!isLcv && isLcvOnly(this.state.brand)) {
        this.handleControlChange(null, { name: 'brand' });
        return;
      }
      this.handleFormChange();
    }
  }

  dependencies = DEPENDENCIES;

  ranges = RANGES;

  pathnameFields = PATHNAME_FIELDS;

  fullFilterFields = FULL_FILTER_FIELDS;

  get basePath() {
    return this.props.isLcv || isLcvOnly(this.state.brand)
      ? '/lcv'
      : '/';
  }

  get emptyPath() { return this.props.isLcv ? '/lcv' : CATALOG_URL; }

  getFormStateFromLocation = (baseEntities = {}, formOptions) => {
    const {
      match: {
        params,
      },
      location,
    } = this.props;

    const filteredBrands = this.getBrandOptions(baseEntities);

    /**
     * Если значение не найдено, то устанавливаем его в null вместо
     * undefined, потому что undefined при сбросе фильтров не воспринимается
     * для некоторых контролов и там остается предыдущее установленное
     * значение.
     */
    const brand = filteredBrands.find(item => (
      item.attributes.slug === params.brand
    )) || null;

    const filteredModels = this.getModelOptions(baseEntities, brand);
    const model = brand && filteredModels.find(item => (
      item.attributes.slug === params.model
    )) || null;

    const filteredGenerations = this.getGenerationOptions(baseEntities, model);
    const generation = model && filteredGenerations.find(item => (
      item.attributes.slug === params.generation
    )) || null;

    const formValuesFromUrl = queryString.parse(location.search);

    return {
      brand,
      model,
      generation,
      price: initRangeValue(
        'price',
        formValuesFromUrl,
        this.ranges.price
      ),
      body: initSelectValue(
        'body',
        formValuesFromUrl,
        formOptions.filteredBodies,
        'attributes.slug',
      ),
      engine_type: initSelectValue(
        'engine_type',
        formValuesFromUrl,
        formOptions.filteredEngineTypes,
      ),
      transmission: initSelectValue(
        'transmission',
        formValuesFromUrl,
        formOptions.filteredTransmissions,
      ),

      country: initSelectValue(
        'country',
        formValuesFromUrl,
        formOptions.filteredCountries,
      ),
      class: initSelectValue(
        'class',
        formValuesFromUrl,
        formOptions.filteredClasses,
        'attributes.url'
      ),
      assembly: initRadioValue(
        'assembly',
        formValuesFromUrl,
      ),
      power: initRangeValue(
        'power',
        formValuesFromUrl,
        this.ranges.power
      ),
      boost: initCheckboxValue(
        'boost',
        formValuesFromUrl,
      ),
      control: initRadioValue(
        'control',
        formValuesFromUrl,
      ),
      razgon: initRangeValue(
        'razgon',
        formValuesFromUrl,
        this.ranges.razgon
      ),
      rashod: initRangeValue(
        'rashod',
        formValuesFromUrl,
        this.ranges.rashod
      ),
      trunk_volume: initRangeValue(
        'trunk_volume',
        formValuesFromUrl,
        this.ranges.trunk_volume
      ),
      number_of_seats: initSwitchWithSeparatorValues(
        'number_of_seats',
        formValuesFromUrl,
        formOptions.filteredSeats,
      ),
      clearance: initRangeValue(
        'clearance',
        formValuesFromUrl,
        this.ranges.clearance
      ),
      options: initCheckboxValue(
        'options',
        formValuesFromUrl,
      ),
      usages: initCheckboxValue(
        'usages',
        formValuesFromUrl,
      ),
    };
  };

  getBrandOptions = memoize(
    (baseEntities = {}) => generateSelectOptions(baseEntities.brands)
  );

  getModelOptions = memoize(
    (baseEntities = {}, brand) => generateSelectOptions(filterEntitiesByRelation('brand', baseEntities.models, [brand]))
  );

  getGenerationOptions = memoize(
    (baseEntities = {}, model) => generateSelectOptions(filterEntitiesByRelation('model', baseEntities.generations, [model]))
  );

  render() {
    const {
      formOptions,
      baseEntities,
      isLcv,
      onLcvChange,
      location,
      match: {
        params: {
          body: submodel,
        },
      },
    } = this.props;

    const {
      brand,
      model,
    } = this.state;

    // Зависимые опции
    const filteredBrands = this.getBrandOptions(baseEntities);
    const filteredModels = this.getModelOptions(baseEntities, brand);
    const filteredGenerations = this.getGenerationOptions(baseEntities, model);

    const FormWrapper = this.isFullFormOpened ? FormModal : Fragment;

    return (
      <FormWrapper>
        <Form
          baseEntities={baseEntities}
          formState={this.state}
          formOptions={{
            filteredBrands,
            filteredModels,
            filteredGenerations,
            ...formOptions,
          }}
          ranges={this.ranges}
          onChange={this.handleControlChange}
          onCarTypeChange={onLcvChange}
          location={location}
          navigateToNewUrl={this.navigateToNewUrl}
          toggleFullForm={this.toggleFullForm}
          allFiltersCount={this.allFiltersCount}
          fullFiltersCount={this.fullFiltersCount}
          resetAllForm={this.resetAllForm}
          isFiltersDisabled={!!submodel && !this.isFullFormOpened}
          isLcv={isLcv}
        />
      </FormWrapper>
    );
  }
}

CatalogForm.propTypes = {
  baseEntities: PropTypes.object,
  formOptions: PropTypes.object,
  isLcv: PropTypes.bool,
  onLcvChange: PropTypes.func,

  /** @ignore */
  location: PropTypes.object,

  /** @ignore */
  match: PropTypes.object,

  /** @ignore */
  history: PropTypes.object,
};

export default withRouter(withContent(CatalogForm));
