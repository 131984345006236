import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { processQueryResults } from 'core/utils/query';

import withBreakpoint from 'core/components/breakpoint/withBreakpoint';
import { Desktop, Mobile } from 'core/components/breakpoint';

import PageLoader from 'core/components/Loader/PageLoader';
import Page from 'core/components/Page';
import Bouesque from 'core/components/Bouesque';
import AdWrapper from 'core/components/Ad/AdWrapper';

import NewReleases from 'site/components/NewReleases';
import Comparison from 'site/components/Comparison';
import Releases from 'site/components/Releases';
import TitleWithReleasesCount from 'site/components/TitleWithReleasesCount';
import Brands from 'site/components/Brands';
import { Indent, NegativeMobile } from 'site/components/Wrappers';

import { SuperFooter } from 'site/components/Ads/desktop';
import { Listing1, Listing2 } from 'site/components/Ads/mobile';

import { brandsQuery } from 'site/queries/brands';
import { comparisonsQuery } from 'site/queries/comparisons';
import { newReleasesQuery, releasesQuery, releasesCountQuery } from 'site/queries/releases';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';


function CatalogPage(props) {
  const results = useRequest({
    queries: [
      // releasesDataAndMeta
      releasesQuery(props),

      // brands
      brandsQuery(props),

      // comparisons
      comparisonsQuery(),

      // newReleases
      newReleasesQuery(),

      // releasesCount
      releasesCountQuery(props),
    ],
  });

  const [{
    data: releasesDataAndMeta = {},
    isLoading: releasesDataAndMetaAreLoading,
  }, ...otherResults] = results;

  const {
    data: [
      brands = [],
      comparisons = [],
      newReleases = [],
      releasesCount = 0,
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(otherResults);

  const {
    isMobile,
    location,
    match,
  } = props;

  const {
    params: {
      lcv: isLcv,
    },
  } = match;

  let title;
  let description;

  if (isLcv) {
    title = 'Коммерческие автомобили, цены и характеристики, продажа коммерческих автомобилей официальными дилерами.';
    description = 'Одна из ключевых бизнес-задач — купить коммерческий автомобиль. Чтобы не ошибиться в выборе, нужно чётко понимать потребности своего предприятия — будь то представительский минивэн или фургон для перевозки фруктов-овощей.';
  } else {
    title = 'Каталог автомобилей - новые легковые авто в онлайн каталоге с ценами, описанием, фото и видео материалами.';
    description = 'Подробный каталог автомобилей с подробной информацией о марках и моделях автомобилей, доступных для покупки в России. Автокаталог позволяет онлайн получить официальную информацию о новых автомобилях, уточнить технические параметры, посмотреть фото и отзывы';
  }

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  if (location.search) {
    return (
      <Page title={title} description={description}>
        <Releases
          releasesDataAndMeta={releasesDataAndMeta}
          isLoading={releasesDataAndMetaAreLoading}
        />
        <Indent top={vertical} />
      </Page>
    );
  }

  return (
    <Page title={title} description={description}>
      {resultsAreLoading && <PageLoader />}
      {!resultsAreLoading && (
        <AdWrapper bottom={vertical}>
          <TitleWithReleasesCount releasesCount={releasesCount} />
          <Indent top={vertical} />
          <Mobile>
            <NegativeMobile>
              <Listing1 />
            </NegativeMobile>
          </Mobile>
          <Brands brands={brands} />
          <Indent top={vertical} />
          <Mobile>
            <Indent top={vertical} />
            <Bouesque />
          </Mobile>
          <Indent top={vertical} />
          <NewReleases content={newReleases} />
          <Indent top={vertical} />
          <Desktop>
            <SuperFooter />
          </Desktop>
          <Comparison content={comparisons} />
          <Indent top={vertical} />
          <Mobile>
            <Listing2 />
          </Mobile>
        </AdWrapper>
      )}
    </Page>
  );
}

CatalogPage.propTypes = {
  isMobile: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      lcv: PropTypes.string,
    }),
  }).isRequired,
};

export default withBreakpoint(CatalogPage);
