import PropTypes from 'prop-types';
import accounting from 'accounting';

import useRequest from 'core/hooks/useRequest';

import { SimpleLoader } from 'core/components/Loader';

import { withRouter } from 'core/libs/router';

import pluralize from 'core/utils/pluralize';

import { servicesApi } from 'site/api/definitions/services';

import { getRequestParamsWithFilter } from 'site/utils';

import {
  buildSearchParamsFromValues,
} from 'site/utils/forms';


const DECLINATIONS = {
  catalog: ['модель', 'модели', 'моделей'],
  offers: ['объявление', 'объявления', 'объявлений'],
};

function Count(props) {
  const {
    formType,
    formState,
    isLcv,
    isMoto,
    ranges,
    isDesktop,
  } = props;

  const processedFormValues = buildSearchParamsFromValues(formState, ranges);

  let requestParams = {
    ...getRequestParamsWithFilter(processedFormValues),
    only_filtered_count: true,
  };

  let apiEndpoint = isMoto ? 'getMotoOffers' : 'getOffers';

  if (formType === 'catalog') {
    const {
      match: {
        params: {
          geo,
        },
      },
    } = props;

    const {
      brand,
      model,
      generation,
      usages,
    } = formState;

    requestParams = {
      ...requestParams,
      'filter[usages]': usages?.join() || 'catalog,archive',
      /**
       * Каталог фильтрует поля ниже по id, поэтому переопределяем
       * их тут на слаги.
      */
      'filter[brand]': brand && brand.attributes.slug,
      'filter[model]': model && model.attributes.slug,
      'filter[generation]': generation && generation.attributes.slug,
      ...(geo && { 'filter[geo]': geo }),
      ...(isLcv && { 'filter[car_type]': 'lcv' }),
    };

    apiEndpoint = isMoto ? 'getMotoReleases' : 'getReleases';
  }

  const {
    data: count,
    isLoading: countAreLoading,
  } = useRequest({
    queryKey: ['servicesApi', apiEndpoint, requestParams],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    select: ({ meta }) => meta.filtered_count,
    enabled: process.env.BROWSER_RUNTIME,
  });

  if (countAreLoading) return <SimpleLoader spinnerWidth={16} spinnerHeight={16} />;

  const pluralizeText = pluralize(Number(count), DECLINATIONS[formType]);
  const prefix = !count || isDesktop ? 'Показать ' : '';

  return prefix + accounting.formatNumber(count) + ' ' + pluralizeText;
}

Count.propTypes = {
  isDesktop: PropTypes.bool,
  formType: PropTypes.oneOf(['catalog', 'offers']),
  formState: PropTypes.object,
  isLcv: PropTypes.bool,
  isMoto: PropTypes.bool,
  ranges: PropTypes.object,
  match: PropTypes.object,
};

export default withRouter(Count);
