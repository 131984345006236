import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { processQueryResults } from 'core/utils/query';

import Page from 'core/components/Page';
import PageLoader from 'core/components/Loader/PageLoader';
import { withBreakpoint, Mobile } from 'core/components/breakpoint';
import AdWrapper from 'core/components/Ad/AdWrapper';

import WhereToBuy from 'site/components/WhereToBuy';
import PhotoAndVideo from 'site/components/PhotoAndVideo';
import { Listing2 } from 'site/components/Ads/mobile';
import { Indent, ReleaseInnerPages } from 'site/components/Wrappers';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';

import { motoReleaseQueries } from 'site/queries/motoRelease';


function MotoReleasePhoto(props) {
  const { isMobile } = props;

  const results = useRequest({ queries: motoReleaseQueries(props) });

  const {
    data: [
      release = {},
      seo = {},
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const brandName = release?.relationships?.brand?.attributes?.name;

  return (
    <Page title={seo?.title} description={seo?.description}>
      {resultsAreLoading && <PageLoader />}
      {!resultsAreLoading && (
        <ReleaseInnerPages release={release}>
          <AdWrapper bottom={vertical}>
            <PhotoAndVideo release={release} isMoto />
            <Indent bottom={vertical} />
            <WhereToBuy brandName={brandName} />
            <Indent bottom={vertical} />
            <Mobile>
              <Listing2 />
            </Mobile>
          </AdWrapper>
        </ReleaseInnerPages>
      )}
    </Page>
  );
}

MotoReleasePhoto.propTypes = {
  isMobile: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      brand: PropTypes.string,
      model: PropTypes.string,
      release: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.object,
};

export default withBreakpoint(MotoReleasePhoto);
