import { useEffect, useState,  useCallback } from 'react';

import useRequest from 'core/hooks/useRequest';

import { denormalizeData } from 'core/utils/api';

import { Helmet } from 'core/libs/helmet';

import { LocalStorage } from 'core/decorators';

import H5 from 'core/components/H5';
import PageLoader from 'core/components/Loader/PageLoader';

import { servicesApi } from 'site/api/definitions/services';

import PageLayout from 'site/components/PageLayout';
import { Indent } from 'site/components/Wrappers';
import PddHeading from 'site/components/PddHeading/';
import QuestionsNavigation from 'site/components/QuestionsNavigation/';
import PddQuestion from 'site/components/PddQuestion/index.js';
import PddResult from 'site/components/PddResult';

import Blocks from 'site/pages/pdd/components/Blocks';

import { isGetAnswer, getPrevQuestionIndex, getNextQuestionIndex } from 'site/utils/pdd';

import { PDD_ALL_QUESTIONS_COUNT, PDD_TICKET_QUESTIONS_COUNT, ERROR_MESSAGE } from 'site/constants';


const title = 'Тест-марафон на сдачу экзамена в ГИБДД: билеты, подсказки';
const description = 'Все 800 вопросов из билетов на сдачу экзамена по вождению в ГИБДД, неограниченное время и количество ошибок, подсказки для тренировки. Онлайн-тест на знание правил ПДД на Quto.ru';

export default function Marafon() {
  const initialAnswers = Array(PDD_ALL_QUESTIONS_COUNT).fill(null);

  const [countQuestion, setCountQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState(initialAnswers);

  const numberTicket = Math.trunc(countQuestion / PDD_TICKET_QUESTIONS_COUNT) + 1;

  const finish = userAnswers.every((answer) => isGetAnswer(answer));

  const activeQuestion = countQuestion - (numberTicket - 1) * PDD_TICKET_QUESTIONS_COUNT;

  useEffect(() => {
    const countQuestionStorage = JSON.parse(LocalStorage.getItem('countQuestion'));
    const userAnswersStorage = JSON.parse(LocalStorage.getItem('userAnswers'));

    setCountQuestion(countQuestionStorage || 0);
    setUserAnswers(userAnswersStorage || initialAnswers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: questions = [],
    isLoading: questionsAreLoading,
    isError,
  } = useRequest({
    queryKey: ['servicesApi', 'getPddQuestions', {
      'filter[ticket]': numberTicket,
      'attributes[pdd_question]': 'base,image',
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    enabled: process.env.BROWSER_RUNTIME,
    select: denormalizeData,
  });

  useEffect(() => {
    LocalStorage.setItem('userAnswers', JSON.stringify(userAnswers));
  }, [userAnswers]);

  useEffect(() => {
    LocalStorage.setItem('countQuestion', JSON.stringify(countQuestion));
  }, [countQuestion]);

  const handleNextQuestion = useCallback(() => {
    const questionIndex = getNextQuestionIndex(userAnswers, countQuestion);
    if (questionIndex > -1) {
      setCountQuestion(questionIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countQuestion]);

  const handlePrevQuestion = useCallback(() => {
    const questionIndex = getPrevQuestionIndex(userAnswers, countQuestion);
    if (questionIndex > -1) {
      setCountQuestion(questionIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countQuestion]);

  const saveAnswer = useCallback((answer) => {
    const correctAnswer = questions
      && questions[activeQuestion]
      && questions[activeQuestion].attributes.correct_answer;

    const objAnswer = {
      answer,
      isCorrect: answer ===  correctAnswer,
    };
    setUserAnswers((prev) => {
      const newState = [...prev];
      newState[countQuestion] = objAnswer;
      return newState;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countQuestion, questions]);

  const handleReset = useCallback(() => {
    LocalStorage.removeItem('userAnswers');
    LocalStorage.removeItem('countQuestion');

    setCountQuestion(0);
    setUserAnswers(initialAnswers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finish]);

  return (
    <PageLayout>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
      </Helmet>
      <Indent bottom={20} />
      <PddHeading
        finish={finish}
        mode='marafon'
      >
        {finish ? 'Результаты Марафона' : 'Марафон'}
      </PddHeading>
      <Indent bottom={20} />
      <QuestionsNavigation
        active={countQuestion + 1}
        mode='marafon'
        answers={userAnswers}
        finish={finish}
      />
      <Indent bottom={20} />
      {isError && <H5>{ERROR_MESSAGE}</H5>}
      {!isError && (
        <>
          {questionsAreLoading && <PageLoader />}
          {!questionsAreLoading && (
            <>
              {!finish ? (
                <PddQuestion
                  question={questions[activeQuestion]}
                  mode='marafon'
                  handlePrevQuestion={handlePrevQuestion}
                  handleNextQuestion={handleNextQuestion}
                  saveAnswer={saveAnswer}
                  userAnswer={isGetAnswer(userAnswers[countQuestion]) ? userAnswers[countQuestion].answer : null}
                />
              ) : (
                <>
                  <PddResult
                    questions={questions}
                    answers={userAnswers}
                    handleReset= {handleReset}
                    mode='marafon'
                  />
                  <Indent bottom={50} />
                  <Blocks mode = 'marafon' finish={finish} />
                </>
              )}
            </>
          )}
        </>
      )}
      <Indent bottom={50} />
    </PageLayout>
  );
}
