import useRequest from 'core/hooks/useRequest';

import { denormalizeData } from 'core/utils/api';

import bindProps from 'core/components/bindProps';
import Drum from 'core/components/Drum';
import H3 from 'core/components/H3';
import PageLoader from 'core/components/Loader/PageLoader';

import { servicesApi } from 'site/api/definitions/services';

import CardServiceReview1 from 'site/cardsService/CardServiceReview1';


const DrumCard = bindProps({ dataQa: 'card-reviews-base-drum' })(CardServiceReview1);

export default function Reviews() {
  const {
    data: reviews = [],
    isLoading: reviewsAreLoading,
  } = useRequest({
    queryKey: ['servicesApi', 'getReviews', {
      limit: 7,
      include: 'car_release,car_modification,user',
      'attributes[review]': 'base,image,text',
      'relations[review]': 'release,modification,user',
      'attributes[car_modification]': 'base,battery',
      'filter[with_images]': true,
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    select: denormalizeData,
    enabled: process.env.BROWSER_RUNTIME,
  });

  if (reviewsAreLoading) return <PageLoader />;
  if (!reviews?.length) return null;

  return (
    <Drum
      grid
      title='Отзывы владельцев'
      showHeaderArrows
      showArrowsOnHover
      arrowsShift={-10}
      headerComponent={H3}
      itemWidth='40%'
      itemWidthMobile='100%'
      content={reviews}
      card={DrumCard}
      dataQa='reviews'
    />
  );
}
