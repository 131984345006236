import { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import pluralize from 'core/utils/pluralize';

import { getDependencies } from 'site/utils/configurator';

import H5 from 'core/components/H5';
import H6 from 'core/components/H6';

import OptionSimple from 'site/components/OptionSimple';

import { withBreakpoint } from 'core/components/breakpoint';

import { ConfiguratorContext } from 'site/components/ConfiguratorProvider';

import styles from './index.styl';

import mapTypes from './types';

const titleMap = {
  'include': 'Ваш выбор включает',
  'require': 'Ваш выбор включает',
  'consist': size => pluralize(size, ['Включая опцию', 'Включая опции', `Включая ${size} опций`]),
  'exclude': 'Ваш выбор отменит',
};

function OptionDependenciesSimple(props) {
  const {
    option,
    modificationId,
    isMobile,
    type,
    compactView,
  } = props;

  const {
    innerView,
    withTitle,
  } = mapTypes[type] || {};

  const configurations = useContext(ConfiguratorContext);

  const {
    options,
    packages,
    configuration: {
      disabled_options: disabledOptions,
    } = {},
  } = configurations[modificationId] || {};

  if (!options && !packages) return null;

  const Title = innerView ? H6 : H5;

  const includeDependencies = getDependencies(option.dependencies, 'include');
  const requireDependencies = getDependencies(option.dependencies, 'require');
  const consistDependencies = getDependencies(option.dependencies, 'consist');
  const excludeDependencies = getDependencies(option.dependencies, 'exclude');

  /**
   * Получаем зависимости-исключения, которых нет в списке заблокированных.
   * Опция попадает в список заблокированных, если была исключена другой опцией.
   * Например: "Адаптивный круиз-контроль" заблокирует "Круиз-контроль".
   */
  const excludesNotDisabled = excludeDependencies
    .filter(({ child }) => !~disabledOptions.findIndex(disabledId => disabledId === child));

  const optionSimpleProps = {
    modificationId: modificationId,
    compactView: compactView,
  };

  return (
    <div
      className={cx(
        'optionDependenciesSimple',
        isMobile && styles.mobile,
        innerView && styles.innerView,
        compactView && styles.compactView,
      )}
    >
      {Boolean(includeDependencies.length) && (
        <div className={styles.group}>
          {withTitle && <Title>{titleMap.include}</Title>}
          {includeDependencies.map(({ child }) => {
            const dependencyOption = options[child] || packages[child];
            return (
              <OptionSimple
                type={2}
                option={dependencyOption}
                key={dependencyOption.id}
                {...optionSimpleProps}
              />
            );
          })}
        </div>
      )}

      {Boolean(requireDependencies.length) && (
        <div className={styles.group}>
          {withTitle && <Title>{titleMap.require}</Title>}
          {requireDependencies.map(({ child }) => {
            const dependencyOption = options[child] || packages[child];
            return (
              <OptionSimple
                type={3}
                option={dependencyOption}
                key={dependencyOption.id}
                {...optionSimpleProps}
              />
            );
          })}
        </div>
      )}

      {Boolean(consistDependencies.length) && (
        <div className={styles.group}>
          {withTitle && <Title>{titleMap.consist(consistDependencies.length)}</Title>}
          {consistDependencies.map(({ child }) => {
            const dependencyOption = options[child] || packages[child];
            return (
              <OptionSimple
                type={3}
                option={dependencyOption}
                key={dependencyOption.id}
                {...optionSimpleProps}
              />
            );
          })}
        </div>
      )}

      {Boolean(excludesNotDisabled.length) && (
        <div className={styles.group}>
          {withTitle && <Title>{titleMap.exclude}</Title>}
          {excludesNotDisabled.map(({ child }) => {
            const dependencyOption = options[child] || packages[child];
            return (
              <OptionSimple
                type={1}
                option={dependencyOption}
                key={dependencyOption.id}
                {...optionSimpleProps}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

OptionDependenciesSimple.propTypes = {
  option: PropTypes.object.isRequired,
  modificationId: PropTypes.string.isRequired,
  type: PropTypes.oneOf([1, 2, 3]),
  isMobile: PropTypes.bool,
  /**
   * Флаг для компактного отображения.
   * Приходит из таблицы сравнения.
   */
  compactView: PropTypes.bool,
};

export default withBreakpoint(OptionDependenciesSimple);
