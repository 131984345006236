import { useCallback, useState, useEffect, useRef, Fragment, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { Helmet } from 'core/libs/helmet';
import { withRouter } from 'core/libs/router';

import PageLoader from 'core/components/Loader/PageLoader';
import withQueryClient from 'core/components/withQueryClient';

import { servicesApi } from 'site/api/definitions/services';

import { denormalizeData } from 'core/utils/api';
import { SessionStorage } from 'core/decorators';

import {
  getRandomTicket,
  checkExamResult,
} from 'site/utils/pdd';

import PddHeading from 'site/components/PddHeading';
import QuestionsNavigation from 'site/components/QuestionsNavigation';
import { Indent } from 'site/components/Wrappers';

import Questions from './Questions';

import reducer, { init } from './reducer';

import { PDD_EXAM_MAX_MISTAKES, PDD_TICKET_QUESTIONS_COUNT } from 'site/constants';


const EXAM_TIME = 20 * 60;
const ADD_TIME = 5 * 60;
const ADD_LIMIT = 5;

function PddExam(props) {
  const {
    mode,
    queryClient,
    match: {
      params: {
        ticketNumber: ticketNumberFromParams,
      },
    },
  } = props;

  const [state, dispatch] = useReducer(reducer, { ticketNumber: ticketNumberFromParams || getRandomTicket() }, init);
  const [seconds, setSeconds] = useState(EXAM_TIME);

  const {
    answers,
    mistakes,
    additionalQuestions,
    ticketNumber,
    questionIndex,
  } = state;

  const interval = useRef(null);

  const isTren = mode === 'tren';

  let processHeading = 'Экзамен';
  let resultHeading = 'онлайн-экзамена';
  let storageName = 'examState';

  if (isTren) {
    processHeading = `Тренировка. Билет\u00A0№\u00A0${ticketNumber}`;
    resultHeading = 'тренировки';
    storageName = 'trenState';
  }

  const {
    data: questions,
    isLoading: questionsAreLoading,
  } = useRequest({
    queryKey: ['servicesApi', 'getPddQuestions', {
      'filter[ticket]': ticketNumber,
      'attributes[pdd_question]': 'base,image',
      'relations[pdd_question]': 'category',
      'include': 'pdd_category',
      'limit': PDD_TICKET_QUESTIONS_COUNT,
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    select: denormalizeData,
    enabled: process.env.BROWSER_RUNTIME,
  });

  useEffect(() => {
    const lastMistakeCategory = mistakes[mistakes.length - 1]?.category;

    if (!lastMistakeCategory) return;

    queryClient
      .fetchQuery({
        queryKey: ['servicesApi', 'getPddQuestions', {
          'attributes[pdd_question]': 'base,image',
          'limit': ADD_LIMIT,
          'filter[category]': lastMistakeCategory,
          'filter[except_ticket]': ticketNumber,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method]({
          ...params,
          'filter[category]': mistakes[mistakes.length - 1].category,
          'filter[except_ticket]': ticketNumber,
        }),
        select: denormalizeData,
      })
      .then(denormalizeData)
      .then(data => {
        dispatch({ type: 'setAdditionalQuestions', payload: data });
        setSeconds(sec => sec + ADD_TIME);
      });
  }, [queryClient, mistakes, ticketNumber]);


  const examResult = useMemo(() => {
    return checkExamResult(answers, mistakes, seconds, mode);
  }, [answers, mistakes, seconds, mode]);

  const isFinish = !!examResult;

  // Устанавливаем состояние из стораджа
  useEffect(() => {
    const examState = JSON.parse(SessionStorage.getItem(storageName));

    if (!examState) return;

    if (ticketNumberFromParams) {
      return;
    }

    dispatch({ type: 'reset', payload: examState });

    // с учетом дополнительного времени
    const secondsToExam = EXAM_TIME + ADD_TIME * Math.min(examState.mistakes.length, PDD_EXAM_MAX_MISTAKES);
    const finishTime = examState.finishTime || Date.now();
    const secondsPassed = (finishTime - examState.startTime) / 1000;

    let secondsLeft = secondsToExam - secondsPassed;
    if (secondsLeft < 0) {
      secondsLeft = 0;
    }
    setSeconds(secondsLeft);
  }, [ticketNumberFromParams, storageName]);

  // Эффект запускает таймер
  useEffect(() => {
    if (!interval.current) {
      interval.current = setInterval(() => {
        setSeconds(sec => sec - 1);
      }, 1000);
    }
    if (isFinish) {
      dispatch({ type: 'setFinishTime', payload: Date.now() });
      clearInterval(interval.current);
      interval.current = null;
    }
    return () => {
      clearInterval(interval.current);
    };
  }, [isFinish]);

  // Эффект сохраняет данные в sessionStorage
  useEffect(() => {
    !ticketNumberFromParams && SessionStorage.setItem(storageName, JSON.stringify(state));
  }, [state, storageName, ticketNumberFromParams]);

  const resetTicket = useCallback(() => {
    dispatch({ type: 'reset', payload: { ticketNumber: ticketNumberFromParams || getRandomTicket() } });
    setSeconds(EXAM_TIME);
  }, [ticketNumberFromParams]);

  const goToNextQuestion = useCallback(() => {
    dispatch({ type: 'next' });
  }, []);

  const goToPrevQuestion = useCallback(() => {
    dispatch({ type: 'prev' });
  }, []);

  const handleAnswer = useCallback((question, answer) => {
    dispatch({ type: 'saveAnswer', payload: { question, answer } });
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{`Билет № ${ticketNumber} - ${isTren ? 'тренировка -' : ''} Экзамен ПДД онлайн`}</title>
      </Helmet>
      <PddHeading
        finish={isFinish}
        time={seconds}
        mode={mode}
      >
        {isFinish
          ? `Результаты ${resultHeading} ПДД`
          : processHeading
        }
      </PddHeading>
      <Indent bottom={22} />
      <QuestionsNavigation
        active={questionIndex + 1}
        mode={mode}
        answers={answers}
        finish={isFinish}
      />
      <Indent bottom={32} />
      {ticketNumber && (
        questionsAreLoading
          ? <PageLoader />
          : (
            <Questions
              questions={questions}
              isFinish={isFinish}
              examResult={examResult}
              goToPrevQuestion={goToPrevQuestion}
              goToNextQuestion={goToNextQuestion}
              resetTicket={resetTicket}
              handleAnswer={handleAnswer}
              additionalQuestions={additionalQuestions}
              answers={answers}
              questionIndex={questionIndex}
              ticketNumber={ticketNumber}
              key={ticketNumber}
              mode={mode}
            />
          )
      )}
    </Fragment>
  );
}

PddExam.defaultProps = {
  mode: 'exam',
};

PddExam.propTypes = {
  mode: PropTypes.oneOf(['tren', 'exam']),
  match: PropTypes.object.isRequired,
  queryClient: PropTypes.object,
};

export default withRouter(withQueryClient(PddExam));
