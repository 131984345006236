import useRequest from 'core/hooks/useRequest';

import { renderError } from 'core/utils/app-status-helper';
import { processQueryResults } from 'core/utils/query';
import { denormalizeData } from 'core/utils/api';

import { servicesApi } from 'site/api/definitions/services';

import rejectEmptyResult from 'site/utils/rejectEmptyResult';
import { buildSearchRequestParams, isMotoPath } from 'site/utils';


const LIMIT = 100;

export default function useBrandContent(queryParams) {
  const {
    match: {
      params: {
        lcv,
        brand: brandSlug,
        type_slug: type,
      },
    },
    location: {
      search,
      pathname,
    },
    history,
  } = queryParams;

  const isLcvPage = Boolean(lcv);
  const isMoto = isMotoPath(pathname);
  const vehiclePrefix = isMoto ? 'moto' : 'car';

  const archiveReleasesParams = {
    [`attributes[${vehiclePrefix}_release]`]: 'base,image,stats',
    [`relations[${vehiclePrefix}_release]`]: 'model,generation,body',
    include: isMoto
      ? 'moto_year'
      : 'car_submodel,car_model,car_generation,car_body,car_release',
    'filter[brand]': brandSlug,
    'filter[usages]': 'archive',
  };

  const brandsHandle = isMoto ? 'getMotoBrands' : 'getBrands';
  const brandSeoHandle = isMoto ? 'getMotoBrandSeo' : 'getCarBrandSeo';
  const releasesHandle = isMoto ? 'getMotoReleases' : 'getReleases';

  const results = useRequest({
    queries: [
      {
        queryKey: ['servicesApi', brandsHandle, {
          [`attributes[${vehiclePrefix}_brand]`]: 'base,seo,image',
          'filter[brand]': brandSlug,
          limit: 1,
          ...(isLcvPage && { 'filter[car_type]': 'lcv' }),
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
          .then(brandsData => denormalizeData(brandsData)[0])
          .then(rejectEmptyResult)
          .catch(err => renderError(err, history)),
      }, {
        queryKey: ['servicesApi', brandSeoHandle, {
          'filter[brand]': brandSlug,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      }, {
        queryKey: ['servicesApi', releasesHandle, {
          [`attributes[${vehiclePrefix}_release]`]: 'base,image,stats',
          [`relations[${vehiclePrefix}_release]`]: 'class',
          include: `${vehiclePrefix}_class`,
          sort: isMoto ? null : 'class',
          'filter[type_slug]': type,
          'filter[brand]': brandSlug,
          'filter[usages]': 'catalog',
          ...(isLcvPage && { 'filter[car_type]': 'lcv' }),
          ...buildSearchRequestParams(search),
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
        select: denormalizeData,
      }, {
        queryKey: ['servicesApi', releasesHandle, {
          ...archiveReleasesParams,
          with_filtered_count: 1,
        }],
        queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
      },
    ],
  });

  const {
    data: archiveGenerations = [],
    isLoading: archiveGenerationsAreLoading,
  } = useRequest({
    queryKey: ['servicesApi', 'getGenerations', {
      'filter[brand]': brandSlug,
      'filter[usages]': 'archive',
      'relations[car_generation]': 'model',
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    select: denormalizeData,
    enabled: !isMoto,
  });

  const {
    data: [
      brand = {},
      brandSeo = {},
      releases = [],
      archiveReleases = [],
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  const filteredCount = archiveReleases?.meta?.filtered_count || 0;
  const iterations = Math.ceil(filteredCount / LIMIT);

  const queries = [];

  for (let i = 1; i < iterations; i++) {
    queries.push({
      queryKey: ['servicesApi', releasesHandle, {
        ...archiveReleasesParams,
        offset: i * LIMIT,
      }],
      queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    });
  }

  const restArchivedReleasesResults = [].concat(useRequest({ queries }) || {});
  const restArchivedReleasesAreLoading = restArchivedReleasesResults.some(({ isLoading }) => isLoading);

  const gluedArchivedReleases = restArchivedReleasesResults
    .filter(({ data }) => !!data)
    .reduce((stack, { data: item }) => {
      return {
        data: stack.data.concat(item.data),
        included: stack.included.concat(item.included),
      };
    }, archiveReleases);

  const allArchivedReleases = denormalizeData(gluedArchivedReleases);

  return {
    brand,
    brandSeo,
    releases,
    archiveReleases: allArchivedReleases,
    archiveGenerations,
    isLoading: resultsAreLoading,
    archivesAreLoading: (!isMoto && archiveGenerationsAreLoading) || restArchivedReleasesAreLoading,
  };
}
