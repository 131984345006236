import PropTypes from 'prop-types';

import styles from './styles.styl';


function LetteredList(props) {
  const {
    items,
    itemCard: ItemCard,
    letter,
  } = props;

  return (
    <div className={styles.letteredList}>
      <div className={styles.letter}>{letter}</div>
      <div className={styles.brandsList}>
        {items.map((item, i) => <ItemCard key={i} content={item} />)}
      </div>
    </div>
  );
}

LetteredList.propTypes = {
  items: PropTypes.array.isRequired,
  letter: PropTypes.string.isRequired,
  itemCard: PropTypes.func.isRequired,
};

export default LetteredList;
