import { useContext } from 'react';
import PropTypes from 'prop-types';

import { Desktop, Mobile } from 'core/components/breakpoint';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import Page from 'core/components/Page';
import Bouesque from 'core/components/Bouesque';
import AdWrapper from 'core/components/Ad/AdWrapper';
import H3 from 'core/components/H3';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent, Indent } from 'core/components/Wrappers';
import PageLoader from 'core/components/Loader/PageLoader';

import {
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
} from 'site/constants';

import BorodaService from 'site/components/BorodaService';
import Brands from 'site/components/Brands';
import NewReleases from 'site/components/NewReleases';
import Comparison from 'site/components/Comparison';
import FooterAds from 'site/components/FooterAds';
import Stories from 'site/components/Stories';

import {
  SuperFooter,
  Context,
} from 'site/components/Ads/desktop';

import {
  ListingSpec,
  Listing2,
} from 'site/components/Ads/mobile';

import ParallaxWrapper from 'site/components/Ads/ParallaxWrapper';
import { InnerSideColumnPart1, InnerSideColumnPart2 } from 'site/components/InnerSideColumn';
import { GeoContext } from 'site/components/GeoContext';

import NewInJournal from './NewInJournal/';
import NowOnSale from './NowOnSale/';
import Popular from './Popular/';
import Reviews from './Reviews/';
import OffersSearch from './OffersSearch';

import useMainContent from './useMainContent';


function MainPage(props) {
  const { geoSlug } = useContext(GeoContext);

  const {
    newInJournal,
    popular,
    brands,
    comparisons,
    newReleases,
    isLoading,
  } = useMainContent(props);

  const { isMobile } = props;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Page>
      {isLoading && <PageLoader />}
      {!isLoading && (
        <AdWrapper bottom={vertical}>
          <Stories />
          <NewInJournal content={newInJournal} />
          <PageIndent>
            <ColumnLayout rightColumn={<InnerSideColumnPart1 showCarNews={false} />}>
              <H3 is='h1'>Каталог автомобилей</H3>
              <Indent top={vertical} />
              <Brands brands={brands} />
              <Mobile>
                <Indent top={vertical} />
                <Bouesque />
              </Mobile>
              <Indent top={vertical} />
              <NewReleases content={newReleases} />
              <Indent top={vertical} />
              <Desktop>
                <SuperFooter />
              </Desktop>
              <Popular topics={popular} />
              <Indent top={vertical} />
              <Mobile>
                <ListingSpec />
              </Mobile>
            </ColumnLayout>

            <OffersSearch geoSlug={geoSlug} />

            <Indent top={vertical} />
            <ParallaxWrapper />

            <H3>Сейчас в продаже</H3>
            <NowOnSale geoSlug={geoSlug} />

            <Indent top={vertical} />
            <ColumnLayout rightColumn={<InnerSideColumnPart2 />}>
              <Comparison content={comparisons} />
              <Indent top={vertical} />
              <Mobile>
                <Listing2 />
              </Mobile>
              <Reviews />
              <Indent top={vertical} />
              <Desktop>
                <Context />
              </Desktop>
              <BorodaService />
            </ColumnLayout>
          </PageIndent>
          <FooterAds contextCount={2} />
        </AdWrapper>
      )}
    </Page>
  );
}

MainPage.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(MainPage);
