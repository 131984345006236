import { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { denormalizeData } from 'core/utils/api';
import resolveRelationships from 'core/utils/relationships';
import { processQueryResults } from 'core/utils/query';

import { withRouter } from 'core/libs/router';

import { withBreakpoint } from 'core/components/breakpoint';

import H3 from 'core/components/H3';
import PageLoader from 'core/components/Loader/PageLoader';

import { servicesApi } from 'site/api/definitions/services';

import SmartDrum from 'site/components/SmartDrum';
import { Indent } from 'site/components/Wrappers';
import Divider from 'site/components/Divider';
import { GeoContext } from 'site/components/GeoContext';

import { isMotoPath } from 'site/utils';

import CardServiceOffer1 from 'site/cardsService/CardServiceOffer1';

import modelPropTypes, {
  offerAttributes,
} from 'site/utils/prop-types/model';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


const similarQuery = ({ apiParams, apiMethod, similarType, geoSlug }) => ({
  queryKey: ['servicesApi', apiMethod, {
    ...apiParams,
    'filter[geo]': geoSlug,
    'limit': 9,
    'attributes[dealer_network]': 'base,image',
    [`filter[${similarType}]`]: true,
  }],
  queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
  select: denormalizeData,
  enabled: process.env.BROWSER_RUNTIME && !!geoSlug,
});

const relationships = resolveRelationships(['release', 'modification', 'body', 'class', 'geo', 'dealer'], {}, {});

function SimilarOffers(props) {
  const {
    isMobile,
    offer,
    location: {
      pathname,
    },
    match: {
      params: {
        brand,
        model,
        generation,
      },
    },
  } = props;

  const { getGeoAttributes, geoSlug } = useContext(GeoContext);

  const isMoto = isMotoPath(pathname);
  const isOfferPage = pathname.indexOf('/inventory/') !== -1;

  const vehiclePrefix = isMoto ? 'moto' : 'car';

  let apiParams = {
    'include': `${vehiclePrefix}_brand,${vehiclePrefix}_model,car_modification,dealer,dealer_network`,
    [`attributes[dealer_offer${isMoto ? '_moto' : ''}]`]: 'base,image',
    [`relations[dealer_offer${isMoto ? '_moto' : ''}]`]: 'brand,model,modification,dealer,dealer_network',
  };

  if (isOfferPage) {
    const { price, slug } = offer?.attributes || {};

    const relations = relationships(offer);

    apiParams = {
      ...apiParams,
      'filter[price_max]': Math.floor(price * 1.1),
      'filter[price_min]': Math.floor(price * 0.9),
      'filter[class]': relations.class.url,
      'filter[offer]': `!${slug}`,
      'relations[dealer]': 'dealer_network',
    };
  } else {
    apiParams = {
      ...apiParams,
      'filter[brand]': brand,
      'filter[model]': model,
      'filter[generation]': generation,
      'attributes[dealer_network]': 'image',
    };
  }

  const apiMethod = isMoto ? 'getMotoOffers' : 'getOffers';
  const commonParams = {
    apiParams,
    apiMethod,
    geoSlug,
  };

  const results = useRequest({
    queries: [
      similarQuery({
        ...commonParams,
        similarType: 'is_new',
      }),
      similarQuery({
        ...commonParams,
        similarType: 'is_trade_in',
      }),
    ],
  });

  const {
    data: [
      similarNew = [],
      similarUsed = [],
    ],
    isLoading: resultsAreLoading,
  } = processQueryResults(results);

  if (resultsAreLoading) return <PageLoader />;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  const geoNameGenitive = geoSlug ? getGeoAttributes(geoSlug).name_genitive : '';
  const typeTransportTitle = isMoto ? 'мотоциклы' : 'автомобили';

  const titleNewOffers = isOfferPage ? `Новые ${typeTransportTitle} за те же деньги` :
    `Новые ${typeTransportTitle} в автосалонах ${geoNameGenitive}`;

  const titleUsedOffers = isOfferPage ? 'Похожие предложения с пробегом' :
    `${typeTransportTitle[0].toUpperCase() + typeTransportTitle.slice(1)} с пробегом в автосалонах ${geoNameGenitive}`;


  const commonProps = {
    headerComponent: H3,
    itemWidth: '250px',
    itemWidthMobile: '250px',
    card: CardServiceOffer1,
    showHeaderArrows: true,
    showArrowsOnHover: true,
    arrowsShift: -10,
  };

  return (
    <Fragment>
      {similarUsed?.length > 0 && (
        <Indent bottom={vertical}>
          <Divider top={30} bottom={20} />
          <SmartDrum
            {...commonProps}
            title={titleUsedOffers}
            content={similarUsed}
          />
        </Indent>
      )}

      {similarNew?.length > 0 && (
        <Indent bottom={vertical}>
          <Divider top={30} bottom={20} />
          <SmartDrum
            {...commonProps}
            title={titleNewOffers}
            content={similarNew}
          />
        </Indent>
      )}
    </Fragment>
  );
}

SimilarOffers.propTypes = {
  isMobile: PropTypes.bool,
  offer: modelPropTypes(offerAttributes),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  match: PropTypes.shape({
    params: PropTypes.object.isRequired,
  }),
};

export default withRouter(withBreakpoint(SimilarOffers));
