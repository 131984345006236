import PropTypes from 'prop-types';

import { capitalize } from 'core/utils/string-helper';

import { withBreakpoint, Desktop, Mobile } from 'core/components/breakpoint';

import AdWrapper from 'core/components/Ad/AdWrapper';
import Page from 'core/components/Page';
import PageLoader from 'core/components/Loader/PageLoader';

import TitleCatalog from 'site/components/TitleCatalog';
import HtmlWithTheme from 'site/components/HtmlWithTheme';
import WhereToBuy from 'site/components/WhereToBuy';
import { Indent } from 'site/components/Wrappers';

import { SuperFooter } from 'site/components/Ads/desktop';
import { Listing1, ListingSpec } from 'site/components/Ads/mobile';

import { isMotoPath } from 'site/utils';

import Releases from './Releases';

import useModelContent from './useModelContent';

import { VERTICAL_INDENT, VERTICAL_INDENT_MOBILE } from 'site/constants';


function ModelPage(props) {
  const {
    model,
    modelSeo,
    releases,
    generations,
    years,
    isLoading,
  } = useModelContent(props);

  const {
    isMobile,
    location: {
      pathname,
    },
  } = props;

  const { name: modelName } = model?.attributes || {};

  const {
    title: seoTitle,
    description: seoDescription,
  } = modelSeo || {};

  const brand = model?.relationships?.brand.data;

  const {
    name: brandName = '',
  } = brand?.attributes || {};

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;

  return (
    <Page title={seoTitle} description={seoDescription}>
      {isLoading && <PageLoader />}
      {!isLoading && (
        <AdWrapper bottom={vertical}>
          {!!brand && <TitleCatalog brand={brand} title={`${capitalize(brandName)} ${modelName}`} />}
          <Indent bottom={vertical} />

          <Releases
            releases={releases}
            generations={generations}
            years={years}
            isMoto={isMotoPath(pathname)}
          />
          <Indent bottom={vertical} />

          <Desktop><SuperFooter /></Desktop>
          <Mobile><Listing1 /></Mobile>

          <WhereToBuy brandName={brandName} />
          <Indent bottom={vertical} />

          <HtmlWithTheme html={seoDescription} />
          <Indent bottom={vertical} />

          <Mobile><ListingSpec /></Mobile>
        </AdWrapper>
      )}
    </Page>
  );
}

ModelPage.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,

  /** @ignore */
  location: PropTypes.object,
};

export default withBreakpoint(ModelPage);
