import PropTypes from 'prop-types';
import { Helmet } from 'core/libs/helmet';

import { withNonPureBreakpoint } from 'core/components/breakpoint';
import Texts from 'core/components/Texts';
import PageLoader from 'core/components/Loader/PageLoader';

import PageLayoutService from 'site/components/PageLayoutService';
import withGeoRedirect from 'site/components/GeoContext/withGeoRedirect';

import {
  SIDE_INDENT,
  VERTICAL_INDENT,
  VERTICAL_INDENT_MOBILE,
  SIDE_INDENT_MOBILE,
} from 'site/constants';

import SearchResults from './SearchResults';
import SeoBlock from './SeoBlock';

import useDealersContent from './useDealersContent';
import styles from './index.styl';


function DealersPage(props) {
  const {
    dealersSeo,
    dealers = [],
    isLoading,
    dealersAreLoading,
  } = useDealersContent(props);

  if (isLoading) return <PageLoader />;

  const { isMobile } = props;

  const { title, description, text } = dealersSeo;

  const vertical = isMobile ? VERTICAL_INDENT_MOBILE : VERTICAL_INDENT;
  const horizontal = isMobile ? SIDE_INDENT_MOBILE : SIDE_INDENT;

  return (
    <PageLayoutService fullWidthContent>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
      </Helmet>
      <Texts
        className={styles.title}
        tag='h1'
        type='title1'
      >
        {title}
      </Texts>
      <SearchResults dealers={dealers} isLoading={dealersAreLoading} />
      {!!text && <SeoBlock
        {...dealersSeo}
        indents={{
          top: vertical,
          right: horizontal,
          bottom: vertical,
          left: horizontal,
        }}
      /> }
    </PageLayoutService>
  );
}

DealersPage.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
};

export default withGeoRedirect(withNonPureBreakpoint(DealersPage));
