import { useState, useContext } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import { denormalizeData } from 'core/utils/api';

import H3 from 'core/components/H3';
import { withBreakpoint } from 'core/components/breakpoint';
import PageLoader from 'core/components/Loader/PageLoader';
import { withRouter } from 'core/libs/router';

import { servicesApi } from 'site/api/definitions/services';

import { NegativeMobile, Indent } from 'site/components/Wrappers';
import DealersMap from 'site/components/DealersMap';
import GeoSelect from 'site/components/GeoSelect';
import { GeoContext } from 'site/components/GeoContext';

import { isMotoPath } from 'site/utils';

import styles from './index.styl';


function WhereToBuy(props) {
  const {
    isMobile,
    brandName,
    location,
    match: {
      params: {
        brand,
      },
    },
  } = props;
  const { geoSlug } = useContext(GeoContext);
  const [innerGeoSlug, setInnerGeoSlug] = useState(geoSlug);

  const vehiclePrefix = isMotoPath(location.pathname) ? 'moto' : 'car';

  const {
    data: geos = [],
    isLoading: geosAreLoading,
  } = useRequest({
    queryKey: ['servicesApi', 'getDealersGeos', {
      [`filter[${vehiclePrefix}_brand]`]: brand,
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params),
    select: denormalizeData,
    enabled: process.env.BROWSER_RUNTIME,
  });

  const {
    data: dealers = [],
    isLoading: dealersAreLoading,
  } = useRequest({
    queryKey: ['servicesApi', 'getDealers', {
      'filter[status]': 'official',
      'filter[geo]': innerGeoSlug,
      [`filter[${vehiclePrefix}_brand]`]: brand,
    }],
    queryFn: ({ queryKey: [, method, params] }) => servicesApi[method](params)
      .then(denormalizeData)
      .then((data = []) => data.filter(dealer => dealer?.attributes?.latitude && dealer?.attributes?.longitude)),
    enabled: process.env.BROWSER_RUNTIME && !!innerGeoSlug,
  });

  if (geosAreLoading) return <PageLoader />;
  if (!geos?.length || !innerGeoSlug) return null;

  return (
    <div className={cx('whereToBuy', isMobile && styles.mobile)}>
      <div className={styles.controls}>
        <H3>Где купить {brandName}</H3>
        <div className={styles.select}>
          <GeoSelect
            geoSlug={innerGeoSlug}
            geos={geos}
            onChange={({ value }) => setInnerGeoSlug(value)}
          />
        </div>
      </div>
      <Indent bottom={20} />
      <NegativeMobile>
        <DealersMap
          dealers={dealers}
          isLoading={dealersAreLoading}
          geoSlug={innerGeoSlug}
          height={isMobile ? 340 : 500}
        />
      </NegativeMobile>
    </div>
  );
}

WhereToBuy.propTypes = {
  /** @ignore */
  isMobile: PropTypes.bool,
  /** @ignore */
  match: PropTypes.object,
  /** @ignore */
  location: PropTypes.object,
  /** Название бренда */
  brandName: PropTypes.string,
};

const WhereToBuyWithHOCs = withBreakpoint(withRouter(WhereToBuy));

WhereToBuyWithHOCs.displayName = 'WhereToBuy';

export default WhereToBuyWithHOCs;
export { WhereToBuy as StorybookComponent };
